import http from '@/http';

export default {
    state: {
        prodGetOptLoading: false,
        onAirTimerModel: null,
        onAirMsgModel: {
            ON_AIR_SHORT_MESSAGE: null,
            ON_AIR_LONG_MESSAGE: null,
        }
    },
    getters: {
        prodGetOptLoading: state => state.prodGetOptLoading,
        onAirTimerModel: state => state.onAirTimerModel,
        onAirMsgModel: state => state.onAirMsgModel,
    },
    mutations: {
        setProdGetOptLoading(state, data) {
            state.prodGetOptLoading = data;
        },
        setOnAirTimerModel(state, data) {
            state.onAirTimerModel = data;
        },
        setOnAirMsgModel(state, data) {
            state.onAirMsgModel = data;
        },
    },
    actions: {
        getOptionsData({ commit }, optData) {
            commit("setProdGetOptLoading", true);
            return new Promise((res, rej) => {
                http('donate')[optData.name === 'search' ? 'post' : 'get'](`options/${optData.name}`, optData.value).then(response => {
                    const onAirData = response.data.data.ON_AIR_TIMER || null;
                    if (optData.name === 'search' && onAirData) {
                        commit("setOnAirTimerModel", onAirData);
                    }
                    commit("setProdGetOptLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setProdGetOptLoading", false);
                    rej(error)
                });
            });
        },
        getEmailOptionsData({ commit }, optData) {
            return new Promise((res, rej) => {
                http('email')[optData.name === 'search' ? 'post' : 'get'](`options/${optData.name}`, optData.value).then(response => {
                    res(response);
                }).catch((error) => rej(error));
            });
        },
        updatesOptionsData({ commit }, optModel) {
            const reqData = { option_value : optModel.value}
            return new Promise((res, rej) => {
                http('donate').put(`options/${optModel.name}`, reqData).then(response => {
                    res(response.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        updateBulkOptData({ commit }, optData) {
            return new Promise((res, rej) => {
                http('donate').post(`options/bulksave`, optData).then(response => {
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
    },
};
