import http from '@/http';

export default {
    state: {
        usersData: {},
        selectedUser: {},
        selectUserLoading: false
    },
    getters: {
        usersLists: state => state.usersData.data,
        getSelectedUser: state => state.selectedUser,
        selectUserLoading: state => state.selectUserLoading,
    },
    mutations: {
        setUsersData(state, data) {
            state.usersData = data;
        },
        setSelectUserData(state, data) {
            state.selectedUser = data;
            state.selectUserLoading = false;
        },
        setSelectUserLoading(state, data) {
            state.selectUserLoading = data;
        },
    },
    actions: {
        getUsersList({ commit }) {
            commit("setDTLoading", true);
            return new Promise((res, rej) => {
                http('user').get('users/lists').then(response => {
                    commit("setUsersData", response.data);
                    commit("setDTDetails", response.data);
                    res(response);
                }).catch((error) => {
                    commit("setDTLoading", false);
                    rej(error)
                });
            });
        },
        getUserData({ commit }, userId) {
            commit("setSelectUserLoading", true);
            return new Promise((res, rej) => {
                http('user').get(`users/${userId}`).then(response => {
                    commit("setSelectUserData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectUserLoading", false);
                    rej(error)
                });
            });
        },
        createUser({ commit }, userData) {
            commit("setSelectUserLoading", true);
            return new Promise((res, rej) => {
                http('user').post('users', userData).then(response => {
                    commit("setSelectUserLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setSelectUserLoading", false);
                    rej(error)
                });
            });
        },
        updateUserData({ commit }, userData) {
            commit("setSelectUserLoading", true);
            return new Promise((res, rej) => {
                http('user').put(`users/${userData.id}`, userData).then(response => {
                    commit("setSelectUserData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectUserLoading", false);
                    rej(error)
                });
            });
        },
        deleteUser({ commit }, userId) {
            return new Promise((res, rej) => {
                http('user').deleteReq(`users/${userId}`).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "User Deleted Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        generateGoogle2fa({ commit }, userData) {
            //  commit("setSelectUserLoading", true);
            return new Promise((res, rej) => {
                http('user').get(`google-2fa/generate`, userData).then(response => {
                    commit("setSelectUserData", response.data.data);
                    res(response);
                }).catch((error) => {
                    //  commit("setSelectUserLoading", false);
                    rej(error)
                });
            });
        },
        toggleGoogle2faStatus({ commit }, userData) {
            //  commit("setSelectUserLoading", true);
            return new Promise((res, rej) => {
                http('user').post(`google-2fa/status`, userData).then(response => {
                    // commit("setSelectUserData", response.data.data);
                    res(response);
                }).catch((error) => {
                    //  commit("setSelectUserLoading", false);
                    rej(error)
                });
            });
        },
    },

};
