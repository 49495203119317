<template>
  <v-row id="themeform-page">
    <v-col cols="12">
      <base-card>
        <v-card-title class="font-weight-bold">{{ `${themeId ? 'Edit' : 'Create'} Donate Page Theme` }}</v-card-title>
        <v-card-text>
          <v-form lazy-validation @submit.prevent="saveTheme">
            <v-row>
              <v-col cols="6">
                <v-combobox ref="sfcampaigns" :error-messages="$helpers.errorMsg('name', $v.model.sfcampaigns, 'Campaigns')"
                            v-model="model.sfcampaigns"
                            :items="campaignLists"
                            small-chips
                            :menu-props="{ closeOnClick: true, bottom: true, offsetY: true }"
                            @blur="searchClear"
                            label="Campaigns"
                            multiple
                            outlined
                            :return-object="false"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-skeleton-loader v-if="index === 0 && campaignListLoading"
                                       type="table-row"
                    ></v-skeleton-loader>
                    <v-chip small :key="item" v-if="!campaignListLoading && index <= 4" close @click:close="model.sfcampaigns.splice(index, 1)">
                      <span class="text-capitalize">{{ (campaignLists.find(data => data.value === item)).text }}</span>
                    </v-chip>
                    <span
                      v-if="!campaignListLoading && index === 5"
                      class="grey--text text-caption"
                    >
                                    (+{{ model.sfcampaigns.length - 5 }} others)
                                    </span>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6">
                <v-text-field ref="title" v-model="model.title" outlined :error-messages="$helpers.errorMsg('name', $v.model.title, 'Name')" label="Name" required/>
              </v-col>
            </v-row>
            <v-card-title class="font-weight-medium ma-0 pa-0">Theme Settings</v-card-title>
            <v-divider class="mb-2"></v-divider>
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Heading</v-subheader>
                <v-text-field v-model="model.heading" outlined label="" hide-details="auto" placeholder="Heading"/>
              </v-col>
              <v-col cols="11">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Description</v-subheader>
                <v-textarea v-model="model.description" outlined label="" auto-grow hide-details="auto" placeholder="Description"
                            rows="4" row-height="20"/>
              </v-col>
              <v-col cols="11">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Tax Deduction Notes</v-subheader>
                <v-textarea v-model="model.tax_deduction_notes" outlined label="" auto-grow hide-details="auto" placeholder="Description"
                            rows="4" row-height="20"/>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Once Amount</v-subheader>
                <DynamicInputTable :checkBxOne="true" :model="model.once_amount" @deleteRow="model.once_amount.length >  1 && model.once_amount.splice($event, 1);"/>
                <v-row class="ma-0">
                  <v-btn text class="mt-3" color="secondary" @click="model.once_amount.push({amount: null, tag: '', default: 0})">
                    <v-icon dark class="mr-1">mdi-plus-circle</v-icon>
                    Add Row
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Recurring Amount</v-subheader>
                <DynamicInputTable :checkBxOne="true" :model="model.recurring_amount" @deleteRow="model.recurring_amount.length >  1 && model.recurring_amount.splice($event, 1);"/>
                <v-row class="ma-0">
                  <v-btn text class="mt-3" color="secondary" @click="model.recurring_amount.push({amount: null, tag: '', default: 0})">
                    <v-icon dark class="mr-1">mdi-plus-circle</v-icon>
                    Add Row
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="11">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Donate background Desktop Image</v-subheader>
                <v-row v-if="model.donate_bg_imageLoad" class="fill-height" align-content="center" justify="center">
                  <v-col class="text-subtitle-1 text-center primary--text pb-0 font-weight-bold" cols="12">
                    Upload your image
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear color="secondary accent-4" indeterminate rounded height="6"/>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" align="center" v-if="!model.donate_bg_imageLoad && images.donate_bg_image">
                  <span class="text-subtitle-1 secondary--text mr-3 font-weight-bold text-uppercase">Preview previous image:</span>
                  <a class="info--text" :href="images.donate_bg_image" target="_blank">{{ images.donate_bg_image.substring(images.donate_bg_image.lastIndexOf('/') + 1) }}</a>
                </v-row>
                <v-file-input :clearable="false" placeholder="Upload Your Background Desktop Image" v-if="!model.donate_bg_imageLoad"
                              @change="$event && imageUploaded($event, 'bgDeskErr', 'donate_bg_image')" label="" outlined hide-details="auto" prepend-icon="mdi-file-upload" v-model="model.donate_bg_image"
                              accept="image/png, image/jpeg, image/jpg" :error-messages="imageError.bgDeskErr ? [imageError.sizeErr?'Please upload upto 8MB file size':'Image is invalid. (Only jpg,jpeg,png images are accepted)'] : []"></v-file-input>
              </v-col>
              <v-col cols="11">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Donate background Mobile Image</v-subheader>
                <v-row v-if="model.donate_bg_mobile_imageLoad" class="fill-height" align-content="center" justify="center">
                  <v-col class="text-subtitle-1 text-center primary--text pb-0 font-weight-bold" cols="12">
                    Upload your image
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear color="secondary accent-4" indeterminate rounded height="6"/>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" align="center" v-if="!model.meta_imageLoad && images.donate_bg_mobile_image">
                  <span class="text-subtitle-1 secondary--text mr-3 font-weight-bold text-uppercase">Preview previous image:</span>
                  <a class="info--text" :href="images.donate_bg_mobile_image" target="_blank">{{ images.donate_bg_mobile_image.substring(images.donate_bg_mobile_image.lastIndexOf('/') + 1) }}</a>
                </v-row>
                <v-file-input :clearable="false" placeholder="Upload Your Background Mobile Image" v-if="!model.donate_bg_mobile_imageLoad"
                              @change="$event && imageUploaded($event, 'bgMobErr', 'donate_bg_mobile_image')" label="" outlined hide-details="auto" prepend-icon="mdi-file-upload" v-model="model.donate_bg_mobile_image"
                              accept="image/png, image/jpeg, image/jpg" :error-messages="imageError.bgMobErr ? [imageError.sizeErr?'Please upload upto 8MB file size':'Image is invalid. (Only jpg,jpeg,png images are accepted)'] : []">
                </v-file-input>
              </v-col>
              <v-col cols="11">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Thankyou Banner</v-subheader>
                <v-textarea v-model="model.thank_you_banner" outlined label="" auto-grow hide-details="auto" placeholder="Description"
                            rows="4" row-height="20"/>
              </v-col>
              <v-col cols="11">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Meta Title</v-subheader>
                <v-textarea v-model="model.meta_title" outlined label="" auto-grow hide-details="auto" placeholder="Description"
                            rows="4" row-height="20"/>
              </v-col>
              <v-col cols="11">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Meta Descripition</v-subheader>
                <v-textarea v-model="model.meta_descripition" outlined label="" auto-grow hide-details="auto" placeholder="Description"
                            rows="4" row-height="20"/>
              </v-col>
              <v-col cols="11">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Meta Image</v-subheader>
                <v-row v-if="model.meta_imageLoad" class="fill-height" align-content="center" justify="center">
                  <v-col class="text-subtitle-1 text-center primary--text pb-0 font-weight-bold" cols="12">
                    Upload your image
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear color="secondary accent-4" indeterminate rounded height="6"/>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" align="center" v-if="!model.meta_imageLoad && images.meta_image">
                  <span class="text-subtitle-1 secondary--text mr-3 font-weight-bold text-uppercase">Preview previous image:</span>
                  <a class="info--text" :href="images.meta_image" target="_blank">{{ images.meta_image.substring(images.meta_image.lastIndexOf('/') + 1) }}</a>
                </v-row>
                <v-file-input placeholder="Upload Your Meta Image" :clearable="false" v-if="!model.meta_imageLoad"
                              @change="$event && imageUploaded($event, 'metaErr', 'meta_image')" label="" outlined hide-details="auto" prepend-icon="mdi-file-upload" v-model="model.meta_image"
                              accept="image/png, image/jpeg, image/jpg" :error-messages="imageError.metaErr ? [imageError.sizeErr?'Please upload upto 8MB file size':'Image is invalid. (Only jpg,jpeg,png images are accepted)'] : []">
                </v-file-input>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-4">
              <v-col cols="4">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Default Donate Mode</v-subheader>
                <v-row class="ma-0 pa-0">
                  <v-switch false-value="O" true-value="M" v-model="model.default_donate_mode" hide-details="auto" class="pt-0 pr-5"
                            inset :label="`${model.default_donate_mode === 'O' ? 'Once' : 'Monthly'}`"
                  ></v-switch>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Show Facebook Button</v-subheader>
                <v-row class="ma-0 pa-0">
                  <v-switch false-value="Off" true-value="On" v-model="model.show_like_button" hide-details="auto" class="pt-0 pr-5"
                            inset :label="`${model.show_like_button === 'On' ? 'On' : 'Off'}`"
                  ></v-switch>
                </v-row>
              </v-col>
              <v-col cols="3">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Show Header Phone Number</v-subheader>
                <v-row class="ma-0 pa-0">
                  <v-switch false-value="Off" true-value="On" v-model="model.show_phone" hide-details="auto" class="pt-0 pr-5"
                            inset :label="`${model.show_phone === 'On' ? 'On' : 'Off'}`"
                  ></v-switch>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-4">
              <v-col cols="4">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Show Campaign Progress Bar</v-subheader>
                <v-row class="ma-0 pa-0">
                  <v-switch false-value="Off" true-value="On" v-model="model.show_target" hide-details="auto" class="pt-0 pr-5"
                            inset :label="`${model.show_target === 'On' ? 'On' : 'Off'}`"
                  ></v-switch>
                </v-row>
              </v-col>
              <v-col cols="7">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Target Mode</v-subheader>
                <v-row class="ma-0 pa-0">
                  <v-switch false-value="D" true-value="P" v-model="model.target_mode" hide-details="auto" class="pt-0 pr-5"
                            inset :label="`${model.target_mode === 'P' ? 'Percentage' : 'Dollar Amount'}`"
                  ></v-switch>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="my-2 mb-5"/>
            <v-row justify="end">
              <v-btn type="submit" color="secondary" class="font-weight-bold mr-4" :disabled="selectThemeLoading">
                Save
              </v-btn>
              <v-btn type="button" color="primary" :disabled="selectThemeLoading" class="font-weight-bold mr-4" to="/donate-page-themes">
                <v-icon left dark>mdi-chevron-left</v-icon>
                Back
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";

import {DynamicInputTable} from "@/components/common";

export default {
  metaInfo: {
    title: "Donate Page Themes",
  },
  components: {
    DynamicInputTable
  },
  data: (vm) => ({
    themeId: vm.$route.params.themeId,
    show: false,
    imageError: {
      bgDeskErr: false,
      bgMobErr: false,
      metaErr: false,
      sizeErr: false
    },
    images: {
      donate_bg_image: null,
      donate_bg_mobile_image: null,
      meta_image: null
    },
    model: {
      sfcampaigns: [],
      title: '',
      heading: '',
      description: '',
      once_amount: [{
        amount: null,
        tag: '',
        default: 1
      }],
      recurring_amount: [{
        amount: null,
        tag: '',
        default: 1
      }],
      donate_bg_image: null,
      donate_bg_mobile_image: null,
      thank_you_banner: "",
      meta_title: "",
      meta_descripition: "",
      meta_image: null,
      meta_image_use_default: 1,
      default_donate_mode: "O",
      show_like_button: "On",
      show_phone: "On",
      show_target: "On",
      target_mode: "P",
    }
  }),
  validations: {
    model: {
      title: { required },
      sfcampaigns: { required }
    }
  },
  computed: {
    ...mapGetters(["campaignLists", "getSelectedTheme", "selectThemeLoading", "campaignListLoading"]),
  },
  watch: {
    'getSelectedTheme': function (newValue) {
      const tempModel = newValue;
      Object.keys(this.images).map(key => {
        this.images[key] = newValue[key];
        delete tempModel[key];
      });
      this.model = { ...this.model, ...tempModel };
    }
  },
  created() {
    if (this.themeId) {
      this.getThemeData(this.themeId);
    }
    this.getCampaignLists();
  },
  methods: {
    ...mapActions(['getCampaignLists', 'getThemeData', 'updateThemeData', 'createTheme',
      'uploadImages', 'deleteImages']),

    saveTheme() {
      this.$v.$touch();
      if (this.$v.$invalid || this.imageError.bgDeskErr || this.imageError.bgMobErr || this.imageError.metaErr) {
        this.$helpers.focusErrorElement(this.$v.model, this.$refs);
        return;
      }
      if (this.themeId) {
        this.updateTheme();
      } else {
        this.createNewTheme();
      }
    },
    createNewTheme() {
      const self = this;
      Object.keys(this.images).map(key => this.model[key] = this.images[key]);
      this.createTheme(this.model).then((response) => {
        if (!response || !response.data) {
          return;
        }
        self.$store.commit("snackbar/SHOW_MESSAGE", {
          text: "Theme Created Successfully",
          color: "success",
        });
        self.$router.push("/donate-page-themes");
      }).catch(() => {
      });
    },
    updateTheme() {
      const self = this;
      Object.keys(this.images).map(key => this.model[key] = this.images[key]);
      this.updateThemeData(this.model).then((response) => {
        if (!response || !response.data) {
          return;
        }
        self.$store.commit("snackbar/SHOW_MESSAGE", {
          text: "Theme Updated Successfully",
          color: "success",
        });
        self.$router.push("/donate-page-themes");
      }).catch(() => {
      });
    },
    searchClear() {
      if (this.model.sfcampaigns.length === 0) {
        this.$refs.sfcampaigns.lazySearch = '';
      }
    },
    imageUploaded(event, field, var_name) {
      const acceptType = ['jpg', 'jpeg', 'png'],
        fileName = event && event.name ? (event.name.slice(event.name.lastIndexOf('.') + 1)).toLowerCase() : null;
      if (fileName && acceptType.indexOf(fileName) === -1) {
        this.imageError[field] = true;
      } else {
        if (event.size > 8388608) {
          this.imageError[field] = this.imageError.sizeErr = true;
          return;
        }
        this.imageError[field] = this.imageError.sizeErr = false;
        this.uploadedFile(event, var_name);
      }
    },
    uploadedFile(event, var_name) {
      this.model[`${var_name}Load`] = true;
      const reqData = new FormData(),
        headers = { 'Content-Type': 'multipart/form-data' };
      reqData.append('file', event);
      this.uploadImages(reqData, headers).then((response) => {
        this.deleteSaveImg(var_name);
        const changeModel = {};
        this.images[var_name] = response.data.url;
        changeModel[var_name] = null;
        changeModel[`${var_name}Load`] = false;
        this.model = Object.assign({}, this.model, changeModel);
      }).catch(() => {
        const changeModel = {};
        changeModel[`${var_name}Load`] = false;
        changeModel[var_name] = null;
        this.model = Object.assign({}, this.model, changeModel)
      });
    },
    deleteSaveImg(fieldName) {
      if (this.images[fieldName]) {
        const reqData = {
          file: this.images[fieldName]
        }
        this.deleteImages(reqData);
      }
    }
  }
};
</script>
