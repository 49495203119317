import VuePerfectScrollbar from "vue-perfect-scrollbar";

// theme Main scss
import "@/assets/scss/octavia-design-system/octavia-design-system.scss";

// plugins menu

// ApexCharts
import "@/plugins/apexChart.js";

// VueCalendar
import "@/plugins/calendar.js";

// axios
import axios from "axios";
import VueAxios from "vue-axios";

// form-Wizard
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import VueMeta from "vue-meta";

//validation
import Vuelidate from 'vuelidate';

// end plugins

export default {
  install(Vue) {
    Vue.use(VueMeta);
    Vue.use(VueFormWizard);
    Vue.use(VueAxios, axios);
    Vue.use(Vuelidate);
    Vue.component(
      "VerticalSix",
      // The `import` function returns a Promise.

      () => import("@/layouts/component/Index")
    );

    Vue.component("VuePerfectScrollbar", VuePerfectScrollbar);
  },
};
