import http from '@/http';

export default {
    actions: {
        updateLedgersIF({commit}, reqData){
            return new Promise((res, rej) => {
                http('donate').post('ledgers', reqData).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Amount Added Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        }
    }
};
