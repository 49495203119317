<template>
    <datatable ref="dtComponent" :modelData="dtData" @deleteItem="deletePermissionItem"/>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Permissions",
  metaInfo: {
    title: "Permissions",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
  },
  data() {
    return {
      dtData: {
        title: 'Permissions',
        headers: [
            { text: 'Name', align: 'start', value: 'name'},
            {text: 'Action', value: 'action', sortable: false}
        ],
        actions: ['edit', 'delete'],
        headerActions: ['reset', 'create'],
        createLink: '/permissions/create-permission',
        editLink: '/permissions/edit-permission/',
        pageLink: `${window.VUE_APP_MS_URL}user/permissions?page=1`
      }
    };
  },
  computed: {
    ...mapGetters(["metaInfoDT", "activeLink", "dtLists", "searchDT"])
  },
  methods: {
    ...mapActions(["deletePermission"]),

    deletePermissionItem(item) {
      let pageLink = new URL(this.activeLink);
      if (this.dtLists.length === 1 || !this.activeLink) {
        pageLink = new URL(this.dtData.pageLink);
        if (this.searchDT !== '') {
          pageLink.searchParams.set("q", this.searchDT);
        }
        pageLink.searchParams.set("page", 1);
      }
      this.deletePermission(item.id).then(() => this.$refs.dtComponent.pageLoading(pageLink) );
    }
  },
};
</script>
