
import http from '@/http';
import globalHelper from '@/helpers/global.helper';
import { followUp, experian, stripe } from './sub-modules'

export default {
    modules: {
        followUp,
        experian,
        stripe
    },
    state: {
        model: {},
        searchLists: [],
        searchLoading: false,
        selectedDonor: {},
        previousSelectedDonor: {},
        existingCardLists: [],
        exisitingCardsLoading: false,
        paymentToken: null,
        donatePaymentData: null,
        apiReqData: {},
        campaignLists: [],
        currentStep: 1
    },
    getters: {
        model: state => state.model,
        apiReqData: state => state.apiReqData,
        selectedDonor: state => state.selectedDonor,
        previousSelectedDonor: state => state.previousSelectedDonor,
        donorSearchLists: state => state.searchLists,
        donorSearchLoading: state => state.searchLoading,
        donorExistingCardList: state => state.existingCardLists,
        donorExistingLoading: state => state.exisitingCardsLoading,
        paymentToken: state => state.paymentToken,
        donatePaymentData: state => state.donatePaymentData,
        campaignLists: state => state.campaignLists,
        currentStep: state => state.currentStep
    },
    mutations: {
        setSearchLists(state, data) {
            state.searchLists = data;
            state.searchLoading = false;
        },
        setCampaignLists(state, data) {
            state.campaignLists = data;
        },
        setCurrentStep(state, data) {
            state.currentStep = data || 1;
        },
        setModel(state, data) {
            state.model = data;
            if (data) {
                state.apiReqData = {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    gender: data.gender,
                    address1: data.address1,
                    suburb: data.suburb,
                    state: data.state,
                    postcode: data.postcode,
                    country: data.country,
                    email: data.email,
                    phone: data.phone,
                    mobile: data.mobile,
                    amount: data.amount,
                    frequency: data.paymentFrequency,
                    contact_id: data.id && data.id !== '' ?  data.id : null,
                    payment_type: data.paymentMethod,
                    payment_method_id: data.newCard ? data.paymentToken : data.existing_pay_id,
                    start_date: data.paymentFrequency === 'M' ? data.date : null,
                    receipt_name: data.receipt_name,
                    donor_infos: data.donor_infos,
                    donation_type: data.donation_type,
                    sf_campaign_id: data.sf_campaign_id || null,
                    sf_campaign_name: data.sf_campaign_name || null,
                    source: data.source,
                    do_not_read: data.do_not_read || 0,
                    receipt_preference__c:  data.receipt_preference__c || '',
                    email_opt_out__c:   data.email_opt_out__c || [],
                    direct_mail_opt_out__c:  data.direct_mail_opt_out__c || [],
                    sms_opt_out__c:  data.sms_opt_out__c || [],
                    phone_opt_out__c: data.phone_opt_out__c || [],
                    sf_id: data.sf_id || null,
                    opt_in: data.opt_in || null,
                    user_name: globalHelper.getCurData('curUser') ? globalHelper.getCurData('curUser').name || null : null,
                    referer_slug: data.referer_slug || null,
                };
            } else {
                state.apiReqData = {};
            }
        },
        updateModel(state, data) {
            state.model = {...state.model, ...data};
            if (data) {
                state.apiReqData = {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    gender: data.gender,
                    address1: data.address1,
                    suburb: data.suburb,
                    state: data.state,
                    postcode: data.postcode,
                    country: data.country,
                    email: data.email,
                    phone: data.phone,
                    mobile: data.mobile,
                    amount: data.amount,
                    frequency: data.paymentFrequency,
                    contact_id: data.id && data.id !== '' ?  data.id : null,
                    payment_type: data.paymentMethod,
                    payment_intent_id: data.payment_intent_id,
                    client_secret: data.client_secret,
                    start_date: data.start_date,
                    receipt_name: data.receipt_name,
                    donor_infos: data.donor_infos,
                    donation_type: data.donation_type,
                    sf_campaign_id: data.sf_campaign_id || null,
                    sf_campaign_name: data.sf_campaign_name || null,
                    source: data.source,
                    do_not_read: data.do_not_read || 0,
                    receipt_preference__c:  data.receipt_preference__c || '',
                    email_opt_out__c:   data.email_opt_out__c || [],
                    direct_mail_opt_out__c:  data.direct_mail_opt_out__c || [],
                    sms_opt_out__c:  data.sms_opt_out__c || [],
                    phone_opt_out__c: data.phone_opt_out__c || [],
                    sf_id: data.sf_id || null,
                    opt_in: data.opt_in || null,
                    user_name: globalHelper.getCurData('curUser') ? globalHelper.getCurData('curUser').name || null : null,
                    referer_slug: data.referer_slug || null,
                };
            } else {
                state.apiReqData = {};
            }
        },
        updatePayReqModel(state, data) {
            const dataValue = {...{
                payment_method_id : null,
                start_date: data.date1,
                payment_type: data.paymentMethod,
                contact_id: data.id && data.id !== '' ?  data.id : null,
                frequency: data.paymentFrequency,
                do_not_read: data.do_not_read || 0,
                receipt_preference__c:  data.receipt_preference__c || '',
                email_opt_out__c:   data.email_opt_out__c || [],
                direct_mail_opt_out__c:  data.direct_mail_opt_out__c || [],
                sf_campaign_id: data.sf_campaign_id || null,
                sms_opt_out__c:  data.sms_opt_out__c || [],
                phone_opt_out__c: data.phone_opt_out__c || [],
                sf_id: data.sf_id || null,
                opt_in: data.opt_in || null,
                user_name: globalHelper.getCurData('curUser') ? globalHelper.getCurData('curUser').name || null : null,
                referer_slug: data.referer_slug || null,
            }, ...data},
                defaultValue = {};
            if (data.paymentMethod === 'CQ') {
                defaultValue['drawer'] = data.drawer;
                defaultValue['account_no'] = data.account_no;
                defaultValue['bsb'] = data.bsb;
                defaultValue['cheque_no'] = data.cheque_no;
            }
            state.apiReqData = globalHelper.insertObjValue(state.apiReqData, dataValue, defaultValue);
        },
        setSearchLoading(state, data) {
            state.searchLoading = data;
        },
        setSelectedDonor(state, data) {
            delete data.sf_id;
            state.selectedDonor = data;
        },
        setPreviousSelectedDonor(state, data) {
            state.previousSelectedDonor = data;
        },
        setExistingCardsLoading(state, data) {
            state.exisitingCardsLoading = data;
        },
        setDonorExistingCards(state, data) {
            if (data.length === 0) {
                state.existingCardLists = [];
                return;
            }
            data.map(item =>  {
                if (item.type === 'au_becs_debit') {
                    item.value = `BECS Direct Debit xxxxx${item.meta.last4}`;
                } else {
                    item.value = `${item.meta.brand.charAt(0).toUpperCase()+item.meta.brand.slice(1)} xxxx xxxx xxxx ${item.meta.last4}   ${item.meta.exp_month}/${((item.meta.exp_year).toString()).slice(-2)}`;
                }
                return item;
            });

            state.existingCardLists = data.map(card => {
                card.existing_pay_id = card.id;
                delete card.id;
                return card;
            });

            state.exisitingCardsLoading = false;
        },
        setPaymentToken(state, data) {
            state.paymentToken = data;
        },
        setDonatePaymentData(state, data) {
            state.donatePaymentData = data;
        },
        resetPayment(state) {
            state.donatePaymentData = null;
            state.paymentToken = null;
        }
    },
    actions: {
        modelUpdate({ commit }, data) {
            commit("setModel", data);
        },
        getSearchLists({ commit }, data) {
            commit("setSearchLoading", true);
            return new Promise((res, rej) => {
                http('salesforce').post('contacts/search', data).then(response => {
                    if (response) {
                        commit("setSearchLists", response.data.data);
                    }
                    res(response);
                }).catch((error) => {
                    if(!error.__CANCEL__) commit("setSearchLoading", false);
                    rej(error)
                });
            });
        },
        refreshData({ commit }) {
            commit("setSearchLists", []);
            commit("setModel", {});
            commit("setSelectedDonor", {});
            commit("setPreviousSelectedDonor", {});
            commit("resetPayment");
            commit("setFollowUpModel", {});
            commit("setDonorExistingCards", []);
            commit("setCurrentStep", null);
        },
        getStripePaymentMethods({ commit }, donorID) {
            commit("setExistingCardsLoading", true);
            return new Promise((res, rej) => {
                http('stripe').get(`customers/${donorID}/paymentmethods`).then(response => {
                    if (response) {
                        commit("setDonorExistingCards", response.data.data);
                    }
                    res(response);
                }).catch((error) => {
                    commit("setExistingCardsLoading", false);
                    rej(error)
                });
            });
        },
        setStripeSetupIndent({ commit }, data) {
            return new Promise((res, rej) => {
                http('stripe').post('paymentmethods/setup_intent', data)
                    .then(response => res(response.data))
                    .catch((error) => rej(error));
            });
        },
        donatePaymentAmount({ commit }, data) {
            const endpoint = data.existingLP_ID ? `donates/${data.existingLP_ID}/catchup` : 'donates';
            return new Promise((res, rej) => {
                http('donate').post(endpoint, data).then(response => {
                    commit("toggleAppLoading");
                    const resData = response.data.data;
                    if (response && resData.status === 0) {
                        commit("snackbar/SHOW_MESSAGE", {
                            text: resData.failed_reason,
                            color: "error",
                        });
                    } else {
                        commit("snackbar/SHOW_MESSAGE", {
                            text: "Donation Processed Successfully",
                            color: "success",
                        });
                        commit("setDonatePaymentData", resData);
                    }
                    res(response);
                }).catch((error) => {
                    commit("toggleAppLoading");
                    rej(error)
                });
            });
        },
        storySave ({ commit }, data) {
            return new Promise((res, rej) => {
                http('donate').post('stories', data).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text:"Submitted Successfully",
                        color: "success",
                    });
                    commit("resetPayment");
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        }
    },

};
