import { APP_VARIABLES } from '@/constants/app-variable';
import _ from 'lodash';

import menuItems from "@/constants/sidebar-menu";
import moment from 'moment-timezone'
import momentDurtion from 'moment-duration-format';

//default timezone updated
moment.tz.setDefault('Australia/Melbourne');
moment(momentDurtion);

class globalHelper {

  getConstantsValue(cName) {
    switch (cName) {
      case 'curUser':
        return APP_VARIABLES.currentUser;
      case 'curDonate':
        return APP_VARIABLES.currentDonate;
      case 'lastSeenPage':
        return APP_VARIABLES.lastSeenPage;
      default:
        return cName;
    }
  };

  getCurData(dataType) {
    switch (dataType) {
      case 'curUser':
        return JSON.parse(localStorage.getItem(APP_VARIABLES.currentUser)) || null;
      case 'curToken':
        return this.getCurData('curUser')?.token || '';
      case 'curUserId':
        return this.getCurData('curUser')?.id || '';
      case 'curUserRole':
        return this.getCurData('curUser')?.roles || [];
      case 'curUserPermissions':
        return this.getCurData('curUser')?.permissions || [];
      case 'curDonate':
        return localStorage.getItem(APP_VARIABLES.currentDonate) || false;
      case 'lastSeenPage':
        return localStorage.getItem(APP_VARIABLES.lastSeenPage) || '/login';
      default:
        break;
    }
  }

  lsPush(name, value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(this.getConstantsValue(name), value);
  }

  updateLSData(changeDatas, name = "curUser") {
    const updateData = { ...getCurData(name), ...changeDatas };
    localStorage.setItem(this.getConstantsValue(name), JSON.stringify(updateData));
  }

  setURLparams(dataObj, link, type = null) {
    let url = type ? link : new URL(link);
    if (type) {
      const params = Object.keys(dataObj).map(key => dataObj[key] ? key + '=' + dataObj[key] : '').join('&');
      url = url + params;
      return url;
    }
    Object.keys(dataObj).map(key => {
      if ((Array.isArray(dataObj[key]) && dataObj[key].length) || (!Array.isArray(dataObj[key]) && (dataObj[key] ||
        (typeof dataObj[key] === 'number' && dataObj[key] === 0)))) {
        url.searchParams.set(key, dataObj[key]);
      }
    });
    return url.toString();
  }

  getCurrRouteDetails(routeName) {
    const menuIndex = menuItems.findIndex(item => {
      if (item.children) {
        return item.children.some(child => {
          if (Array.isArray(child.route) && child.route.length) {
            return child.route.indexOf(routeName) !== -1;
          } else {
            return child.route === routeName;
          }
        });
      }
      return item.route === routeName;
    });
    return menuIndex === -1 ? null : (menuItems[menuIndex].children ?
      (menuItems[menuIndex].children.filter(child => {
        if (Array.isArray(child.route) && child.route.length) return child.route.indexOf(routeName) !== -1;
        else return child.route === routeName;
      }))[0] : menuItems[menuIndex])
  }

  getMomentDatas(format = null, date = new Date()) {
    let _moment = moment(date);

    if (format) {
      _moment = _moment.format(format);
    }

    return _moment;
  }

  getMomentDiff(toDate, getType = "days", fromDate = new Date()) {
    const startDate = this.getMomentDatas(null, fromDate),
      endDate = this.getMomentDatas(null, toDate);

    return startDate.diff(endDate, getType);
  }

  setMomentValues(date, type, value, format) {
    const momentVal = moment(date).set(type, value);
    if (format) return momentVal.format(format);
    return momentVal;
  }

  goDateGvnType(value, format, goType = 'days', type = 'add', date = new Date()) {
    let _moment = this.getMomentDatas(null, date)[type](value, goType);

    if (format) {
      _moment = _moment.format(format);
    }

    return _moment;
  }

  compareTwoDates(begin, format = 'h:mma', end) {
    const endData = end ? end : moment().format(format),
      beginningTime = moment(begin, format),
      endTime = moment(endData, format);
    return beginningTime.isBefore(endTime);
  }

  givenTimeRangeCheck(toDate = new Date(), fromDate = new Date()) {
    const currentTime = moment(),
      beforeTime = moment(fromDate),
      afterTime = moment(toDate);

    return currentTime.isBetween(beforeTime, afterTime);
  }

  givenSecToTime(seconds, moreDays = false) {
    // Get HH:m:ss format for more than one day (ex: 48:59:00);
    if (moreDays) return moment.duration(seconds, 'seconds').format("HH:mm:ss");

    // Get HH:m:ss format for only one day timer (ex: 23:59:00);
    return moment()
      .startOf("day")
      .seconds(seconds)
      .format("HH:mm:ss");
  }

  addHoursnDDateJs(dateName, value, date = null) {
    const currDate = date ? new Date(date) : new Date();
    switch (dateName) {
      case "hours":
        if (typeof value == 'number') {
          return currDate.setHours(currDate.getHours() + value);
        }
        return new Date(currDate.setHours(value.h, value.m, value.s, value.ms));
      case "date":
        return new Date(currDate.setDate(currDate.getDate() + value));
      default:
        return date;
    }
  }

  getJsDateBeginEnd(type = null, date = null, field = 'day') {
    const _moment = date ? moment(date) : moment();
    if (type) return new Date(_moment.startOf(field).toString());
    return new Date(_moment.add(1, 'days').endOf(field).toString());
  }

  errorMsg(name, model, fieldName, customMsg) {
    const errors = [];
    if (!model.$dirty) return errors;
    switch (name) {
      case "name":
        !model.required && errors.push(`${fieldName} is required`);
        break;
      case "email":
        !model.email && errors.push("Enter a valid email address");
        model.required !== undefined && !model.required && errors.push(`${fieldName} is required`);
        break;
      case "sameas":
        !model.sameAsfield && errors.push(`${fieldName} does not match`);
        !model.required && errors.push(`${fieldName} is required`);
        break;
      case "number":
        !model.required && errors.push(`${fieldName} is required`);
        !model.minValue && errors.push(`${fieldName} must be greater than zero`);
        customMsg && !model.maxValue && errors.push(`${fieldName} must be less than ${customMsg}`);
        break;
      case 'otp':
        !model.required && errors.push(`${fieldName} is required`)
        !model.isNumeric && errors.push(`${fieldName} must be numeric`)
        !model.exactLength && errors.push(`${fieldName} must be exactly 6 digits`)
        break;
      default:
        break;
    }

    return errors;
  }

  insertObjValue(originalObj, updateObj, insertObj = null) {
    Object.keys(updateObj).forEach(obj => {
      if (insertObj && insertObj.hasOwnProperty(obj) || originalObj.hasOwnProperty(obj)) {
        originalObj[obj] = updateObj[obj];
      }
    });

    return originalObj;
  }

  resetObjValue(originalObj, defaultObj) {
    const outputObject = {};
    Object.keys(originalObj).forEach(obj => outputObject[obj] = (defaultObj.hasOwnProperty(obj) ? defaultObj[obj] : ''));

    return outputObject;
  }

  getChangedObjVal(originalObj, updateObj, reqKeys = null) {
    const outputObject = {};
    Object.keys(originalObj).forEach(obj => {
      if (JSON.stringify(originalObj[obj]) !== JSON.stringify(updateObj[obj]) || (reqKeys && reqKeys === obj)) {
        outputObject[obj] = updateObj[obj]
      }
    });

    return _.isEmpty(outputObject) ? null : outputObject;
  }

  focusErrorElement(element, refs) {
    for (let key in Object.keys(element)) {
      const input = Object.keys(element)[key];
      if (input.includes("$")) return false;

      if (element[input].$error && refs && refs[input]) {
        refs[input].focus();
        break;
      }
    }
  }

  cloneData(value) {
    return JSON.parse(JSON.stringify(value));
  }

  getMomentMMYYLists(count = null, format = 'MMMM') {
    if (count) {
      return Array.apply(0, Array(count)).map(function (_, i) { return moment().add(i, 'y').format(format) });
    }
    return Array.apply(0, Array(12)).map(function (_, i) { return moment().month(i).format(format) });
  }

  getMomentMonYearFormat(data, format, type) {
    if (type) return moment().year(data).format(format);
    return moment().month(data).format(format);
  }

  dateValidation(date, format) {
    return moment(date, format, true).isValid();
  }

  avoidInputScrollable(evt) {
    evt.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })
  }

  avoidInputArrowKeyPress(e) {
    if (e.which === 38 || e.which === 40) {
      e.preventDefault();
    }
  }

  removeLSStorage(name) {
    localStorage.removeItem(this.getConstantsValue(name));
  }

  logout() {
    localStorage.setItem(APP_VARIABLES.lastSeenPage, location.pathname);
    localStorage.removeItem(APP_VARIABLES.currentUser);
    localStorage.removeItem(APP_VARIABLES.currentDonate);
  }

  currencyFormat(value, cents) {
    if(value == '-') {
      return value;
    }

    if(!value || typeof value == 'undefined') {
      return 0;
    }

    if(cents === false) {
      value = Math.trunc(value += 100000  )
    }

    return Intl.NumberFormat('en-AU').format(value);
  }
}

export default new globalHelper();