import http from '@/http';

export default {
    state: {
        selectedTheme: {},
        selectThemeLoading: false
    },
    getters: {
        getSelectedTheme: state => state.selectedTheme,
        selectThemeLoading: state => state.selectThemeLoading,
    },
    mutations: {
        setSelectThemeData(state, data) {
            state.selectedTheme = data;
            state.selectThemeLoading = false;
        },
        setSelectThemeLoading(state, data) {
            state.selectThemeLoading = data;
        },
    },
    actions: {
        createTheme({ commit }, themeData) {
            commit("setSelectThemeLoading", true);
            return new Promise((res, rej) => {
                http('donate').post('themes', themeData).then(response => {
                    commit("setSelectThemeLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setSelectThemeLoading", false);
                    rej(error)
                });
            });
        },
        createDuplicate({ commit }, themeId) {
            return new Promise((res, rej) => {
                http('donate').get(`themes/${themeId}/clone`).then(response => {
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        getThemeData({ commit }, themeId) {
            commit("setSelectThemeLoading", true);
            return new Promise((res, rej) => {
                http('donate').get(`themes/${themeId}`).then(response => {
                    commit("setSelectThemeData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectThemeLoading", false);
                    rej(error)
                });
            });
        },
        updateThemeData({ commit }, themeData) {
            commit("setSelectThemeLoading", true);
            return new Promise((res, rej) => {
                http('donate').put(`themes/${themeData.id}`, themeData).then(response => {
                    commit("setSelectThemeData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectThemeLoading", false);
                    rej(error)
                });
            });
        },
        deleteTheme({ commit }, themeId) {
            return new Promise((res, rej) => {
                http('donate').deleteReq(`themes/${themeId}`).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Theme Deleted Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        uploadImages({ commit }, imgData) {
            return new Promise((res, rej) => {
                http('user').post(`file/upload`, imgData).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Image Uploaded Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    commit("setSelectThemeLoading", false);
                    rej(error)
                });
            });
        },
        deleteImages({ commit }, imgData) {
            return new Promise((res, rej) => {
                http('user').post(`file/delete`, imgData).then(response => {
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        updateThemeActive({ commit }, themeId) {
            return new Promise((res, rej) => {
                commit("setItemActiveLoading", true);
                http('donate').get(`themes/${themeId}/activate`).then(response => {                    
                    commit("setActiveDTItem", themeId);
                    commit("setItemActiveLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setItemActiveLoading", false);
                    rej(error)
                });
            });
        }
    },

};
