import Vue from 'vue';

//NPM Style Sheets
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import '@/assets/css/address.css';
import '@/assets/js/edq-validate-ui.min.js';
import 'vuetify/dist/vuetify.min.css';

//Component Imports
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import filters from '@/filters';
import globalHelper from '@/helpers/global.helper';

//Theme plugin Imports
import vuetify from '@/plugins/vuetify';
import '@/plugins';
import themeKit from '@/plugins/theme.kit';

//Websocket Echo
import Echo from 'laravel-echo'

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: window.VUE_APP_PUSHER_KEY,
  wsHost: window.VUE_APP_PUSHER_HOST,
  wsPort: window.VUE_APP_PUSHER_PORT,
  wssPort: window.VUE_APP_PUSHER_PORT,
  disableStats: false,
  forceTLS: window.VUE_APP_PUSHER_SSL,
  enabledTransports: window.VUE_APP_PUSHER_SSL ? ['wss','ws'] : ['ws'],
  withoutInterceptors: true
});

Vue.use(themeKit);
Vue.config.productionTip = false;

Vue.use({
  install() {
    Vue.helpers = globalHelper
    Vue.prototype.$helpers = globalHelper
  }
});

Vue.use({
    install() {
      Vue.filters = filters
      Vue.prototype.$filters = filters
    }
});

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
