<template>
  <div id="challenges-page">
    <datatable ref="dtComponent" :modelData="dtData" :combineData="{value:['start_time', 'end_time'], dateFormat: true, join: ' - '}"/>
  </div>
</template>

<script>
export default {
  name: "Challenges",
  metaInfo: {
    title: "Challenges",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
  },
  data() {
    return {
      dtData: {
        title: 'Challenges',
        expandable: true,
        headers: [
            { text: "",  align: 'start', value: "data-table-expand" },
            { text: 'Challenge Name', value: 'title'},
            { text: "Type", value: "type_label" },
            { text: "Date/Time", value: 'combine' },
            { text: "Challenge Items", value: 'htmlelement', field: 'fullTitle' },
            {text: '# of donations', value: 'noOfDonation'},
            {text: '$ value of donations ', value: 'raisedAmount'}
        ],
        expandHeaders: [
          {text: 'Date/Time',  align: 'start', value:'datefield', field: 'created_at'},
          {text: 'Donor', value:'donor_name'},
          {text: 'Payment Method', value:'frequency_label'},
          {text: 'Amount', value:'amount'},
        ],
        actions: [],
        headerActions: ['activeChallenges', 'expand', 'reset'],
        pageLink: `${window.VUE_APP_MS_URL}donate/targets?columns=raisedAmount,noOfDonation,fullTitleWOTime&with_trashed=1&page=1`,
        expandDatas: {
          link: `${window.VUE_APP_MS_URL}donate/donates?tr=1`,
          field: 'id',
          passField: 'tid',
          method: 'get',
        }
      }
    };
  }
};
</script>
