<template>  
    <v-row id="error-403-page" class="flex-column ma-0 pa-0" align="center" justify="center">
        <div class="primary--text text-center font-weight-bold pa-0 d-flex align-center justify-center mb-3">
            <div class="error-code">4</div>
            <v-img src="@/assets/images/hand-denied.png" width="200" height="200" />
            <div class="error-code">3</div>
        </div>
        <div class="text-h4 text-center primary--text font-weight-bold mb-4 text-uppercase">page access denied !</div>
        <div class="text-center text-uppercase mt-6 mb-2 secondary--text">
            YOU SEEM TO BE TRYING TO FIND THIS WAY HOME
        </div>
        <v-btn class="ma-2" outlined color="primary" @click="$router.push('info')">Back To Home</v-btn>
    </v-row>
</template>

<script>
export default {
    name: 'Error403',
    metaInfo: {
        title: 'Error 403',
    },

}
</script>
