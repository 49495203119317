<template>
  <div>
    <v-subheader v-if="item.divider" class="text-12 mt-8 text-uppercase">{{
      item.divider
    }}</v-subheader>

    <v-list-item
      :to="`/${item.to}`"
      @click="sidebarClose(item)"
      :active-class="` ${isDark ? 'primary--text ' : 'white--text'}`"
      :height="48"
      class="text-18 base-item mb-2"
      :class="isDark ? 'primary--text ' : 'white--text'"
      v-ripple="{ class: 'primary--text' }"
    >
      <v-list-item-icon
        v-if="text"
        class="v-list-item__icon--text"
        v-text="computedText"
      />

      <v-list-item-icon class="min-w-0" v-else-if="item.icon">
        <v-icon class="text-16 vertical-sidebar-icon" :color="isDark ? 'primary ' : 'white'"
        v-text="item.icon" />
      </v-list-item-icon>

      <v-list-item-content
        class="pa-0 text-13 vertical-sidebar-text"
        v-if="item.title || item.subtitle"
      >
        <v-list-item-title class="ma-0 text-13" v-text="item.title" />

        <v-list-item-subtitle v-text="item.subtitle" />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable";
import { mapActions } from "vuex";

import menuItems from "@/constants/sidebar-menu";

export default {
  name: "ItemTwo",
  mixins: [Themeable],
  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reportSubRoute: []
    }
  },
  computed: {
    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },
  created() {
    const reportRouters = (menuItems.find(item => item.title === 'Reports')).children;
    reportRouters.map(item => this.reportSubRoute.push(item.to));
  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer"]),
    sidebarClose(item) {
      if (item.to && ['home', 'lp-entry', 'producer', 'onair', 'staff', 'tally', 'sound-effect'].indexOf(item.to) !== -1 ||
      this.reportSubRoute.indexOf(item.to) !== -1 || (!item.to && item.route && item.route === 'Home')) {
        this.changeVerticalSidebarDrawer();
      }
    }
  }
};
</script>

