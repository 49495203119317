<template>
    <div id="donations-page">
        <AdvanceFilter :clearAdvFilter="clearAdvFilter" v-if="!targetId" @reset="resetFilter" @search="searchAdvFilter"/>
        <datatable ref="dtComponent" :modelData="dtData" @clearFilter="clearAdvFilter=true;searchLink = searchData = null" @export="exportDoc"
                   @customAction="targetId && moveToChallenge($event.item.id)" :searchModel="searchData"
                   :dtOptions="{itemsPerPage: 50}"
                   :footerOptions="{itemsPerPageOptions:[10, 50, 100, -1]}"
        ></datatable>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "Donations",
  metaInfo: {
    title: "Donations",
  },
  props: ['targetId'],
  components: {
    Datatable: () => import("@/components/common/datatable"),
    AdvanceFilter: () => import("@/pages/reports/donations/components/AdvanceFilter"),
  },
  data() {
    return {
        clearAdvFilter: false,
        dtData: {
            title: 'Donations',
            headers: [
                { text: 'SF ID', align: 'start', value: 'htmlelement', field: 'sf_id', link: window.VUE_APP_SF_DOMAIN},
                { text: 'Campaign', value: 'campaign'},
                { text: 'Target', value: 'target'},
                { text: 'Status', value: 'customfield', field: 'inspiration_fund', valtype: 'bool', valueList: [{
                  value: 0 , status: 'Tally'}, {value: 1 , status: 'Held'}, {value: 2 , status: 'IF'}]},
                { text: 'On / Off Air', value: 'label', field: 'sf_fc_pipeline', color: 'primary'},
                { text: 'Date', value: 'datefield', field: 'created_at', format: 'date'},
                { text: 'Time', value: 'datefield', field: 'created_at', format: 'time'},
                { text: 'Receipt Name', align: 'start', value: 'receipt_name'},
                { text: 'Amount', align: 'start', value: 'amount'},
                { text: "Frequency", value: "frequency_label" },
                {text: ' Payment ID', value: 'payment_id'},
                {text: ' Payment Method', value: 'payment_type_label'},
            ],
            expandable: false,
            actions: [],
            headerActions: ['reset', 'export'],
            pageLink: `${window.VUE_APP_MS_URL}donate/donates?page=1`
        },
        searchLink: null,
        searchData: null
    };
  },
  computed: {
    ...mapGetters(["metaInfoDT", "activeLink"]),
  },
  created() {
    if (this.targetId) {
      this.dtData.pageLink = this.$helpers.setURLparams({tid: this.targetId, filter: 'producer_target'}, this.dtData.pageLink);
      this.dtData.headers.push({text: 'Action', value: 'action', sortable: false});
      this.dtData.customButtonActions = [{
          icon: 'mdi-file-arrow-left-right',
          tooltipName: 'Move to Challenge',
          name: 'To Challenge',
          color: 'secondary',
          btnSmall: true
      }];
      this.dtData.headerActions.splice(1, 1);
    }
  },
  methods: {
    ...mapActions(['pageNavigation', 'updateDonate']),

    resetFilter() {
        this.searchLink = this.searchData = null;
        this.$refs.dtComponent.searchDT = '';
        this.$refs.dtComponent.pageLoading(this.dtData.pageLink, true);
    },
    searchAdvFilter(searchModel) {
        this.searchLink = this.$helpers.setURLparams(searchModel, this.dtData.pageLink);
        this.clearAdvFilter = false;
        this.searchData = searchModel;
        this.$refs.dtComponent.searchDT = '';
        this.$refs.dtComponent.pageLoading(this.searchLink, true);
    },
    exportDoc() {
      this.$refs.dtComponent.exportLoading = true;
      const downldLink = new URL(this.searchLink ? this.searchLink  :  this.dtData.pageLink);
      downldLink.searchParams.set("export", 1);

      if(this.$refs.dtComponent.searchDT != ''){
        downldLink.searchParams.set("q", this.$refs.dtComponent.searchDT);
      }

      this.pageNavigation(downldLink).then((response) => {
        const link = document.createElement('a');
        link.href = response.data;
        link.download = 'donation-report';
        link.click();
        this.$refs.dtComponent.exportLoading = false;
      }).catch(err => this.$refs.dtComponent.exportLoading = false);
    },
    moveToChallenge(itemId) {
      const itemIndex =  this.$refs.dtComponent.dtLists.findIndex(item => item.id === itemId);
      this.$refs.dtComponent.dtLists[itemIndex].customLoading = true;
      this.updateDonate({id: itemId, target_report: 1}).then(resp => {
        this.$refs.dtComponent.dtLists.splice(itemIndex, 1);
      }).catch(err => {
        const list = {...this.$refs.dtComponent.dtLists[itemIndex], ...{customLoading: false}};
        this.$refs.dtComponent.dtLists.splice(itemIndex, 1, list);
      })
    }
  }
};
</script>
