<template>
  <v-row id="permission-form-page">
    <v-col cols="12">
        <base-card>
            <v-card-title class="font-weight-bold">{{`${permissionId ? 'Edit' : 'Create'} Permission`}}</v-card-title>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="savePermission">
                    <v-row class="ma-0 pa-0 mb-2">
                        <v-col cols="12 col-lg-6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-text-field ref="name" v-model="model.name" :error-messages="$helpers.errorMsg('name', $v.model.name, 'Name')"
                                label="Name" @input="$v.model.name.$touch()"  @blur="$v.model.name.$touch()" required/>
                        </v-col>
                        <v-col cols="12 col-lg-6" class="ma-0 pt-0 pb-0 pr-0">                        
                            <v-text-field  v-model="model.displayname" label="Display Name"/>
                        </v-col>
                    </v-row>
                    <v-textarea hide-details="auto" class="mb-3"
                        auto-grow
                        rows="4"
                        v-model="model.description"
                        row-height="20"
                        label="Description"
                        outlined
                    ></v-textarea>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="secondary" :disabled="selectPermissionLoading" class="mr-4">
                            Save
                        </v-btn>
                        <v-btn type="button" color="primary" :disabled="selectPermissionLoading" class="mr-4" to="/permissions">
                            Back
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { email, required } from "vuelidate/lib/validators";

export default {
    metaInfo: {
        title: "Permissions",
    },
    data: (vm) => ({
        permissionId: vm.$route.params.permissionId,
        show: false,
        model: {
            name: '',
            displayname: '',
            description: '',
        },
    }),
    validations: {
        model: {
            name: { required},
        }
    },
    computed: {
        ...mapGetters(["getSelectedPermission", "selectPermissionLoading"]),      
    },
    created() {
        if (this.permissionId) {
            this.getPermissionData(this.permissionId);
        }
    },
    methods: {
        ...mapActions(['getPermissionData', 'updatePermissionData', 'createPermission']),

        savePermission() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            if (this.permissionId) {
                this.updatePermission(this.model);
            } else {
                this.createNewPermission(this.model);
            }
        },
        createNewPermission (reqData) {
            const self = this;
            this.createPermission(reqData).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                self.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Permission Created Successfully",
                    color: "success",
                });
                self.$router.push("/permissions");
            }).catch(() => {});
        },
        updatePermission(reqData) {
            const self = this;
            this.updatePermissionData(reqData).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                self.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Permission Updated Successfully",
                    color: "success",
                });
                self.$router.push("/permissions");
            }).catch(() => {});
        }
    },
    watch: {
        'getSelectedPermission': function (newValue) {
            this.model = {...this.model, ...newValue}
        }
    }
};
</script>
