<template>
  <div id="donate-page-theme-page">
    <base-card class="pa-4">
      <v-card-title class="pa-0 pb-3 font-weight-bold">Default Campaign Codes</v-card-title>
      <v-row class="ma-0">
        <v-col cols="12" class="col-lg-6">
          <v-subheader :class="loadingDefaultVal ? '' : 'primary--text'" class="pa-0 ma-0 h-auto mb-1 text-capitalize">SF Campaign (Once)</v-subheader>
          <div class="skeleton-list-item w-full" v-if="campaignListLoading || prodGetOptLoading">
              <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
          </div>
          <v-autocomplete :disabled="loadingDefaultVal" v-model="model.WEB_DONATION_ONCE_DEFAULT" v-if="!campaignListLoading && !prodGetOptLoading"
              hide-details="auto" label="" placeholder="Select Source"
              :items="campaignLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
              outlined
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" class="col-lg-6">
          <v-subheader :class="loadingDefaultVal ? '' : 'primary--text'" class="pa-0 ma-0 h-auto mb-1 text-capitalize">SF Campaign (Monthly)</v-subheader>
          <div class="skeleton-list-item w-full" v-if="campaignListLoading || prodGetOptLoading">
              <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
          </div>
          <v-autocomplete :disabled="loadingDefaultVal" v-model="model.WEB_DONATION_RECURRING_DEFAULT" v-if="!campaignListLoading && !prodGetOptLoading"
              hide-details="auto" label="" placeholder="Select Source"
              :items="campaignLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
              outlined
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </base-card>
    <datatable ref="dtComponent" :modelData="dtData" @deleteItem="deleteThemeItem" @customAction="createClone($event.item)"
      @activeHandled="themeActiveUpdate" activeFieldName="status">
          <template v-slot:buttonSection>
            <v-col class="pa-0 d-flex align-center justify-start">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :loading="updateOptLoading" class="ma-2" v-bind="attrs" v-on="on" dark color="primary" @click="defaultCampaignUpdate">
                      <v-icon>mdi-{{loadingDefaultVal ? 'pencil-box-multiple-outline' : 'content-save-all-outline'}}</v-icon>
                      <span class="ml-1">Default campaign codes</span>
                  </v-btn>
                </template>
                <span>{{loadingDefaultVal ? 'Edit ' : 'Save '}}Default campaign codes </span>
              </v-tooltip>
            </v-col>
          </template>
    </datatable>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "donatePageThemes",
  metaInfo: {
    title: "Donate Page Themes",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
  },
  data() {
    return {
      model: {
        WEB_DONATION_ONCE_DEFAULT: '',
        WEB_DONATION_RECURRING_DEFAULT: '',
      },
      loadingDefaultVal: true,
      updateOptLoading: false,
      dtData: {
        title: 'Donate Page Themes',
        headers: [
            { text: 'Name', align: 'start', value: 'title'},
            { text: "Sf ID", value: "row_id", field: 'sfcampaigns' },
            { text: "Once Amount", value: "once_amount_only" },
            { text: "Recurring Amount", value: "recurring_amount_only" },
            { text: "Active", value: "active", sortable: false},
            {text: 'Action', value: 'action', sortable: false}
        ],
        actions: ['edit', 'delete'],
        activeIcon: 'mdi-star',
        headerActions: ['create', 'reset'],
        customButtonActions:[{
          icon: 'mdi-content-duplicate',
          tooltipName: 'Create Clone',
          btnIcon: true
        }],
        createLink: '/donate-page-themes/create-donate-theme',
        editLink: '/donate-page-themes/edit-donate-theme/',
        pageLink: `${window.VUE_APP_MS_URL}donate/themes?page=1`
      }
    };
  },
  computed: {
    ...mapGetters(["metaInfoDT", "activeLink", "dtLists", "searchDT", "campaignListLoading", "campaignLists",
    'prodGetOptLoading'])
  },
  created() {
     this.getCampaignLists();
     this.getDefaultCampaignVal();
  },
  methods: {
    ...mapActions(["getCampaignLists", "deleteTheme", "createDuplicate", "updateThemeActive", "updateBulkOptData",
      "getOptionsData"]),

    getDefaultCampaignVal() {
      const reqData = {
          name: 'search',
          value: {
            option_name: ['WEB_DONATION_ONCE_DEFAULT', 'WEB_DONATION_RECURRING_DEFAULT'],
          }
      }
      this.getOptionsData(reqData).then((response) => {
        const resp = response.data.data || null;
          if (resp) {
            this.model = resp;
          }
      });
    },
    defaultCampaignUpdate() {
      if (!this.loadingDefaultVal) {
        this.updateOptLoading = true;
        const reqData = [];      
        Object.keys(this.model).forEach(obj => {
          if (this.model[obj]) reqData.push({ option_value: this.model[obj], option_name: obj })
        });
        this.updateBulkOptData(reqData).then(resp => {
          this.updateOptLoading = false;
          this.loadingDefaultVal = true;
        }).catch(err => this.loadingDefaultVal = false);
      } else {
        this.loadingDefaultVal = false;
      }
    },
    deleteThemeItem(item) {
      let pageLink = new URL(this.activeLink);
      if (this.dtLists.length === 1 || !this.activeLink) {
        pageLink = new URL(this.dtData.pageLink);
        if (this.searchDT !== '') {
          pageLink.searchParams.set("q", this.searchDT);
        }
        pageLink.searchParams.set("page", 1);
      }
      this.deleteTheme(item.id).then(() => this.$refs.dtComponent.pageLoading(pageLink));
    },
    createClone(item) {
      this.createDuplicate(item.id).then(() => {
        this.$store.commit("snackbar/SHOW_MESSAGE", {
            text: "Theme Cloned Successfully",
            color: "success",
        });
        this.$refs.dtComponent.pageLoading(this.activeLink);
      });
    },
    themeActiveUpdate(themeId) {
      this.updateThemeActive(themeId).then((res) => {
        let listsDT = this.$refs.dtComponent.dtLists;
        if(listsDT.length) listsDT = listsDT.map(item => {
          if (item.id == themeId) {
            item.status = 1;
            return item;
          }
          item.status = 0;
          return item;
        })
        this.$refs.dtComponent.dtLists = listsDT || [];
      });
    }
  },
};
</script>
