<template>
  <v-row id="roleform-page">
    <v-col cols="12">
        <base-card>
            <v-card-title class="font-weight-bold">{{`${roleId ? 'Edit' : 'Create'} Role`}}</v-card-title>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveRole">
                    <v-row class="ma-0 pa-0 mb-2">
                        <v-col cols="12 col-lg-6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-text-field ref="name" v-model="model.name" :error-messages="$helpers.errorMsg('name', $v.model.name, 'Name')"
                                label="Name" @input="$v.model.name.$touch()"  @blur="$v.model.name.$touch()" required/>
                        </v-col>
                        <v-col cols="12 col-lg-6" class="ma-0 pt-0 pb-0 pr-0">                        
                            <v-text-field  v-model="model.displayname" label="Display Name"/>
                        </v-col>
                    </v-row>
                    <v-textarea hide-details="auto" class="mb-3"
                        auto-grow
                        rows="4"
                        v-model="model.description"
                        row-height="20"
                        label="Description"
                        outlined
                    ></v-textarea>
                    <div class="mt-4 mb-4 d-flex align-center jusify-center">
                        <label for="ip_restriction" class="v-label theme--light mr-4 ">Restrict By IP</label>
                        <v-switch  hide-details="auto" class="pa-0 ma-0"
                            v-model="model.ip_restriction"
                            inset
                            label=""
                        ></v-switch>
                    </div>
                    <v-combobox class="mb-3" ref="permissions" @blur="searchClear('permissions')"
                        v-model="model.permissions"
                        :items="permissionLists"
                        chips
                        label="Permissions"
                        multiple
                        outlined
                    >
                        <template v-slot:prepend-item>
                            <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggle"
                            >
                            <v-list-item-action>
                                <v-icon :color="model.permissions.length > 0 ? 'secondary' : ''">
                                {{ icon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                Select All
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template> 
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index <= 4" close  @click:close="model.permissions.splice(index, 1)">
                            <span class="text-capitalize">{{ item }}</span>
                            </v-chip>
                            <span v-if="index === 5" class="grey--text text-caption">
                                (+{{ model.permissions.length - 5 }} others)
                            </span>
                        </template>              
                    </v-combobox>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="secondary" :disabled="selectRoleLoading" class="mr-4">
                            Save
                        </v-btn>
                        <v-btn type="button" color="primary" :disabled="selectRoleLoading" class="mr-4" to="/roles">
                            Back
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { email, required } from "vuelidate/lib/validators";

export default {
    metaInfo: {
        title: "Roles",
    },
    data: (vm) => ({
        roleId: vm.$route.params.roleId,
        show: false,
        model: {
            name: '',
            displayname: '',
            description: '',
            ip_restriction: '',
            permissions: [],
        },
    }),
    validations: {
        model: {
            name: { required},
        }
    },
    computed: {
        ...mapGetters(["permissionLists", "getSelectedRole", "selectRoleLoading"]),
    
      selectedAllPermissions() {
        return this.model.permissions.length === this.permissionLists.length
      },
      selectSomePermissions() {
        return this.model.permissions.length > 0 && !this.selectedAllPermissions
      },
      icon() {
        if (this.selectedAllPermissions) return 'mdi-close-box'
        if (this.selectSomePermissions) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
    created() {
        if (this.roleId) {
            this.getRoleData(this.roleId);
        }
        this.getPermissionsList();
    },
    methods: {
        ...mapActions(['getPermissionsList', 'getRoleData', 'updateRoleData', 'createRole']),

        toggle() {
            this.$nextTick(() => {
            if (this.selectedAllPermissions) {
                this.model.permissions = []
            } else {
                this.model.permissions = this.permissionLists.slice()
            }
            })
        },
        searchClear(value) {
            if (this.model[value].length === 0) {
                this.$refs[value].lazySearch = '';
            }
        },
        saveRole() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            if (this.roleId) {
                this.updateRole(this.model);
            } else {
                this.createNewRole(this.model);
            }
        },
        createNewRole (reqData) {
            const self = this;
            this.createRole(reqData).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                self.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Role Created Successfully",
                    color: "success",
                });
                self.$router.push("/roles");
            }).catch(() => {});
        },
        updateRole(reqData) {
            const self = this;
            this.updateRoleData(reqData).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                self.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Role Updated Successfully",
                    color: "success",
                });
                self.$router.push("/roles");
            }).catch(() => {});
        }
    },
    watch: {
        'getSelectedRole': function (newValue) {
            this.model = {...this.model, ...newValue}
        }
    }
};
</script>
