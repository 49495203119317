<template>
  <div id="hourly-summary-page">
    <AdvanceFilter :clearAdvFilter="clearAdvFilter" @reset="resetFilter" @search="searchAdvFilter"/>
    <static-datatable ref="dtComponent" :modelData="dtData" @clearFilter="clearAdvFilter=true;searchLink = null" @export="exportDoc"/> 
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "HourlyReport",
  metaInfo: {
    title: "Hourly Report",
  },
  components: {
    StaticDatatable: () => import("@/components/common/static-datatable"),
    AdvanceFilter: () => import("@/pages/reports/hourly-report/components/AdvanceFilter"),
  },
  data() {
    return {
      clearAdvFilter: false,
      dtData: {
        title: 'Hourly Summary Report',
        headers: [
            { text: 'Date', align: 'start', value: 'date'},
            { text: 'Time', align: 'start', value: 'time'},
            { text: "On Air Once Off Count", value: "on_once_cnt" },
            { text: "On Air Once Off Amount ($)", value: "on_once" },
            { text: 'Off Air Once Off Count', value: 'off_once_cnt'},
            { text: 'Off Air Once-Off Amount ($)', value: 'off_once'},
            { text: 'On Air Recurring Count', value: 'on_rec_cnt'},
            { text: 'On Air Recurring Amount ($)', value: 'on_rec'},
            { text: 'Off Air Recurring Count', value: 'off_rec_cnt'},
            { text: 'Off Air Recurring Amount ($)', value: 'off_rec'},
            { text: 'Overall Tally Amount ($)', value: 'overall'},
            { text: 'Inspiration Funds Used', value: 'if_used'},
            { text: 'Inspiration Funds Received', value: 'if_received'},
        ],
        actions: [],
        headerActions: ['reset', 'export'],
        pageLink: `${window.VUE_APP_MS_URL}donate/hourly/report?page=1`
      },
      searchLink: null
    };
  },
  computed: {
    ...mapGetters(["activeLink"]),
  },
  methods: {
    ...mapActions(['pageNavigation']),

    resetFilter() {
        this.searchLink = null;
        this.$refs.dtComponent.pageLoading(this.dtData.pageLink);
    },
    searchAdvFilter(searchModel) {
        const activeUrl = this.activeLink ? this.activeLink : this.dtData.pageLink;
        this.searchLink = this.$helpers.setURLparams(searchModel, activeUrl);
        this.clearAdvFilter = false;
        this.$refs.dtComponent.pageLoading(this.searchLink);
    },
    exportDoc() {
      this.$refs.dtComponent.exportLoading = true;
      const dwnldLink = this.searchLink ? this.searchLink  :  this.dtData.pageLink;
      this.pageNavigation(`${dwnldLink}&export=1`).then((response) => {
        const link = document.createElement('a');
        link.href = response.data;
        link.download = 'hourly-report';
        link.click();
        this.$refs.dtComponent.exportLoading = false;
      }).catch(err => this.$refs.dtComponent.exportLoading = false);
    }
  }
};
</script>
