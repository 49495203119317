import http from '@/http';

export default {
    state: {
        profileUser: {},
        profileLoading: false,
        adminUserLoading: false,
        allAdminUserLists: []
    },
    getters: {
        profileLoading: state => state.profileLoading,
        profileUser: state => state.profileUser,
        adminUserLists: state => state.allAdminUserLists,
        adminUserLoading: state => state.adminUserLoading,
    },
    mutations: {
        setProfileUser(state, data) {
            state.profileUser = data;
            state.profileLoading = false;
        },
        setProfileLoading(state, data) {
            state.profileLoading = data;
        },
        setUserListsLoading(state, data) {
            state.adminUserLoading = data;
        },
        setAllAdminUsers(state, data) {
            state.allAdminUserLists = data;
            state.adminUserLoading = false;
        }
    },
    actions: {
        getAuthUser({ commit }) {
            commit("setProfileLoading", true);
            return new Promise((res, rej) => {
                http('user').get('auth/user').then(response => {
                    commit("setProfileUser", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setProfileLoading", false);
                    rej(error)
                });
            });
        },
        getAdminUserLists({ commit }) {
            commit("setUserListsLoading", true);
            return new Promise((res, rej) => {
                http('user').post('users/lists').then(response => {
                    const resp = response.data.data || null;
                    commit("setAllAdminUsers", resp);
                    res(resp);
                }).catch((error) => {
                    commit("setUserListsLoading", false);
                    rej(error)
                });
            });
        },
    },
};
