import http from '@/http';
import globalHelper from '@/helpers/global.helper';

export default {
    state: {
        lists: [],
        metaDTinfo: {},
        loadingDT: false,
        searchDT: '',
        activeLink: null,
        itemActiveLoading: false
    },
    getters: {
        loadingDT: state => state.loadingDT,
        dtLists: state => state.lists,
        metaInfoDT: state => state.metaDTinfo,
        currentPage: state => state.metaDTinfo?.current_page || 1,
        lastPage: state => state.metaDTinfo?.last_page,
        total: state => state.metaDTinfo?.total,
        activeLink: state => state.activeLink,
        searchDT: state => state.searchDT,
        itemActiveLoading: state => state.itemActiveLoading
    },
    mutations: {
        setDTDetails(state, data) {
            state.lists = data.data || [];
            state.metaDTinfo = data.meta || {};
            state.loadingDT = false;
        },
        setDTlists(state, data) {
            state.lists = data;
            state.loadingDT = false;
        },
        setActiveLink(state, data) {
            state.activeLink = data;
        },
        setDTLoading(state, data) {
            state.loadingDT = data;
        },
        setSearchDT(state, data) {
            state.searchDT = data;
        },
        setEmptyDT(state, data) {
            state.loadingDT = false;
            state.lists = [];
            state.activeLink = null;
            state.metaDTinfo = {};
        },
        setActiveDTItem(state, data) {
            const findIndex = state.lists.findIndex(dtItem => dtItem.id === data);
            const cloneLists = globalHelper.cloneData(state.lists);
            cloneLists.map(item => item.status = 0);
            if (findIndex !== -1) {
              cloneLists[findIndex].status = 1;
              state.lists = cloneLists;
            }
        },
        setItemActiveLoading(state, data) {
            state.itemActiveLoading = data;
        }
    },
    actions: {
        pageNavigation({commit}, pageLink) {
            commit("setDTLoading", true);
            commit("setActiveLink", pageLink);
            return new Promise((res, rej) => {
                http().get(pageLink).then((response) => {
                    commit("setDTDetails", response.data);
                    res(response.data);
                }).catch(error => {
                    commit("setEmptyDT");
                    rej(error);
                })
            });
        },
        getExpandItem({commit}, expandData) {
            return new Promise((res, rej) => {
                http()[expandData.method](expandData.link, expandData.data || {}).then((response) => {
                    res(response.data);
                }).catch(error => {
                    rej(error);
                })
            });
        },
    }
};
