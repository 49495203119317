import http from '@/http';

export default {
    state: {
        rolesData: {},
        selectedRole: {},
        selectRoleLoading: false
    },
    getters: {
        roleLists: state => state.rolesData.data || [],
        getSelectedRole: state => state.selectedRole,
        selectRoleLoading: state => state.selectRoleLoading,
    },
    mutations: {
        setRolesData(state, data) {
            state.rolesData = data;
        },
        setSelectRoleData(state, data) {
            state.selectedRole = data;
            state.selectRoleLoading = false;
        },
        setSelectRoleLoading(state, data) {
            state.selectRoleLoading = data;
        },
    },
    actions: {
        getRolesList({ commit }) {
            commit("setDTLoading", true);
            return new Promise((res, rej) => {
                http('user').get('roles/lists').then(response => {
                    commit("setRolesData", response.data);
                    res(response);
                }).catch(() => {
                    commit("setDTLoading", false);
                    rej(error)
                });
            });
        },
        getRoleData({ commit }, roleId) {
            commit("setSelectRoleLoading", true);
            return new Promise((res, rej) => {
                http('user').get(`roles/${roleId}`).then(response => {
                    commit("setSelectRoleData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectRoleLoading", false);
                    rej(error)
                });
            });
        },
        createRole({ commit }, roleData) {
            commit("setSelectRoleLoading", true);
            return new Promise((res, rej) => {
                http('user').post('roles', roleData).then(response => {
                    commit("setSelectRoleLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setSelectRoleLoading", false);
                    rej(error)
                });
            });
        },
        updateRoleData({ commit }, roleData) {
            commit("setSelectRoleLoading", true);
            return new Promise((res, rej) => {
                http('user').put(`roles/${roleData.id}`, roleData).then(response => {
                    commit("setSelectRoleData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectRoleLoading", false);
                    rej(error)
                });
            });
        },
        deleteRole({ commit }, roleId) {
            return new Promise((res, rej) => {
                http('user').deleteReq(`roles/${roleId}`).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Role Deleted Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        }
    },

};

