<template>
  <v-row id="user-form-page">
    <v-col cols="12">
        <base-card>
            <v-card-title class="font-weight-bold">{{`${appSettingId ? 'Edit' : 'Create'} Setting`}}</v-card-title>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveSetting">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Setting Name</v-subheader>
                            <v-text-field hide-details="auto" v-model="model.option_name" label=""  :disabled="true" outlined/>
                        </v-col>
                        <v-col cols="12">
                            <v-skeleton-loader v-if="selectAppSettingLoading || fileLoading || dropdownLoading"
                                height="100px"
                                type="image"
                            ></v-skeleton-loader>

                            <v-textarea  v-if="!selectAppSettingLoading && !model.field_type && model.option_name !== 'CAMPAIGN_PROGRESS'"  hide-details="auto" class="mb-3"
                                auto-grow
                                rows="4"
                                v-model="model.option_value"
                                row-height="20"
                                label=""
                                outlined
                            ></v-textarea>

                            <EditorTextbox placeholder="Write the value..." v-if="!selectAppSettingLoading && model.field_type == 'editor'" height="500"
                                @updateContent="model.option_value = $event;" :setContent="model.option_value"/>

                            <v-row class="ma-0 pa-0" align="center" v-if="!fileLoading && !selectAppSettingLoading && model.field_type == 'file' && model.option_value">
                                <span class="text-subtitle-1 secondary--text mr-3 font-weight-bold text-uppercase">Preview the uploaded file:</span>
                                <a class="info--text" :href="model.option_value" target="_blank" >{{ model.option_value.substring(model.option_value.lastIndexOf('/')+1) }}</a>
                            </v-row>
                            <v-text-field readonly v-if="!fileLoading && !selectAppSettingLoading && model.field_type == 'file'" outlined label="" placeholder="Upload your file"
                                @click="$refs.file.click()" prepend-icon="mdi-paperclip"></v-text-field>
                            <input type="file" style="display: none" ref="file"  @change="uploadedFile($event)" />
                            <v-row v-if="!selectAppSettingLoading && model.field_type == 'switch'" class="ma-0">
                                <v-subheader class="primary--text pa-0 ma-0 mr-5 h-auto mb-1 text-capitalize">Enabled</v-subheader> 
                                <v-switch v-model="model.option_value" inset label="" :false-value="0" :true-value="1"></v-switch>
                            </v-row>
                            <template v-if="!selectAppSettingLoading && model.option_name === 'CAMPAIGN_PROGRESS'">
                                <v-row class="ma-0">
                                    <v-col cols="12">
                                        <v-subheader class="primary--text pa-0 ma-0 mr-5 h-auto mb-1 text-capitalize">Amount Options</v-subheader>
                                        <v-radio-group v-model="model.option_value.amount" row>
                                            <v-radio v-for="(value, key) in campaignAmtOpt" :label="value" :value="key"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-subheader class="primary--text pa-0 ma-0 mr-5 h-auto mb-1 text-capitalize">Image
                                            <span v-if="model.option_value.user_image" class="text-subtitle-1 secondary--text mx-3 font-weight-bold text-uppercase">Preview the uploaded file:</span>
                                            <a v-if="model.option_value.user_image" class="info--text" :href="model.option_value.user_image" target="_blank" >{{ model.option_value.user_image.substring(model.option_value.user_image.lastIndexOf('/')+1) }}</a>
                                        </v-subheader>
                                        <v-text-field readonly outlined label="" placeholder="Upload your file"
                                            @click="$refs.file.click()" prepend-icon="mdi-paperclip"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-subheader class="primary--text pa-0 ma-0 mr-5 h-auto mb-1 text-capitalize">Progress Color Picker</v-subheader>
                                        <v-color-picker hide-inputs v-model="model.option_value.progress_color"></v-color-picker>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-subheader class="primary--text pa-0 ma-0 mr-5 h-auto mb-1 text-capitalize">Enable Image Border</v-subheader>
                                        <v-switch v-model="model.option_value.circle_corner" inset label="" :false-value="0" :true-value="1"></v-switch>
                                    </v-col>
                                </v-row>
                            </template>
                            <v-autocomplete v-if="!dropdownLoading && model.field_type && model.field_type.includes('sfRecordType')"
                                v-model="model.option_value"
                                hide-details="auto" label="" placeholder="Select..."
                                :items="dropDownLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                                outlined
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="secondary" class="mr-4" :disabled="updateLoading">
                            Save
                        </v-btn>
                        <v-btn type="button" color="primary" class="mr-4" to="/app-settings" :disabled="updateLoading">
                            Back
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { EditorTextbox } from "@/components/common";

export default {
    metaInfo: {
        title: "Edit Setting",
    },
    components: {
        EditorTextbox,
    },
    data: (vm) => ({
        appSettingId: vm.$route.params.appSettingId,
        model: {
            option_value: ''
        },
        updateLoading: false,
        fileLoading: false,
        dropDownLists: [],
        dropdownLoading: false,
        campaignAmtOpt: { 'HIDE': 'Hide', 'PERCENTAGE': 'Percentage', 'DOLLAR': 'Dollar'}
    }),
    computed: {
        ...mapGetters(["selectedAppSettingModel", "selectAppSettingLoading"]),
    },
    created() {
        if (this.appSettingId) {
            this.getSelectAppSettingData(this.appSettingId).then(resp => {
                const data = resp.data.data || null;
                if (data && data.field_type && data.field_type.includes('sfRecordType')) {
                    this.dropdownLoading = true;
                    const reqData = data.field_type.split('.')[1];
                    this.getRecordLists(JSON.parse(reqData));
                }
            });
        }
    },
    methods: {
        ...mapActions(['getSelectAppSettingData', 'updatesOptionsData', 'uploadImages', 'deleteImages',
            'getRecordType']),

        getRecordLists (reqData) {
            this.getRecordType(reqData).then((response) => {
                this.dropdownLoading = false;
                this.dropDownLists = response;
            }).catch(err => this.dropdownLoading = false);
        },
        uploadedFile(evt) {
            const prevFile = this.$helpers.cloneData(this.model.option_name === 'CAMPAIGN_PROGRESS' ? this.model.option_value.user_image || null : this.model.option_value),
                files = evt.target.files[0]
            if (files !== undefined) {
                this.fileLoading = true;
                const reqData = new FormData(),
                    headers =  { 'Content-Type': 'multipart/form-data' };
                reqData.append('file', files);
                this.uploadImages(reqData, headers).then((response) => {
                    if (this.model.option_name === 'CAMPAIGN_PROGRESS') this.model.option_value.user_image = response.data.url;
                    else this.model.option_value = response.data.url;
                    this.fileLoading = false;
                    if(prevFile) this.deleteImages({ file: prevFile });
                }).catch(err => {
                    this.model.option_value = null;
                    this.fileLoading = false
                });
            }
        },
        saveSetting() {
            this.updateLoading = true;
            const reqData = {
                value: this.model.option_value,
                name: this.model.id
            }
            this.updatesOptionsData(reqData).then((response) => {
                if (!response) {
                    return;
                }
                this.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Setting Updated Successfully",
                    color: "success",
                });
                this.updateLoading = false;
                this.$router.push("/app-settings");
            }).catch(() => this.updateLoading = false);
        }
    },
    watch: {
        selectedAppSettingModel: function(newVal) {
            this.model = {...this.model, ...newVal};
            if(this.model.option_name === "CAMPAIGN_PROGRESS" && !this.model.option_value) this.model.option_value = {};
        }
    },
};
</script>
