import Sortable from 'sortablejs';

const sortable = {
    params: ['sortable'],
    bind (el, binding, vnode) {
        Sortable.create(el, {
            group: 'sortablejs',
            animation: 200,
            sort: false,
            onEnd: function (evt) {
                vnode.componentInstance.$emit('sortEmit', {source_name: evt.from.id, target_name: evt.to.id, id: evt.item.id, value: binding.value});
            }
          });
    }
};
  
  export default sortable;
  