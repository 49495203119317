<template>
  <v-card class="px-4">
    <template v-if="model">
        <v-row class="ma-0 pa-0">
            <v-col cols="6" class="d-flex align-center justify-center text-h5 font-weight-bold">
                Opportunity Details
            </v-col>
            <v-col cols="6">
                <v-row class>
                    <v-col cols="4" class="font-weight-bold">
                        Name
                    </v-col>
                    <v-col cols="8" class="text-capitalize">
                        {{ model.source }}: {{ model.receipt_name }} Donation ${{ model.amount }}
                    </v-col>
                    <v-col cols="4" class="font-weight-bold">
                        Frequency
                    </v-col>
                    <v-col cols="8" class="text-capitalize">
                        {{ model.frequency_label }}
                    </v-col>
                    <v-col cols="4" class="font-weight-bold">
                        Next Due Date
                    </v-col>
                    <v-col cols="8" class="text-capitalize">
                        {{ model.dueDate || '-' }}
                    </v-col>
                    <v-col cols="4" class="font-weight-bold">
                        Payment Method
                    </v-col>
                    <v-col cols="8" class="text-capitalize">
                        {{  model.payment_type_label }}
                    </v-col>
                    <v-col cols="4" class="font-weight-bold">
                        Current Balance
                    </v-col>
                    <v-col cols="8">
                        $ {{ model.balance || '0' }}
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-card-actions class="pa-0">
            <v-row class="ma-0 pa-3" align="center" justify="space-between">
                <v-btn color="error" class="text-capitalize" @click="openNotify('Cancel Light Partnership', 'Will cancel the current subscription')">Cancel Light Partnership</v-btn>
                <v-btn color="success" class="text-capitalize" :to="'/catchup-payment/'+sfId">Catchup payment</v-btn>
                <v-btn color="warning" class="text-capitalize" @click="openNotify('clear missed payment', 'will clear all missed payments')">clear missed payment</v-btn>
            </v-row>
        </v-card-actions>
        <v-row align="center" class="ma-0 pa-3">
            <v-item-group v-model="windowSlide" class="mr-6">
                <v-item v-for="(name, index) in actionLists" :key="name" v-slot="{ active, toggle }">
                    <div class="mb-3" @click="enableAction = true">
                        <div  v-if="index === 0" class="d-none" :input-value="active"  @click="toggle"></div>
                        <v-btn :disabled="enableAction" v-if="index !== 0" :color="active ? 'secondary' : 'primary'" :input-value="active" @click="toggle">
                            {{ index == 1 && model.payment_type === 'DD' ? 'Update Bank Details' : name }}
                        </v-btn>
                    </div>
                </v-item>
            </v-item-group>
            <v-col>
                <v-window v-model="windowSlide" class="elevation-1" vertical v-if="windowSlide > 0">
                    <v-window-item v-for="n in (actionLists.length + 1)" :key="n" class="pa-2">
                        <div>
                            <UpdateCardExpiry :modelData="model" v-if=" model.payment_type !== 'DD' && windowSlide === 1" @enableAct="closeAction"/>
                            <UpdateCard  :modelData="model" v-if="windowSlide === (model.payment_type === 'DD' ? 1 : 2)" :stripe="stripe" @enableAct="closeAction"/>
                            <ChangeSchedule :modelData="model" v-if="windowSlide === (model.payment_type === 'DD' ? 2 : 3)"
                                @enableAct="closeAction" @modelUpdate="model[$event.field] = $event.value"/>
                            <UpdateAmount :modelData="model" v-if="windowSlide === (model.payment_type === 'DD' ? 3 : 4)"
                                @enableAct="closeAction" @modelUpdate="model[$event.field] = $event.value"/>
                            <PauseLightPartnership :modelData="model" v-if="windowSlide === (model.payment_type === 'DD' ? 4 : 5)" @enableAct="closeAction"/>
                        </div>
                    </v-window-item>
                </v-window>
            </v-col>
        </v-row>
    </template>
    <template v-else-if="!donateLoading">
        <v-row class="ma-0 text-h5 pa-8 error--text" align="center" justify="center">
          Light Partnership no longer active - Please create a new one
        </v-row>
    </template>
    <notify-modal :loading="notifyLoading" @reasonUpdate="model.reason = $event;" :modalData="modalData" :open="openNotifyModal"
        @save="saveNotify" @close="openNotifyModal = false"/>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: "SFOpportunity",
    metaInfo: {
        title: "SF Opportunity",
    },
    components: {
        UpdateCardExpiry: () => import("@/pages/admin/sf-opportunity/components/UpdateCardExpiry"),
        UpdateCard: () => import("@/pages/admin/sf-opportunity/components/UpdateCard"),
        ChangeSchedule: () => import("@/pages/admin/sf-opportunity/components/ChangeSchedule"),
        UpdateAmount: () => import("@/pages/admin/sf-opportunity/components/UpdateAmount"),
        PauseLightPartnership: () => import("@/pages/admin/sf-opportunity/components/PauseLightPartnership"),
        NotifyModal: () => import("@/pages/admin/sf-opportunity/components/NotifyModal"),
    },    
    data: (vm) => ({        
        sfId: vm.$route.params.sfId,
        actionLists: ['', 'update card expiry', 'update card', 'change schedule', 'update amount', 'pause light partnership'],//'change payment method',
        windowSlide: 0,
        stripe: null,
        enableAction: false,
        openNotifyModal: false,
        modalData: {},
        model: null,
        notifyLoading: false,
        donateLoading: false
    }),
    created() {
        this.donateLoading = true;
        this.donateSearch({ sf_id: this.sfId}).then (resp => {
            const response = resp.data.data ? resp.data.data[0] : null
            if (response) {
                this.model = response;
                this.model.dueDate = this.model.next_due_date ? this.$helpers.getMomentDatas('DD-MM-YYYY', this.model.next_due_date) : null;
                if (this.model.payment_type === 'DD') {
                    this.actionLists.splice(1, 1);
                }
                this.getDonatSubBal(this.model.payment_id).then(resp => this.model.balance = resp.balance);
                if (this.model.stripe_customer_id) this.getStripePaymentMethods(this.model.stripe_customer_id);
            }
            this.donateLoading = false;
        }).catch(err => this.donateLoading = false)
    },
    mounted() {
        this.stripe = window.Stripe(`${window.VUE_APP_STRIPE_PUBLISHABLE_KEY}`);
    },
    methods: {
        ...mapActions(['donateSearch', 'getDonatSubBal', 'donateCancel', 'donateClearMissPay', 'getStripePaymentMethods']),

        openNotify(title, notFound) {
            this.modalData = {
                name: null,
                items: [],
                tableNotFound: null
            };
            if (title === 'Cancel Light Partnership') {
                this.model.reason = 'Lapsed';
                this.modalData.reason = true;
            }
            this.modalData.name = title;
            this.modalData.tableNotFound = notFound;
            this.openNotifyModal = true;
        },
        saveNotify() {
            if (this.modalData.name === 'Cancel Light Partnership') {
                return this.cancelDonate();
            }
            return this.clearPayment();
        },
        closeAction(evt) {
            if(!evt) this.windowSlide = 0;
            this.enableAction = evt;
        },
        cancelDonate() {
            this.notifyLoading = true;
            this.donateCancel({id: this.model.id, lp_reason_for_cancel: this.model.reason}).then(resp => {
                this.notifyLoading = false;
                this.openNotifyModal = false;
            }).catch(err => this.notifyLoading = false);
        },
        clearPayment() {
            this.notifyLoading = true;
            this.donateClearMissPay(this.model.id).then(resp => {
                this.notifyLoading = false;
                this.openNotifyModal = false;
            }).catch(err => this.notifyLoading = false);
        },
    }
}
</script>
