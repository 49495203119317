<template>
    <div id="staff-page" class="pa-3">
        <HeaderCards />
        <ActiveInformations />
        <DetailsCard />
    </div>
</template>
<script>

export default {
  name: "Staff",
  metaInfo: {
    title: "Staff",
  },
  components: {
    HeaderCards: () => import("@/pages/active-campaign/staff/components/header-components"),
    ActiveInformations: () => import("@/pages/active-campaign/staff/components/active-inform-components"),
    DetailsCard: () => import("@/pages/active-campaign/staff/components/content-details-components"),
  }
};
</script>
