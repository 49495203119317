import http from '@/http';
import globalHelper from '@/helpers/global.helper';

export default {
    state: {
        latestTargetLoading: false,
        currentTargetModel: null,
        targetTypeList: [
            {
                text: "Challenge",
                value: "C", 
            }, {
                text: "Unlock Challenge",
                value: "U", 
            }, {
                text: "Dollar for Dollar Match",
                value: "DM", 
            }, {
                text: "General Dollar Amount",
                value: "GD", 
            }, {
                text: "Matching Challenge",
                value: "M", 
            }, {
                text: "Best Gift Challenge",
                value: "G", 
            }
        ],
        targetGraphLoading: false,
        targetGraphData: null
    },
    getters: {
        latestTargetLoading: state => state.latestTargetLoading,
        currentTargetModel: state => state.currentTargetModel,
        targetTypeList: state => state.targetTypeList,
        targetGraphLoading: state => state.targetGraphLoading,
        targetGraphData: state => state.targetGraphData,
    },
    mutations: {
        setLatestTargetLoading(state, data) {
            state.latestTargetLoading = data;
        },
        setCurrentTargetModel(state, data) {
            state.currentTargetModel = data;
            state.latestTargetLoading = false;
        },
        setTargetGraphLoading(state, data) {
            state.targetGraphLoading = data;
        },
        setTargetGraphData(state, data) {
            state.targetGraphData = data;
            state.targetGraphLoading = false;
        }
            
    },
    actions: {
        getLatestTarget({ commit }) {
            commit("setLatestTargetLoading", true);
            return new Promise((res, rej) => {
                http('donate').get('targets/latest?columns=fullTitle').then(response => {
                    const targetData = response.data.data;
                    if (targetData) {
                        targetData.deleted_at =  targetData.deleted_at ?
                            globalHelper.getMomentDatas('hh:mm A', targetData.deleted_at) : null;
                    }
                    commit("setCurrentTargetModel", targetData);
                    commit("setLatestTargetLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setLatestTargetLoading", false);
                    commit("setCurrentTargetModel", null);
                    rej(error)
                });
            });
        },
        getCurrTargetGraph({ commit }, targetId) {
            commit("setTargetGraphLoading", true);
            return new Promise((res, rej) => {
                http('donate').get(`targets/${targetId}/chart`).then(response => {
                    commit("setTargetGraphData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setTargetGraphLoading", false);
                    rej(error)
                });
            });
        },
        createTarget({ commit }, targetData) {
            commit("setModalLoading", true);
            return new Promise((res, rej) => {
                http('donate').post('targets', targetData).then(response => {
                    commit("setModalLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setModalLoading", false);
                    rej(error)
                });
            });
        },
        updateTarget({ commit }, targetData) {
            commit("setModalLoading", true);
            return new Promise((res, rej) => {
                http('donate').put(`targets/${targetData.id}`, targetData).then(response => {
                    commit("setModalLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setModalLoading", false);
                    rej(error)
                });
            });
        },
        deleteTarget({ commit }, targetId) {
            return new Promise((res, rej) => {
                http('donate').deleteReq(`targets/${targetId}`).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Challenge Ended Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        }
    },

};
