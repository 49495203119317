export default [
    {
        title: "Contact Centre",
        to: "",
        route: 'Home',
        access: 'home',
        icon: "mdi-account-group",
    }, {
        title: "LP Entry",
        to: "lp-entry",
        route: 'LP Entry',
        access: 'lp_entry',
        icon: "mdi-handshake",
    }, {
        title: "Update Contact",
        to: "update-contact",
        route: 'Update Contact',
        access: 'update_contact',
        icon: "mdi-card-account-mail",
    }, {
        title: "Active Campaign",
        icon: "mdi-human-greeting-proximity",
        group: "active-campaign",
        children: [
            {
                title: "Producer",
                to: "producer",
                route: 'Producer',
                access: 'producer',
                icon: "mdi-cast-connected",
            }, {
                title: "Tally",
                to: "tally",
                route: 'Tally',
                access: 'tally',
                icon: "mdi-tally-mark-5",
            }, {
                title: "On Air",
                to: "onair",
                route: 'On Air',
                access: 'on_air',
                icon: "mdi-radio",
            }, {
                title: "Sound Effect",
                to: "sound-effect",
                route: 'Sound Effect',
                access: 'on_air',
                icon: "mdi-remote",
            }, {
                title: "Staff",
                to: "staff",
                route: 'Staff',
                access: 'staff',
                icon: "mdi-account-tie-voice",
            },
        ]
    }, {
        title: "Reports",
        icon: "mdi-chart-box",
        group: "reports",
        children: [
            {
                title: "Donations",
                to: "donations",
                route: 'Donations',
                access: 'donation',
                icon: "mdi-charity",
            }, {
                title: "Hourly Summary",
                to: "hourly-summary",
                route: 'Hourly Summary',
                access: 'donation',
                icon: "mdi-clipboard-flow",
            }, {
                title: "Hourly Summary (Last 3hrs)",
                to: "last-3hours-summary",
                route: 'Hourly Summary(Last 3hrs)',
                access: 'donation',
                icon: "mdi-clipboard-text-clock",
            }, {
                title: "Hourly Summary (Table)",
                to: "hourly-report",
                route: 'Hourly Report',
                access: 'donation',
                icon: "mdi-clipboard-file",
            }, {
                title: "Challenges",
                to: "challenges",
                route: 'Challenges',
                access: 'challenge',
                icon: "mdi-timer-cog",
            }, {
                title: "Stories",
                to: "stories",
                route: 'Stories',
                access: 'web_form_stories',
                icon: "mdi-book-open-page-variant",
            }, {
                title: "Audits",
                to: "audits",
                route: 'Audits',
                access: 'audits',
                icon: "mdi-receipt-text-check-outline",
            }, {
                title: "Payouts",
                to: "payouts",
                route: 'Payouts',
                access: 'payouts',
                icon: "mdi-cash-fast",
            }, {
                title: "Sync Failed Contacts",
                to: "sync-failed-contacts",
                route: 'Sync Failed Contacts',
                access: 'donation',
                icon: "mdi-close-network",
            }, {
                title: "Sync Failed Transactions",
                to: "sync-failed-txns",
                route: 'Sync Failed Txns',
                access: 'donation',
                icon: "mdi-credit-card-sync-outline",
            }, {
                title: "Sync Failed Donations",
                to: "sync-failed-donations",
                route: 'Sync Failed Donations',
                access: 'donation',
                icon: "mdi-sync-alert",
            }
        ]
    }, {
        title: "Access",
        icon: "mdi-table-cog",
        group: "access",
        children: [
            {
                title: "Users",
                to: "users",
                route: ['Users', 'Create User', 'Edit User'],
                access: 'user',
                icon: "mdi-account-network",
            }, {
                title: "Roles",
                to: "roles",
                route: ['Roles', 'Create Role', 'Edit Role'],
                access: 'role',
                icon: "mdi-account-cog",
            }, {
                title: "Permissions",
                to: "permissions",
                access: 'permission',
                route: ['Permissions', 'Create Permission', 'Edit Permission'],
                icon: "mdi-script-text-key",
            }
        ]
    }, {
        title: "Settings",
        icon: "mdi-cogs",
        group: "settings",
        children: [
            {
                title: "Incentives",
                to: "incentives",
                route: ['Incentives', 'Create Incentive', 'Edit Incentive'],
                access: 'gift',
                icon: "mdi-wallet-giftcard",
            }, {
                title: "Campaigns",
                to: "campaigns",
                route: ['Campaigns', 'Create Campaign', 'Edit Campaign'],
                access: 'campaign',
                icon: "mdi-home-group",
            }, {
                title: "Donate Page Themes",
                to: "donate-page-themes",
                route: ['Donate Page Themes', 'Create Donate Page Theme', 'Edit Donate Page Theme'],
                access: 'sf_campaign',
                icon: "mdi-web-refresh",
            }, {
                title: "Membership Page Theme",
                to: "membership-page-theme",
                route: 'Membership Page Theme',
                access: 'sf_campaign',
                icon: "mdi-account-credit-card-outline",
            }, {
                title: "Application Settings",
                to: "app-settings",
                route: ['App Settings', 'Edit Setting'],
                access: 'donate_settings',
                icon: "mdi-application-cog-outline",
            },
        ]
    }, {
        title: "Info",
        to: "info",
        route: 'Info',
        access: '',
        icon: "mdi-information-outline",
    },
];
