import http from '@/http';

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    updateStory({ commit }, storiesData) {
      commit("setItemActiveLoading", true);
      return new Promise((res, rej) => {
        http('donate').put(`stories/${storiesData.id}`, storiesData).then(response => {
          res(response);
          commit("setItemActiveLoading", false);

        }).catch((error) => {
          commit("setItemActiveLoading", false);
          rej(error)
        });
      });
    },
    clearFavouriteStory({ commit }) {
      commit("setItemActiveLoading", true);
      return new Promise((res, rej) => {
        http('donate').put(`stories/clear`, {}).then(response => {
          res(response);
          commit("setItemActiveLoading", false);
        }).catch((error) => {
          commit("setItemActiveLoading", true);
          rej(error)
        });
      });
    },

  },

};
