<template>
  <div id="audits-page">
    <AdvanceFilter :clearAdvFilter="clearAdvFilter" @reset="resetFilter" @search="searchAdvFilter"/>
    <datatable ref="dtComponent" :modelData="dtData" @clearFilter="clearAdvFilter=true;searchLink = searchData = null"
        :searchModel="searchData" @customAction="viewLogsModal($event.item)"/>
    <ViewLogModal v-if="openViewModal" :item="selectedItem" :modalOpen="openViewModal" @closeModal="openViewModal=false"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "Audits",
  metaInfo: {
    title: "Audits",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
    AdvanceFilter: () => import("@/pages/reports/audits/components/AdvanceFilter"),
    ViewLogModal: () => import("@/pages/reports/audits/components/ViewLogModal"),
  },
  data() {
    return {
      openViewModal: false,
      selectedItem: {},
      clearAdvFilter: false,
      dtData: {
        title: 'Audits',
        headers: [
            { text: 'Id', align: 'start', value: 'id'},
            { text: "Table", value: "auditable_type" },
            { text: "User", value: "user_name" },
            { text: "Event", value: "event" },
            { text: "Created At", value: 'datefield', field: 'created_at' },
            {text: 'Action', value: 'action', sortable: false}
        ],
        actions: [],
        customButtonActions:[{
          icon: 'mdi-file-eye-outline',
          tooltipName: 'View Log',
          btnIcon: true
        }],
        headerActions: ['reset'],
        pageLink: `${window.VUE_APP_MS_URL}donate/audits?page=1`
      },
      searchLink: null,
      searchData: null
    };
  },
  computed: {
    ...mapGetters(["activeLink"]),
  },
  methods: {
    resetFilter() {
        this.searchLink = this.searchData = null;
        this.$refs.dtComponent.searchDT = '';
        this.$refs.dtComponent.pageLoading(this.dtData.pageLink, true);
    },
    searchAdvFilter(searchModel) {
        this.searchLink = this.$helpers.setURLparams(searchModel, this.dtData.pageLink);
        this.clearAdvFilter = false;
        this.searchData = searchModel;
        this.$refs.dtComponent.searchDT = '';
        this.$refs.dtComponent.pageLoading(this.searchLink, true);
    },
    viewLogsModal(item) {
      this.selectedItem = item;
      this.openViewModal = true;
    }
  }
};
</script>
