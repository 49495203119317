import { default as login }  from './login';
import { default as resetPassword }  from './forgot-password';
import { default as user }  from './user';


export default {
  modules: {
    login,
    resetPassword,
    user
  },
  state: {
    cancelTokens: []
  },
  getters: {
    cancelTokens: state => state.cancelTokens,
  },
  mutations: {
    addCancelToken(state, token) {
      state.cancelTokens.push(token);
    },
    clearCancelToken(state) {
      state.cancelTokens = [];
    }
  },
  actions: {
    cancelPendingRequests({ commit , state}) {
      if (state.cancelTokens.length) {
        state.cancelTokens[state.cancelTokens.length - 1].cancel();
      }
      // Reset the cancelTokens store
      commit('clearCancelToken');
    },
    cancelAllPendingRequests({ commit , state}) {
      if (state.cancelTokens.length) {
        state.cancelTokens.forEach((request, i) => {
          if(request.cancel){
            request.cancel();
          }
        });
      }
      commit('clearCancelToken');
    }
  }
}