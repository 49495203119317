const filters = {
    titleCase (str) {
        const txt = str.trim();
        return txt ? txt.split(' ').map(function(word) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(' ') : '';
    },
    currencyComma (value) {
        if(value == '-') {
            return value;
        }

        if(!value || typeof value == 'undefined'){
            return 0;
        }

        return Intl.NumberFormat('en-AU').format(value);
    },
}

export default filters;