<template>
    <datatable ref="dtComponent" :modelData="dtData" @deleteItem="deleteRoleItem"/>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Roles",
  metaInfo: {
    title: "Roles",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
  },
  data() {
    return {
      dtData: {
        title: 'Roles',
        headers: [
            { text: 'Name', align: 'start', value: 'name'},
            { text: "Permissions", value: "chip", field: 'permissions' },
            {text: 'Action', value: 'action', sortable: false}
        ],
        actions: ['edit', 'delete'],
        deleteActionHide: { name: 'name', value: 'Super Admin'},
        headerActions: ['reset', 'create'],
        createLink: '/roles/create-role',
        editLink: '/roles/edit-role/',
        pageLink: `${window.VUE_APP_MS_URL}user/roles?page=1`
      }
    };
  },
  computed: {
    ...mapGetters(["metaInfoDT", "activeLink", "dtLists", "searchDT"])
  },
  methods: {
    ...mapActions(["getRoles", "deleteRole"]),

    deleteRoleItem(item) {
      let pageLink = new URL(this.activeLink);
      if (this.dtLists.length === 1|| !this.activeLink) {
        pageLink = new URL(this.dtData.pageLink);
        if (this.searchDT !== '') {
          pageLink.searchParams.set("q", this.searchDT);
        }
        pageLink.searchParams.set("page", 1);
      }
      this.deleteRole(item.id).then(() =>  this.$refs.dtComponent.pageLoading(pageLink));
    }
  },
};
</script>
