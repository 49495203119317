<template>
    <div id="dynamic-table-page">
        <v-simple-table class="rounded-xl">
           <thead class="primary">
                <tr>
                    <th class="white--text text-subtitle-1 text-center">S.no</th>
                    <th class="white--text text-subtitle-1 text-center">Amount</th>
                    <th class="white--text text-subtitle-1 text-center">Tag</th>
                    <th class="white--text text-subtitle-1 text-center">Default</th>
                    <th class="white--text text-subtitle-1 text-center">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in model" :key="i" class="row-custom-fields">
                    <td class="px-0 text-center border-left" :class="{'border-bottom': (model.length-1) === i}">
                        {{ i+1 }}
                    </td>
                    <td class="px-0" :class="{'border-bottom': (model.length-1) === i}">
                        <v-text-field class="box-shadow-none rounded-0 input-align-center" type="number" v-model.number="item.amount" solo hide-details="auto"
                            @focus="$helpers.avoidInputScrollable($event)" placeholder="Amount" @keydown="$helpers.avoidInputArrowKeyPress"/>
                    </td>
                    <td class="px-0" :class="{'border-bottom': (model.length-1) === i}">
                        <v-text-field class="box-shadow-none rounded-0 input-align-center" v-model.trim="item.tag" solo hide-details="auto" placeholder="Tag"/>
                    </td>
                    <td :class="{'border-bottom': (model.length-1) === i}" class="d-flex justify-center">
                        <v-checkbox  hide-details="auto" v-model="item.default" dense color="primary"
                            label="" :false-value="0" :true-value="1" @change="checkBoxChange($event,i)"></v-checkbox>
                    </td>
                    <td class="border-right" :class="{'border-bottom': (model.length-1) === i}">
                        <div class="d-flex justify-center">
                            <v-btn v-if="model.length !== 1" icon text color="secondary" @click="$emit('deleteRow', i)">
                                <v-icon dark>mdi-delete-circle</v-icon>
                            </v-btn>
                        </div>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>
<script>
export default {
  name: "DynamicTable",
  props: ["model", "checkBxOne"],
  emits: ["modelUpdate", "deleteRow"],
  methods: {
    checkBoxChange(evt, i) {
        if (evt === 1 && this.checkBxOne) {
            this.model.map((item, index) => {
                if (index != i)item.default = 0;
                return item;
            });
        }
    }
  }
};
</script>
<style scoped lang="scss">
.row-custom-fields{
    .v-input--is-focused {
        border: 1px solid var(--v-primary-base) !important;
    }
    background: transparent !important;
    .border-left {
        border-left: 1px solid var(--v-primary-base) !important;
    }
    .border-right {
        border-right: 1px solid var(--v-primary-base) !important;
    }
    .border-bottom {
        border-bottom: 1px solid var(--v-primary-base) !important;
    }
}
</style>
