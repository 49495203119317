<template>
  <v-row id="campaignform-page">
    <v-col cols="12">
        <base-card>
            <v-card-title class="font-weight-bold">{{`${campaignId ? 'Edit' : 'Create'} Campaign`}}</v-card-title>
            <v-card-text class="pa-8 pt-0">
                <v-form lazy-validation @submit.prevent="savCampaign">
                    <v-row align="center" justify="center">
                        <v-col cols="6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">SF Campaign (Once)</v-subheader>
                            <v-autocomplete @change="getHourlyCampaignLists()"
                                ref="sf_campaign_once"
                                :error-messages="$helpers.errorMsg('name', $v.model.sf_campaign_once, 'SF Campaign (Once)')"
                                v-model="model.sf_campaign_once"
                                hide-details="auto" label="" placeholder="Select Campaign (Once)"
                                :items="campaignLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                                outlined
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">SF Campaign (Monthly)</v-subheader>
                            <v-autocomplete @change="getHourlyCampaignLists()"
                                ref="sf_campaign_monthly"
                                :error-messages="$helpers.errorMsg('name', $v.model.sf_campaign_monthly, 'SF Campaign (Monthly)')"
                                v-model="model.sf_campaign_monthly"
                                hide-details="auto" label="" placeholder="Select Campaign (Once)"
                                :items="campaignLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                                outlined
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Start Date</v-subheader>
                            <v-menu v-model="startmenu" transition="scale-transition" min-width="250"
                                :close-on-content-click="false" offset-y >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field hide-details="auto"
                                        :value="startDate"
                                        placeholder="Start Date"
                                        readonly
                                        label=''
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                    ></v-text-field>
                                </template>
                                <div class="range-calendar">
                                    <vc-date-picker :min-date="minDate" mode="dateTime" class="date-picker-range" v-model="model.start" :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }"
                                        timezone="Australia/Melbourne" color="indigo" :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }" is-required
                                        @input="model.end = $helpers.addHoursnDDateJs('date', 1,  model.start)"/>
                                    <v-btn class="ma-2"  dark color="primary" @click="startmenu=false;">
                                        Ok
                                    </v-btn>
                                </div>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">End Date</v-subheader>
                            <v-menu v-model="endmenu" transition="scale-transition" min-width="250"
                                :close-on-content-click="false" offset-y >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field hide-details="auto"
                                        :value="endDate"
                                        placeholder="End Date"
                                        readonly
                                        label=''
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                    ></v-text-field>
                                </template>
                                <div class="range-calendar">
                                    <vc-date-picker :min-date="model.start" mode="dateTime" class="date-picker-range" v-model="model.end" :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }"
                                        timezone="Australia/Melbourne" color="indigo" :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }" is-required/>
                                    <v-btn class="ma-2"  dark color="primary" @click="endmenu=false;">
                                        Ok
                                    </v-btn>
                                </div>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Slogan</v-subheader>
                            <v-text-field v-model="model.slogan" outlined label="" hide-details="auto" placeholder="Slogan"/>
                        </v-col>
                        <v-col cols="6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Target</v-subheader>
                            <v-text-field type="number" min="0" v-model.number="model.target" outlined label="" hide-details="auto" placeholder="Target"/>
                        </v-col>
                        <v-col cols="12">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Is Active</v-subheader>
                            <v-row class="ma-0 pa-0">
                                <v-switch :false-value="0" :true-value="1" v-model="model.is_active" hide-details="auto" class="pt-0 pr-5"
                                    inset :label="`${model.is_active == '1' ? 'Yes' : 'No'}`"
                                ></v-switch>
                            </v-row>
                        </v-col>
                        <v-col cols="12" class="campaign-sortable-skeleton">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Campaign Hourly Report</v-subheader>
                            <v-row class="ma-0 pa-0">
                                <v-col cols="6" class="pa-0 pr-3 pt-2">
                                    <v-card :loading="sortLoading.campaignsRelatedLists" style="height: 700px" class="border-primary" outlined>
                                        <vue-perfect-scrollbar  v-if="!sortLoading.campaignsRelatedLists" v-sortable="campaignsRelatedLists" @sortEmit="sortableUpdate"
                                            id="campaignsRelatedLists" style="height: 100%" class="scroll-area" :settings="{'maxScrollbarLength': 940}">
                                                <v-alert :id="item.value" class="pa-0 ma-3 pa-3 cursor-pointer" dense text v-for="(item, i) in campaignsRelatedLists" :key="i">
                                                {{ item.text }}
                                                </v-alert>
                                        </vue-perfect-scrollbar>
                                        <v-skeleton-loader  v-if="sortLoading.campaignsRelatedLists"
                                            v-bind="{class: 'list-line'}"
                                            type="list-item,list-item,list-item,list-item"
                                        ></v-skeleton-loader>
                                    </v-card>
                                </v-col>
                                <v-col cols="6" class="pa-0 pt-2">
                                    <v-card class="mb-5 border-primary" outlined :loading="sortLoading.ON" style="height: 235px">
                                        <v-card-title class="secondary--text font-weight-bold justify-center pa-1">On Air</v-card-title>
                                        <vue-perfect-scrollbar v-if="!sortLoading.ON" id="ON" v-sortable="campaignHourlyLists.ON" @sortEmit="sortableUpdate"
                                            style="height: 193px" class="target-element scroll-area" :settings="{'maxScrollbarLength': 260}">
                                                <v-alert dismissible :id="item.value" class="pa-0 ma-3 pa-3 cursor-pointer" dense text v-for="(item, i) in campaignHourlyLists.ON" :key="item.value" @input="removeHourlyList('ON', i)">
                                                {{ item.text }}
                                                </v-alert>
                                        </vue-perfect-scrollbar>
                                        <v-skeleton-loader v-if="sortLoading.ON"
                                            v-bind="{class: 'list-line'}"
                                            type="list-item,list-item,list-item,list-item"
                                        ></v-skeleton-loader>
                                    </v-card>
                                    <v-card class="mb-5 border-primary" outlined :loading="sortLoading.OFF" style="height: 235px">
                                        <v-card-title class="secondary--text font-weight-bold justify-center pa-1">Off Air</v-card-title>
                                        <vue-perfect-scrollbar v-if="!sortLoading.OFF" id="OFF" v-sortable="campaignHourlyLists.OFF" @sortEmit="sortableUpdate" style="height: 193px" class="target-element scroll-area" :settings="{'maxScrollbarLength': 260}">
                                            <v-alert dismissible :id="item.value" class="pa-0 ma-3 pa-3 cursor-pointer" dense text v-for="(item, i) in campaignHourlyLists.OFF" :key="item.value" @input="removeHourlyList('OFF', i)">
                                            {{ item.text }}
                                            </v-alert>
                                        </vue-perfect-scrollbar>
                                        <v-skeleton-loader v-if="sortLoading.OFF"
                                            v-bind="{class: 'list-line'}"
                                            type="list-item,list-item,list-item,list-item"
                                        ></v-skeleton-loader>
                                    </v-card>
                                    <v-card class="target-element border-primary" outlined :loading="sortLoading.MAJOR" style="height: 235px">
                                        <v-card-title class="secondary--text font-weight-bold justify-center pa-1">Major Donors</v-card-title>
                                        <vue-perfect-scrollbar v-if="!sortLoading.MAJOR" id="MAJOR" v-sortable="campaignHourlyLists.MAJOR" @sortEmit="sortableUpdate" style="height: 193px" class="starget-element croll-area" :settings="{'maxScrollbarLength': 260}">
                                            <v-alert dismissible :id="item.value" class="pa-0 ma-3 pa-3 cursor-pointer" dense text v-for="(item, i) in campaignHourlyLists.MAJOR" :key="item.value" @input="removeHourlyList('MAJOR', i)">
                                            {{ item.text }}
                                            </v-alert>
                                        </vue-perfect-scrollbar>
                                        <v-skeleton-loader v-if="sortLoading.OFF"
                                            v-bind="{class: 'list-line'}"
                                            type="list-item,list-item,list-item,list-item"
                                        ></v-skeleton-loader>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class="my-2 mb-5"/>
                    <v-row justify="end">
                        <v-btn type="submit" color="secondary" class="font-weight-bold mr-4" :disabled="selectCampaignLoading">
                            Save
                        </v-btn>
                        <v-btn type="button" color="primary" :disabled="selectCampaignLoading" class="font-weight-bold mr-4" to="/campaigns">
                            <v-icon left dark>mdi-chevron-left</v-icon> Back
                        </v-btn>
                    </v-row>
                </v-form>
            </v-card-text>
        </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import _ from 'lodash';

import { Sortable } from "@/directives";

export default {
    metaInfo: {
        title: "Campaign",
    },
    directives: {
      'sortable': Sortable,
    },
    data: (vm) => ({
        minDate: vm.$helpers.getJsDateBeginEnd('start'),
        campaignId: vm.$route.params.campaignId,
        startmenu: false,
        endmenu: false,
        campaignHourlyLists: {
            ON: [],
            OFF: [],
            MAJOR: []
        },
        campaignsRelatedLists: [],
        sortLoading: {
            campaignsRelatedLists: false,
            ON: false,
            OFF: false,
            MAJOR: false
        },
        model: {
            sf_campaign_once: '',
            sf_campaign_monthly: '',
            start: null,
            end: null,
            target: null,
            slogan: '',
            hourly_campaigns: {}
        },
        copyModel: {},
    }),
    validations: {
        model: {
            sf_campaign_once: { required},
            sf_campaign_monthly: { required},
        }
    },
    computed: {
        ...mapGetters(["campaignLists", "getSelectedCampaign", "selectCampaignLoading",
            "campaignListLoading", "campaignCombineLists"]),

        startDate() {
            return this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.model.start);
        },
        endDate() {
            return this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.model.end);
        }
    },
    created() {
        if (this.campaignId) {
            this.getCampaignData(this.campaignId);
        }
        this.getCampaignLists();
    },
    mounted() {
        this.model.start = this.$helpers.getJsDateBeginEnd('start');
        this.model.end = this.$helpers.getJsDateBeginEnd();
    },
    methods: {
        ...mapActions(['getCampaignLists', 'getCampaignData', 'updateCampaignData', 'createCampaign']),

        savCampaign() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            if (this.campaignId) {
                this.updateCampaign();
            } else {
                this.createNewCampaign();
            }
        },
        createNewCampaign () {
            const self = this;
            this.model.hourly_campaigns = this.getIDHourlyValue(this.campaignHourlyLists);
            this.createCampaign(this.model).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                self.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Campaign Created Successfully",
                    color: "success",
                });
                self.$router.push("/campaigns");
            }).catch(() => {});
        },
        updateCampaign() {
            const self = this;
            this.model.hourly_campaigns = this.getIDHourlyValue(this.campaignHourlyLists);
            const reqData = this.$helpers.getChangedObjVal(this.copyModel, this.model, 'id');
            this.updateCampaignData(reqData).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                self.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Campaign Updated Successfully",
                    color: "success",
                });
                self.$router.push("/campaigns");
            }).catch(() => {});
        },
        searchClear(value) {
            if (this.model[value].length === 0) {
                this.$refs[value].lazySearch = '';
            }
        },
        getHourlyCampaignLists(type) {
            if (this.model.sf_campaign_once || this.model.sf_campaign_monthly) {
                const combineCampaign = [];
                if (this.model.sf_campaign_once) combineCampaign.push(this.model.sf_campaign_once);
                if (this.model.sf_campaign_monthly) combineCampaign.push(this.model.sf_campaign_monthly);
                this.getCampaignLists({top_level_campaign_id__c: combineCampaign}).then(() => {
                    if (type && !_.isEmpty(this.model.hourly_campaigns)) {
                        this.campaignHourlyLists = this.getIDHourlyValue(this.model.hourly_campaigns, 'item');
                        const ObjValue = Object.values(this.model.hourly_campaigns),
                            idValue = ObjValue.reduce((acc, val) => acc.concat(val), []),
                            combineId = this.campaignCombineLists.map(item => item.value),
                            existingItems = combineId.filter(item => idValue.every(idItem => idItem !== item));
                        if (combineId.every(item => item !== this.model.sf_campaign_once) && idValue.every(item => item !== this.model.sf_campaign_once)) { 
                            existingItems.push(this.model.sf_campaign_once);
                        }
                        if (combineId.every(item => item !== this.model.sf_campaign_monthly) && idValue.every(item => item !== this.model.sf_campaign_monthly)) { 
                            existingItems.push(this.model.sf_campaign_monthly);
                        }
                        this.campaignsRelatedLists = this.campaignLists.filter(item => existingItems.indexOf(item.value) !== -1)
                    }
                    Object.keys(this.sortLoading).forEach(obj => this.sortLoading[obj] = false);
                }).catch((err) => Object.keys(this.sortLoading).forEach(obj => this.sortLoading[obj] = false));
            }
        },
        getIDHourlyValue(listObj, type='value', totalList=[]) {
            let idValue = {
                ON: [],
                OFF: [],
                MAJOR: []
            }
            if (listObj?.ON?.length > 0 || listObj?.OFF?.length > 0 || listObj?.MAJOR?.length > 0) {
                 Object.keys(listObj).forEach(obj => {
                    if (type === 'value') {
                        idValue[obj] = listObj[obj].map(item => item.value);
                    } else {
                        const arrayVal = [];
                        this.campaignLists.map(item => {
                            if (listObj[obj].indexOf(item.value) !== -1) arrayVal.push(item);
                        });
                        idValue[obj] = arrayVal;
                    }
                });
                return idValue;
            }
            return idValue;
        },
        sortableUpdate(data) {
            this.sortLoading[data.target_name] = this.sortLoading[data.source_name] = true;
            const self = this;
            setTimeout(() => {
                let sourceValue = {};
                let oldIndex = 0;
                if (data.source_name === 'campaignsRelatedLists') {
                    oldIndex = this.campaignsRelatedLists.findIndex(item => item.value === data.id);
                    sourceValue = this.campaignsRelatedLists[oldIndex];
                    self.campaignsRelatedLists = self.campaignsRelatedLists.filter(item =>  item && item.value !== sourceValue.value);
                } else {
                    oldIndex = this.campaignHourlyLists[data.source_name].findIndex(item => item.value === data.id);
                    sourceValue = this.campaignHourlyLists[data.source_name][oldIndex];
                    self.campaignHourlyLists[data.source_name] = self.campaignHourlyLists[data.source_name].filter(item =>  item && item.value !== sourceValue.value);
                };
                if (data.target_name === 'campaignsRelatedLists') self.campaignsRelatedLists.unshift(sourceValue);
                else self.campaignHourlyLists[data.target_name].unshift(sourceValue);
                self.sortLoading[data.target_name] = self.sortLoading[data.source_name] = false;
            }, 300);
        },
        removeHourlyList(name, index) {
            this.sortLoading[name] = this.sortLoading['campaignsRelatedLists'] = true;
            const self = this;
            setTimeout(() => {
                self.campaignsRelatedLists.unshift(self.campaignHourlyLists[name][index]);
                self.campaignHourlyLists[name].splice(index, 1);
                self.sortLoading[name] = self.sortLoading['campaignsRelatedLists'] = false;
            }, 300);
        },
        setSelectedCampOnair() {
            if (this.campaignHourlyLists.ON.every(item => item.value !== this.model.sf_campaign_once) 
                && this.campaignHourlyLists.OFF.every(item => item.value !== this.model.sf_campaign_once)
                && this.campaignHourlyLists.MAJOR.every(item => item.value !== this.model.sf_campaign_once)) {
                    this.campaignHourlyLists.ON.push(this.campaignLists.find(item => item.value === this.model.sf_campaign_once))   
            }
            if (this.campaignHourlyLists.ON.every(item => item.value !== this.model.sf_campaign_monthly) 
                && this.campaignHourlyLists.OFF.every(item => item.value !== this.model.sf_campaign_monthly)
                && this.campaignHourlyLists.MAJOR.every(item => item.value !== this.model.sf_campaign_monthly)) {
                    this.campaignHourlyLists.ON.push(this.campaignLists.find(item => item.value === this.model.sf_campaign_monthly))   
            }
        }
    },
    watch: {
        getSelectedCampaign: function (newValue) {
            this.model = {...this.model, ...newValue};
            this.copyModel = this.$helpers.cloneData(this.model);
            this.minDate = this.$helpers.getJsDateBeginEnd('start', this.copyModel.start);
            Object.keys(this.sortLoading).forEach(obj => this.sortLoading[obj] = true);
            this.getHourlyCampaignLists('update');
        },
        campaignCombineLists: function (newValue) {
            this.campaignsRelatedLists = newValue.filter(item => !item.fc_pipeline__c);
            this.campaignHourlyLists = {
                ON: newValue.filter(item => item.fc_pipeline__c && item.fc_pipeline__c === 'On Air' ),
                OFF: newValue.filter(item => item.fc_pipeline__c && item.fc_pipeline__c === 'Off Air'),
                MAJOR: newValue.filter(item => item.fc_pipeline__c && item.fc_pipeline__c === 'Major Donor')
            };
            this.setSelectedCampOnair();
        }
    }
};
</script>
