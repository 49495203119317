import Vue from "vue";
import Router from "vue-router";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import globalHelper from "@/helpers/global.helper";

import { Users, UserForm, Roles, RoleForm, Permissions, PermissionForm } from "@/pages/access";
import { Donations, Last3HoursSummary, HourlySummary, HourlyReport, Challenges, Stories, Audits,
  Payouts, SyncFailedTxns, SyncFailedDonations, SyncFailedContacts } from "@/pages/reports";
import { Producer, Tally, OnAir, SoundEffect, OnAirStaff } from "@/pages/active-campaign";
import { Incentives, IncentiveForm, Campaigns, CampaignForm, DonatePageThemes, DonatePageThemeForm, MembershipPageTheme,
  AppSettings, AppSettingForm } from "@/pages/settings";
import { UpdateProfile, SfOpportunity, sfRefundTxn } from "@/pages/admin";
import { PageNotFound, AccessDenied } from "@/pages/error";
import { SecureStripNotify } from "../components/common";

Vue.use(Router);

const checkAccessURLs = (to, next, redirectRoute=null) => {
  const currRoute = globalHelper.getCurrRouteDetails(to.name),
    userAccessURLs = globalHelper.getCurData('curUserPermissions');
  if (!currRoute || userAccessURLs.length === 0 || currRoute.access === '' ||
    (currRoute.access !== '' && userAccessURLs.indexOf(currRoute.access) !== -1)) redirectRoute ? next(redirectRoute) : next();
  else next ('/info');
};

const router = new Router({
  mode: "history",
  routes: [
    {
      path: '/login', name: 'Login',
      component: () => import('@/pages/auth/Login'),
      beforeEnter: (to, from, next) => {
        if (globalHelper.getCurData('curUser')) {
          next('/info');
        } else {
          next();
        }
      }
    }, {
      path: '/forgot-password', name: 'Forget Password',
      component: () => import('@/pages/auth/ForgotPassword'),
      alias: ['/reset-password'],
      props: route => ({ query: route.fullPath === '/forgot-password' ? null : route.query.token}),
      beforeEnter: (to, from, next) => {
        if (to.path === '/reset-password' && !to.query.token) {
          next('/error');
        } else {
          next();
        }
      }
    }, {
      path: "/error-403", name: "Error 403",
      component: AccessDenied,
    }, {
      path: "/error", name: "Error 404",
      component: PageNotFound,
    }, {
        path: "/3d-secure-completed", name: 'Payment Success',
        component: SecureStripNotify,
        beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
        },
    }, {
      path: "/home",
      redirect: "/",
    }, {
      path: "/",
      component: () => import("@/pages/index"),
      beforeEnter: (to, from, next) => {
        if (globalHelper.getCurData('curUser')) {
          return checkAccessURLs(to, next);
        } else {
          next('/login')
        }
      },
      children: [
        {
          path: "/users", name: 'Users',
          component: Users,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/users/create-user", name: 'Create User',
          component: UserForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/users/edit-user/:userId", name: 'Edit User',
          component: UserForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/roles", name: 'Roles',
          component: Roles,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/roles/create-role", name: 'Create Role',
          component: RoleForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/roles/edit-role/:roleId", name: 'Edit Role',
          component: RoleForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/permissions", name: 'Permissions',
          component: Permissions,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/permissions/create-permission", name: 'Create Permission',
          component: PermissionForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/permissions/edit-permission/:permissionId", name: 'Edit Permission',
          component: PermissionForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/lp-entry", name: 'LP Entry',
          component: () => import("@/pages/lp-entry/index"),
        }, {
          path: "/update-contact", name: 'Update Contact',
          component: () => import("@/pages/update-contact/index")
        }, {
          path: "/donations", name: 'Donations',
          component: Donations,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/hourly-summary", name: 'Hourly Summary',
          component: HourlySummary,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/last-3hours-summary", name: 'Hourly Summary(Last 3hrs)',
          component: Last3HoursSummary,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/hourly-report", name: 'Hourly Report',
          component: HourlyReport,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/challenges", name: 'Challenges',
          component: Challenges,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/stories", name: 'Stories',
          component: Stories,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/audits", name: 'Audits',
          component: Audits,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/payouts", name: 'Payouts',
          component: Payouts,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
            path: "/sync-failed-contacts", name: 'Sync Failed Contacts',
            component: SyncFailedContacts,
            beforeEnter: (to, from, next) => {
                const permissions = globalHelper.getCurData('curUserPermissions');
                if (permissions.length === 0) next('/info');
                else next();
            },
        }, {
          path: "/sync-failed-txns", name: 'Sync Failed Txns',
          component: SyncFailedTxns,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/sync-failed-donations", name: 'Sync Failed Donations',
          component: SyncFailedDonations,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/producer", name: 'Producer',
          component: Producer,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/tally", name: 'Tally',
          component: Tally,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/onair", name: 'On Air',
          component: OnAir,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/sound-effect", name: 'Sound Effect',
          component: SoundEffect,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/staff", name: 'Staff',
          component: OnAirStaff,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/incentives", name: 'Incentives',
          component: Incentives,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/incentives/create-incentive", name: 'Create Incentive',
          component: IncentiveForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/incentives/edit-incentive/:incentiveId", name: 'Edit Incentive',
          component: IncentiveForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/campaigns", name: 'Campaigns',
          component: Campaigns,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/campaigns/create-campaign", name: 'Create Campaign',
          component: CampaignForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/campaigns/edit-campaign/:campaignId", name: 'Edit Campaign',
          component: CampaignForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/donate-page-themes", name: 'Donate Page Themes',
          component: DonatePageThemes,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/donate-page-themes/create-donate-theme", name: 'Create Donate Page Theme',
          component: DonatePageThemeForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/donate-page-themes/edit-donate-theme/:themeId", name: 'Edit Donate Page Theme',
          component: DonatePageThemeForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/membership-page-theme", name: 'Membership Page Theme',
          component: MembershipPageTheme,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/app-settings", name: 'App Settings',
          component: AppSettings,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/app-settings/edit-setting/:appSettingId", name: 'Edit Setting',
          component: AppSettingForm,
          beforeEnter: (to, from, next) => {
            const permissions = globalHelper.getCurData('curUserPermissions');
            if (permissions.length === 0) next('/info');
            else next();
          },
        }, {
          path: "/update-profile", name: 'Update Profile',
          component: UpdateProfile
        }, {
          path: "/info", name: 'Info',
          component: () => import("@/pages/confidentiality-info/index")
        }, {
          path: "/opportunity/:sfId", name: 'SF Opportunity',
          component: SfOpportunity
        }, {
          path: "/refund/:oppId/:txnId", name: 'Refund Transaction',
          component: sfRefundTxn
        }, {
          path: "**", name: 'Home',
          component: () => import("@/pages/dashboard/index"),
        }
      ],
    }
  ],
});

if (window.VUE_APP_SENTRY_DSN && window.VUE_APP_SENTRY_ORIGINS) {
  Sentry.init({
    Vue,
    dsn: window.VUE_APP_SENTRY_DSN,
    environment: window.VUE_APP_SENTRY_ENV || 'staging',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: window.VUE_APP_SENTRY_ORIGINS,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

export default router;
