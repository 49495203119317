<template>
    <div id="tally-page">
      <v-row class="pa-0 ma-0 mb-3" align="center" justify="center">
        <v-col class="d-flex align-center justify-center">
          <div class="skeleton-list-item w-10 mx-4" v-if="producerActcampaignLoading">
              <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
          </div>
          <v-banner v-if="!producerActcampaignLoading" outlined class="px-8 text-h6 border-w-3 border-primary primary--text font-weight-bold bg-white mr-10">
            {{ producerActCampModel ? producerActCampModel.progress : 0 }}% Raised
          </v-banner>
          <div class="skeleton-list-item w-10 mx-4" v-if="producerActcampaignLoading">
              <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
          </div>
          <v-banner v-if="!producerActcampaignLoading" outlined class="px-8 text-h6 border-w-3 border-primary primary--text font-weight-bold bg-white mr-10">
            <span v-currency="producerActCampModel? producerActCampModel.raised : 0"></span>
          </v-banner>
          <div class="skeleton-list-item w-10 mx-4" v-if="latestIncentiveLoading">
              <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
          </div>
          <v-banner v-if="!latestIncentiveLoading" outlined class="px-8 text-h6 border-w-3 border-primary primary--text font-weight-bold bg-white">
            Incentive:
            <span v-if="incModel && incModel.sum_incentive_count"> {{ incModel.sum_incentive_count }} </span>
            <span v-else>0</span>
            /<span v-if="incModel && incModel.maximum_incentive"> {{ $filters.currencyComma(incModel.maximum_incentive) }}</span>
            <span v-else>0</span>
          </v-banner>
        </v-col>
      </v-row>
      <v-card>
        <v-row class="pa-0 ma-0">
            <DonationToTally :wsBind="wsData"/>
            <HeldDonation :wsBind="wsData" />
            <DonationToIF :wsBind="wsData" />
            <InspirationFund />
        </v-row>
      </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { CurrencyFormat } from "@/directives";

export default {
  name: "Tally",
  metaInfo: {
    title: "Tally",
  },
  directives: {
    'currency': CurrencyFormat,
  },
  components: {
    DonationToTally: () => import("@/pages/active-campaign/tally/components/donation-tally"),
    HeldDonation: () => import("@/pages/active-campaign/tally/components/held-donation"),
    DonationToIF: () => import("@/pages/active-campaign/tally/components/donation-if"),
    InspirationFund: () => import("@/pages/active-campaign/tally/components/inspiration-fund"),
  },
  data() {
    return {
      tally: false,
      held: false,
      inspirationIF: false,
      incModel: {},
      wsData: null,
    }
  },
  computed: {
    ...mapGetters(['producerActcampaignLoading', 'producerActCampModel', 'latestIncentiveLoading', 'currentIncentive'])
  },
  watch: {
    'currentIncentive': function(newVal) {
      if (newVal) {
        this.incModel = newVal;
      }
    }
  },
  created() {
    this.getActiveCampaign({with_variation: 1});
    this.getLatestIncentive({with_variation: 1}).then(resp => this.incModel = resp.data.data);
  },
  mounted() {
    this.wsOptActionUpdate();
  },
  methods: {
    ...mapActions(['getActiveCampaign', 'getLatestIncentive']),

    wsOptActionUpdate() {
      Echo.channel('incentive').listen('.active',(incentiveModel) => {
          this.incModel = incentiveModel;
      });
      Echo.channel('donation').listen('.created',(donationItem) => {
        this.wsData = donationItem;
      });
      Echo.channel('donation').listen('.updated',(donationItem) => {
        this.wsData = donationItem;
      });
    }
  },
  beforeDestroy() {
      Echo.leaveChannel('incentive');
      Echo.leaveChannel('donation');
  }
};
</script>
