export default {
    state: {
        donationsData: {}
    },
    getters: {
        donationLists: state => state.donationsData.data || [],
    },
    mutations: {
        setDonationsData(state, data) {
            state.donationsData = data;
        },
    },
};

