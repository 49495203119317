import http from '@/http';

export default {
    state: {
        donateSearchLoading: false
    },
    getters: {
        donateSearchLoading: state => state.donateSearchLoading,
    },
    mutations: {
        setDonateSearchLoading(state, data) {
            state.donateSearchLoading = data;
        },
    },
    actions: {
        getDonationLists({ commit }, endPoint) {
            return new Promise((res, rej) => {
                http('donate').get(`donates?${endPoint}`).then(response => {
                    const resp = response.data.data || null;
                    res(resp);
                }).catch((error) => {
                    if(!error.__CANCEL__) commit("setDonateSearchLoading", false);
                    rej(error)
                });
            });
        },
        donateSearch({ commit }, searchData) {
            commit("setDonateSearchLoading", true);
            return new Promise((res, rej) => {
                http('donate').post('donates/search', searchData).then(response => {
                    commit("setDonateSearchLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setDonateSearchLoading", false);
                    rej(error)
                });
            });
        },
        updateDonate({ commit }, donateData) {
            return new Promise((res, rej) => {
                http('donate').put(`donates/${donateData.id}`, donateData).then(response => {
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        donationClear({ commit }) {
            commit("setDonateSearchLoading", true);
            return new Promise((res, rej) => {
                http('donate').get('donates/clear').then(response => {
                    commit("setDonateSearchLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setDonateSearchLoading", false);
                    rej(error)
                });
            });
        },
        donateCancel({ commit }, data) {
            return new Promise((res, rej) => {
                http('donate').post(`/donates/${data.id}/cancel`, data).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Light Partnership Subscription Cancelled Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        donateUpdateExp({ commit }, data) {
            return new Promise((res, rej) => {
                http('donate').post(`/donates/${data.id}/update-expiry`, data.reqData).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Card Expiry Updated Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        donateUpdateCard({ commit }, data) {
            return new Promise((res, rej) => {
                http('donate').post(`/donates/${data.id}/update-card`, data.reqData).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Card Updated Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        donateClearMissPay({ commit }, donateId) {
            return new Promise((res, rej) => {
                http('donate').post(`/donates/${donateId}/clear-payment`).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Clear Missed Payment Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        donateChangeSchedule({ commit }, data) {
            return new Promise((res, rej) => {
                http('donate').post(`/donates/${data.id}/change-schedule`, data.reqData).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Schedule Changed Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        donateSubPause({ commit }, data) {
            return new Promise((res, rej) => {
                http('donate').post(`/donates/${data.id}/pause-subscription`, data.reqData).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Light Partnership Subscription Paused Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        transactionSearch({ commit }, data) {
            return new Promise((res, rej) => {
                http('donate').post('/transactions/search', data).then(response => {
                    const resp = response.data.data || null;
                    res(resp);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        refundTxn({ commit }, data) {
            return new Promise((res, rej) => {
                http('donate').post(`/transactions/${data.id}/refund`, data.reqData).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Amount Refunded Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        updateDonateAmt({ commit }, data) {
            return new Promise((res, rej) => {
                http('donate').post(`/donates/${data.id}/update-amount`, data.reqData).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Amount Updated Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
    },
};
