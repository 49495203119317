<template>
  <v-row id="user-form-page">
    <v-col cols="12">
        <base-card>
            <v-card-title class="font-weight-bold">{{`${userId ? 'Edit' : 'Create'} User`}}</v-card-title>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveUser">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12 col-lg-6" class="ma-0 pt-0 pb-0 pl-0">
                            <v-text-field  ref="first_name" v-model="model.first_name" :error-messages="$helpers.errorMsg('name', $v.model.first_name, 'First Name')"
                                label="First Name" @input="$v.model.first_name.$touch()"  @blur="$v.model.first_name.$touch()" required/>
                        </v-col>
                        <v-col cols="12 col-lg-6" class="ma-0 pt-0 pb-0 pr-0">                        
                            <v-text-field ref="last_name" v-model="model.last_name" :error-messages="$helpers.errorMsg('name', $v.model.last_name, 'Last Name')"
                                label="Last Name" @input="$v.model.last_name.$touch()"  @blur="$v.model.last_name.$touch()" required/>
                        </v-col>
                    </v-row>
                    <v-text-field v-model="model.email" :error-messages="$helpers.errorMsg('email', $v.model.email, 'Email')"
                        label="E-mail" ref="email" @input="$v.model.email.$touch()"  @blur="$v.model.email.$touch()" required />

                    <v-text-field ref="password" :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        v-model="model.password" :error-messages="!userId ? $helpers.errorMsg('name', $v.model.password, 'Password'): ''"
                        label="Password" @click:append="show = !show" :required="!userId" />

                    <v-combobox ref="roles"  @input="$v.model.roles.$touch()"  @blur="$v.model.roles.$touch();searchClear('roles')"
                        v-model="model.roles"  :error-messages="$helpers.errorMsg('name', $v.model.roles, 'Roles')"
                        :items="roleLists" chips label="Roles" multiple outlined>
                        <template v-slot:prepend-item>
                            <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggle"
                            >
                            <v-list-item-action>
                                <v-icon :color="model.roles.length > 0 ? 'secondary' : ''">
                                {{ icon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                Select All
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template> 
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index <= 4" close  @click:close="model.roles.splice(index, 1)">
                            <span class="text-capitalize">{{ item}}</span>
                            </v-chip>
                            <span
                            v-if="index === 5"
                            class="grey--text text-caption"
                            >
                            (+{{ model.roles.length - 5 }} others)
                            </span>
                        </template>              
                    </v-combobox>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="secondary" :disabled="selectUserLoading" class="mr-4">
                            Save
                        </v-btn>
                        <v-btn type="button" color="primary" :disabled="selectUserLoading" class="mr-4" to="/users">
                            Back
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { email, required } from "vuelidate/lib/validators";

export default {
    metaInfo: {
        title: "Users",
    },
    data: (vm) => ({
        userId: vm.$route.params.userId,
        show: false,
        model: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            roles:[],
        },
    }),
    validations() {
        const validateModel = {
            model: {
                first_name: { required},
                last_name: { required},
                email: { email, required},
                roles: { required},
            }
        }
        if (!this.userId) {
            validateModel.model['password'] = {required }
            return validateModel;
        } else {
            return validateModel;
        }
    },
    computed: {
        ...mapGetters(["roleLists", "getSelectedUser", "selectUserLoading"]),
    
      selectedAllRoles() {
        return this.model.roles.length === this.roleLists.length
      },
      selectSomeRoles() {
        return this.model.roles.length > 0 && !this.selectedAllRoles
      },
      icon() {
        if (this.selectedAllRoles) return 'mdi-close-box'
        if (this.selectSomeRoles) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
    created() {
        if (this.userId) {
            this.getUserData(this.userId);
        }
        this.getRolesList();
    },
    methods: {
        ...mapActions(['getRolesList', 'getUserData', 'updateUserData', 'createUser']),

        toggle() {
            this.$nextTick(() => {
            if (this.selectedAllRoles) {
                this.model.roles = []
            } else {
                this.model.roles = this.roleLists.slice()
            }
            })
        },
        searchClear(value) {
            if (this.model[value].length === 0) {
                this.$refs[value].lazySearch = '';
            }
        },
        saveUser() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            const reqData = this.$helpers.cloneData(this.model);
            if (this.userId) {
                this.updateUser(reqData);
            } else {
                this.createNewUser(reqData);
            }
        },
        createNewUser (reqData) {
            const self = this;
            this.createUser(reqData).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                self.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "User Created Successfully",
                    color: "success",
                });
                self.$router.push("/users");
            }).catch(() => {});
        },
        updateUser(reqData) {
            const self = this;
            if (reqData.password === '') {
                delete reqData.password;
            }
            this.updateUserData(reqData).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                self.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "User Updated Successfully",
                    color: "success",
                });
                self.$router.push("/users");
            }).catch(() => {});
        }
    },
    watch: {
        'getSelectedUser': function (newValue) {
            this.model = {...this.model, ...newValue}
        }
    }
};
</script>
