<template>
  <v-app>
    <router-view/>
    <Snackbar></Snackbar>
  </v-app>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      title: "Home",
      titleTemplate: `%s | ${window.VUE_APP_APP_NAME}`,
      script: [{
        src: `//maps.google.com/maps/api/js?key=${window.VUE_APP_MAP_KEY}`,
        type: "text/javascript"
      },
      {
        src: '//unpkg.com/@googlemaps/markerclusterer/dist/index.min.js',
        type: "text/javascript"
      }]
    };
  },
  components: {
    Snackbar: () => import("@/components/base/Snackbar"),
  }
};
</script>