<template>
  <v-row id="user-form-page">
    <v-col cols="12">
        <base-card>
            <v-card-title class="font-weight-bold">{{`${incentiveId ? 'Edit' : 'Create'} Incentive`}}</v-card-title>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveIncentive">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="col-lg-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">
                                SF Incentive
                                <v-btn class="mx-2 mb-1 secondary" x-small icon dark @click="!picklistLoading && getIncentiveLists({force: 1})">
                                    <v-icon dark size="15" v-if="!picklistLoading">mdi-sync</v-icon>
                                    <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
                                </v-btn>
                            </v-subheader>
                            <div class="skeleton-list-item w-full mb-5" v-if="picklistLoading">
                                <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
                            </div>
                            <v-autocomplete v-if="!picklistLoading"
                                ref="sf_incentive"
                                :error-messages="$helpers.errorMsg('name', $v.model.sf_incentive, 'SF Incentive')"
                                v-model="model.sf_incentive"
                                hide-details="auto" label="" placeholder="Select SF Incentive"
                                :items="incentiveLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                                outlined
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" class="col-lg-6">
                             <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-subheader>
                            <v-text-field hide-details="auto" ref="name" v-model="model.name" :error-messages="$helpers.errorMsg('name', $v.model.name, 'Name')"
                                label="" @input="$v.model.name.$touch()" outlined required/>
                        </v-col>
                        <v-col cols="12">
                             <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Heading</v-subheader>
                            <v-text-field hide-details="auto" v-model="model.heading" label="" outlined/>
                        </v-col>
                        <v-col cols="12">
                             <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Description</v-subheader>
                             <v-textarea hide-details="auto" class="mb-3"
                                auto-grow
                                rows="4"
                                v-model="model.description"
                                row-height="20"
                                label=""
                                outlined
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="col-lg-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Threshold Once</v-subheader>
                            <v-text-field type="number" min="0" v-model.number="model.threshold_once" outlined label="" hide-details="auto" placeholder="Threshold Once"/>
                        </v-col>
                        <v-col cols="12" class="col-lg-6">
                             <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Threshold Monthly</v-subheader>
                             <v-text-field type="number" min="0" v-model.number="model.threshold_monthly" outlined label="" hide-details="auto" placeholder="Threshold Monthly"/>
                        </v-col>
                        <v-col cols="12" class="col-lg-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Start Date</v-subheader>
                            <v-menu v-model="startmenu" transition="scale-transition" min-width="250"
                                :close-on-content-click="false" offset-y >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field hide-details="auto"
                                        :value="startDate"
                                        placeholder="Start Date"
                                        readonly
                                        label=''
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                    ></v-text-field>
                                </template>
                                <div class="range-calendar">
                                    <vc-date-picker :min-date="minDate" mode="dateTime" class="date-picker-range" v-model="model.start_date" :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }"
                                        timezone="Australia/Melbourne" color="indigo" :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }" is-required
                                        @input="model.end_date = $helpers.addHoursnDDateJs('date', 1,  model.start_date)"/>
                                    <v-btn class="ma-2"  dark color="primary" @click="startmenu=false;">
                                        Ok
                                    </v-btn>
                                </div>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" class="col-lg-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">End Date</v-subheader>
                            <v-menu v-model="endmenu" transition="scale-transition" min-width="250"
                                :close-on-content-click="false" offset-y >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field hide-details="auto"
                                        :value="endDate"
                                        placeholder="End Date"
                                        readonly
                                        label=''
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                    ></v-text-field>
                                </template>
                                <div class="range-calendar">
                                    <vc-date-picker :min-date="model.start_date" mode="dateTime" class="date-picker-range" v-model="model.end_date" :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }"
                                        timezone="Australia/Melbourne" color="indigo" :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }" is-required/>
                                    <v-btn class="ma-2"  dark color="primary" @click="endmenu=false;">
                                        Ok
                                    </v-btn>
                                </div>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" class="col-lg-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Max No of Incentive</v-subheader>
                            <v-text-field type="number" min="0" v-model.number="model.maximum_incentive" outlined label="" hide-details="auto" placeholder="Threshold Once"/>
                        </v-col>
                        <v-col cols="12" class="col-lg-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Show Opt in checkbox</v-subheader>
                            <v-row class="ma-0 pa-0">
                                <v-switch :false-value="0" :true-value="1" v-model="model.optin" hide-details="auto" class="pt-0 pr-5"
                                    inset :label="`${model.optin == '1' ? 'Yes' : 'No'}`"
                                ></v-switch>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="model.optin == 1">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Opt in description</v-subheader>
                            <v-text-field v-model.number="model.optin_desc" outlined label="" hide-details="auto" placeholder="Opt In Description"
                               ref="optin_desc" :error-messages="$helpers.errorMsg('name', $v.model.optin_desc, 'Opt in description')"/>
                            <v-radio-group v-model="model.optin_val" row ref="optin_val"
                                :error-messages="$helpers.errorMsg('name', $v.model.optin_val, 'Opt in value')">
                                <v-radio label="FEBC" value="FEBC"></v-radio>
                                <v-radio label="Compassion" value="Compassion"></v-radio>
                                <v-radio label="Salvos" value="Salvos"></v-radio>
                                <v-radio label="Brotherhood of St Laurence" value="Brotherhood of St Laurence"></v-radio>
                                <v-radio label="Su (Scripture Union)" value="Su (Scripture Union)"></v-radio>
                                <v-radio label="Destiny Rescue" value="Destiny Rescue"></v-radio>
                                <v-radio label="CBM Australia" value="CBM Australia"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>

                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="secondary" :disabled="picklistLoading || selectIncentiveLoading" class="mr-4">
                            Save
                        </v-btn>
                        <v-btn type="button" color="primary" :disabled="selectIncentiveLoading" class="mr-4" to="/incentives">
                            Back
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
    metaInfo: {
        title: "Users",
    },
    data: (vm) => ({
        minDate: vm.$helpers.getJsDateBeginEnd('start'),
        incentiveId: vm.$route.params.incentiveId,
        model: {
            sf_incentive: null,
            name: null,
            start_date: null,
            end_date: null,
            maximum_incentive: null,
            active: 0
        },
        startmenu: false,
        endmenu: false,
        incentiveLists: [],
    }),
    validations: {
        model: {
            sf_incentive: { required},
            name: { required},
        }
    },
    validations() {
        const valid = {
            model: {
                sf_incentive: { required},
                name: { required},
            }
        };
        if (this.model.optin) {
            valid.model['optin_desc'] = { required };
            valid.model['optin_val'] = { required };
        }
        return valid;
    },
    computed: {
        ...mapGetters(["getSelectedIncentive", "selectIncentiveLoading", 'picklistLoading']),


        startDate() {
            return this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.model.start_date);
        },
        endDate() {
            return this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.model.end_date);
        }
    },
    created() {
        if (this.incentiveId) {
            this.getIncentiveData(this.incentiveId);
        }
        this.getIncentiveLists();
    },
    mounted() {
        this.model.start_date = this.$helpers.getJsDateBeginEnd('start');
        this.model.end_date = this.$helpers.getJsDateBeginEnd();
    },
    methods: {
        ...mapActions(['getPicklist', 'getIncentiveData', 'updateIncentiveData', 'createIncentive']),

        getIncentiveLists(data) {
            let reqData = {object: "Lightfm_Centre_Transaction__c", field: "Lightfm_Incentive__c"};
            if (data) reqData = {...reqData, ...data};
            this.getPicklist(reqData).then((response) => {
                this.incentiveLists = response;
            });
        },
        saveIncentive() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            const reqData = this.$helpers.cloneData(this.model);
            if (this.incentiveId) {
                this.updateIncentive(reqData);
            } else {
                this.createNewIncentive(reqData);
            }
        },
        createNewIncentive (reqData) {
            const self = this;
            this.createIncentive(reqData).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                self.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Incentive Created Successfully",
                    color: "success",
                });
                self.$router.push("/incentives");
            }).catch(() => {});
        },
        updateIncentive(reqData) {
            const self = this;
            this.updateIncentiveData(reqData).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                self.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Incentive Updated Successfully",
                    color: "success",
                });
                self.$router.push("/incentives");
            }).catch(() => {});
        }
    },
    watch: {
        'getSelectedIncentive': function (newValue) {
            this.model = {...this.model, ...newValue};
            this.minDate = this.$helpers.getJsDateBeginEnd('start', this.model.start_date);
        }
    }
};
</script>
