<template>
    <div class="editor-container">
        <textarea id="editor-text-box" v-model="content" name="editor-content"></textarea>
    </div>
</template>

<script>
export default {
    props: ['setContent', 'placeholder', 'height'],
    emits: ['updateContent', 'inModalTrigger'],
    components: { 
    },
    data: () => ({
        content: '',
    }),
    created() {
        if (this.setContent) this.content = this.setContent;
    },
    mounted() {
        const self = this;
        $('#editor-text-box').summernote({
            placeholder: self.placeholder,
            height: self.height || 'auto',
            addDefaultFonts: false,
            toolbar: [
                ['style', ['style']],
                ['fontsize', ['fontsize']],
                ['font', ['bold', 'underline', 'strikethrough']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link']],
                ['view', ['fullscreen', 'codeview']],
            ],
            callbacks: {
                onChange: function(contents) {
                    self.$emit('inModalTrigger', false);
                    self.$emit('updateContent', contents);
                }
            }
        });
        $('#editor-text-box').summernote('fontName', 'Mont');
        $('.note-insert .note-btn').click(function(){
            self.$emit('inModalTrigger', true);
        });
        $('.note-modal-header .close').click(function(){
            self.$emit('inModalTrigger', false);
        });
        $('.note-modal-footer .note-btn').click(function(){
            self.$emit('inModalTrigger', false);
        });
    }
}
</script>