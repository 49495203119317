<template>
  <div id="users-table-page">
    <AdvanceFilter :clearAdvFilter="clearAdvFilter" @reset="resetFilter" @search="searchAdvFilter"/>
    <datatable ref="dtComponent" :modelData="dtData" @deleteItem="deleteUserItem" @toggleField="updateItem" @clearFilter="clearAdvFilter=true;searchLink = null" :sortable="true"/>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Users",
  metaInfo: {
    title: "Users",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
    AdvanceFilter: () => import("@/pages/access/users/components/AdvanceFilter"),
  },
  data() {
    return {
      clearAdvFilter: false,
      dtData: {
        title: 'Users',
        headers: [
          { text: 'First Name', align: 'start', value: 'first_name' },
          { text: 'Last Name', value: 'last_name' },
          { text: "Email", value: "email" },
          { text: "Roles", value: "roles" },
          { text: "2FA", value: 'toggle', field: "google2fa_status", sortable: false },
          { text: "Active", value: 'toggle', field: "status", sortable: false },
          { text: 'Action', value: 'action', sortable: false }
        ],
        actions: ['edit', 'delete'],
        headerActions: ['reset', 'create'],
        createLink: '/users/create-user',
        editLink: '/users/edit-user/',
        pageLink: `${window.VUE_APP_MS_URL}user/users?page=1`
      }
    };
  },
  computed: {
    ...mapGetters(["metaInfoDT", "activeLink", "dtLists", "searchDT"])
  },
  methods: {
    ...mapActions(["deleteUser", "updateUserData"]),

    resetFilter() {
      this.searchLink = null;
      this.$refs.dtComponent.pageLoading(this.dtData.pageLink);
    },
    searchAdvFilter(searchModel) {
      const activeUrl = this.activeLink ? this.activeLink : this.dtData.pageLink;
      this.searchLink = this.$helpers.setURLparams(searchModel, activeUrl);
      this.clearAdvFilter = false;
      this.$refs.dtComponent.pageLoading(this.searchLink);
    },
    updateItem({ item, index, header }) {
      const activeUrl = this.activeLink ? this.activeLink : this.dtData.pageLink;
      item.toggleLoading = true;
      this.$refs.dtComponent.dtLists.splice(index, 1, item);

      const payload = { id: item.id };
      payload[header.field] = item[header.field];

      let message = "Status Updated successfully";

      if (header.field === 'google2fa_status') {
        payload.google2fa_secret = null;
        message = "Google 2Fa Status Successfully";
      }

      this.updateUserData(payload).then((res) => {
        this.$store.commit("snackbar/SHOW_MESSAGE", {
          text: message,
          color: "success",
        });

        item.toggleLoading = false;
        this.$refs.dtComponent.dtLists.splice(index, 1, item);
        this.$refs.dtComponent.pageLoading(activeUrl);
      }).catch(() => {
        item.toggleLoading = false;
        item.status = !item.status;
        this.$refs.dtComponent.dtLists.splice(index, 1, item);
      });
    },
    deleteUserItem(item) {
      let pageLink = new URL(this.activeLink);
      if (this.dtLists.length === 1 || !this.activeLink) {
        pageLink = new URL(this.dtData.pageLink);
        if (this.searchDT !== '') {
          pageLink.searchParams.set("q", this.searchDT);
        }
        pageLink.searchParams.set("page", 1);
      }
      this.deleteUser(item.id).then(() => this.$refs.dtComponent.pageLoading(pageLink));
    }
  },
};
</script>
