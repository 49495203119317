import http from '@/http';

export default {
    state: {
        businessListLoading: {},
    },
    getters: {
        businessListLoading: state => state.businessListLoading,
    },
    mutations: {
        setBusinessLoading(state, data) {
            state.businessListLoading = data;
        },
    },
    actions: {
        getRecordType({ commit }, reqData) {
            return new Promise((res, rej) => {
                http('salesforce').post('recordtype', reqData).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        searchAccount({ commit }, reqData) {
            commit("setBusinessLoading", true);
            return new Promise((res, rej) => {
                http('salesforce').post('accounts/search', reqData).then(response => {
                    commit("setBusinessLoading", false);
                    res(response.data.data);
                }).catch((error) => {
                    commit("setBusinessLoading", false);
                    rej(error)
                });
            });
        },
        updateSyncFailedContact({ commit }, reqData) {
            return new Promise((res, rej) => {
                http('salesforce').put(`contacts/${reqData.id}`, reqData).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
    },
};
