import http from '@/http';

export default {
    state: {
        currentUser: {},
        loading: false,
    },
    getters: {
        loading: state => state.loading,
        curUserName: state => state.currentUser && state.currentUser.name ? state.currentUser.name : '',
    },
    mutations: {
        setUser(state, data) {
            state.currentUser = data;
            state.loading = false;
        },
        setLogout(state) {
            state.currentUser = {};
            state.loading = false;
        },
        setLoading(state, data) {
            state.loading = data;
        },
    },
    actions: {
        login({ commit }, data) {
            commit("setLoading", true);
            return new Promise((res, rej) => {
                http('user').post('auth/login', data).then(response => {
                    commit("setUser", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setLoading", false);
                    rej(error)
                });
            });
        },
        verify2fa({ commit }, data) {
            commit("setLoading", true);
            return new Promise((res, rej) => {
                http('user').post('auth/verify2fa', data).then(response => {
                    commit("setUser", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setLoading", false);
                    rej(error)
                });
            });
        }
    },
};
