
import http from '@/http';

export default {
    state: {
        resetLoading: false,
    },
    getters: {
        resetLoading: state => state.resetLoading,
    },
    mutations: {
        setResetLoading(state, data) {
            state.resetLoading = data;
        },
    },
    actions: {
        reserMailSend({ commit }, data) {
            commit("setResetLoading", true);
            return new Promise((res, rej) => {
                http('user').post('auth/forgot-password', data).then(response => {
                    commit("setResetLoading", false);
                    res(response);
                }).catch(() => {
                    rej(error)
                });
            });
        },
        resetPassword({ commit }, data) {
            commit("setResetLoading", true);
            return new Promise((res, rej) => {
                http('user').post('auth/reset-password', data).then(response => {
                    commit("setResetLoading", false);
                    res(response);
                }).catch(() => {
                    rej(error)});
            });
        }
    },
};
