import http from '@/http';

export default {
    state: {
        permissionsData: {},
        selectedPermission: {},
        selectPermissionLoading: false
    },
    getters: {
        permissionLists: state => state.permissionsData.data || [],
        getSelectedPermission: state => state.selectedPermission,
        selectPermissionLoading: state => state.selectPermissionLoading,
    },
    mutations: {
        setPermissionsData(state, data) {
            state.permissionsData = data;
        },
        setSelectPermissionData(state, data) {
            state.selectedPermission = data;
            state.selectPermissionLoading = false;
        },
        setSelectPermissionLoading(state, data) {
            state.selectPermissionLoading = data;
        },
    },
    actions: {
        getPermissionsList({ commit }) {
            commit("setDTLoading", true);
            return new Promise((res, rej) => {
                http('user').get('permissions/lists').then(response => {
                    commit("setPermissionsData", response.data);
                    commit("setDTDetails", response.data);
                    res(response);
                }).catch(() => {
                    commit("setDTLoading", false);
                    rej(error)
                });
            });
        },
        getPermissionData({ commit }, permissionId) {
            commit("setSelectPermissionLoading", true);
            return new Promise((res, rej) => {
                http('user').get(`permissions/${permissionId}`).then(response => {
                    commit("setSelectPermissionData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectPermissionLoading", false);
                    rej(error)
                });
            });
        },
        createPermission({ commit }, permissionData) {
            commit("setSelectPermissionLoading", true);
            return new Promise((res, rej) => {
                http('user').post('permissions', permissionData).then(response => {
                    commit("setSelectPermissionLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setSelectPermissionLoading", false);
                    rej(error)
                });
            });
        },
        updatePermissionData({ commit }, permissionData) {
            commit("setSelectPermissionLoading", true);
            return new Promise((res, rej) => {
                http('user').put(`permissions/${permissionData.id}`, permissionData).then(response => {
                    commit("setSelectPermissionData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectPermissionLoading", false);
                    rej(error)
                });
            });
        },
        deletePermission({ commit }, permissionId) {
            return new Promise((res, rej) => {
                http('user').deleteReq(`permissions/${permissionId}`).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Permission Deleted Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        }
    },

};

