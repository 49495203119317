import http from '@/http';

export default {
    state: {
        selectedCampaign: {},
        selectCampaignLoading: false,
        campaignCombineLists: [],
        campaignListLoading: false
    },
    getters: {
        getSelectedCampaign: state => state.selectedCampaign,
        selectCampaignLoading: state => state.selectCampaignLoading,
        campaignListLoading: state => state.campaignListLoading,
        campaignCombineLists: state => state.campaignCombineLists,
    },
    mutations: {
        setSelectCampaignData(state, data) {
            state.selectedCampaign = data;
            state.selectCampaignLoading = false;
        },
        setSelectCampaignLoading(state, data) {
            state.selectCampaignLoading = data;
        },
        setCampaignListLoading(state, data) {
            state.campaignListLoading = data;
        },
        setCampaignCombineLists(state, data) {
            state.campaignCombineLists = data;
        },
    },
    actions: {
        getCampaignLists({ commit }, data) {
            commit("setCampaignListLoading", true);
            return new Promise((res, rej) => {
                http('salesforce').post('campaigns/lists', data).then(response => {
                    if (response) {
                        if (data) {
                            commit("setCampaignCombineLists", response.data.data);
                        } else {
                            commit("setCampaignLists", response.data.data);
                        }
                    }
                    commit("setCampaignListLoading", false);
                    res(response);
                }).catch((error) => {
                    if (data) {
                        commit("setCampaignCombineLists", []);
                    } else {
                        commit("setCampaignLists", []);
                    }
                    commit("setCampaignListLoading", false);
                    rej(error)
                });
            });
        },
        getCampaignSearch({ commit }, data) {
            return new Promise((res, rej) => {
                http('salesforce').post('campaigns/search', data).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error);
                });
            });
        },
        getCampaignMarkers({ commit }, campaignId) {
            return new Promise((res, rej) => {
                http('donate').get(`campaigns/markers/${campaignId}`).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        createCampaign({ commit }, campaignData) {
            commit("setSelectCampaignLoading", true);
            return new Promise((res, rej) => {
                http('donate').post('campaigns', campaignData).then(response => {
                    commit("setSelectCampaignLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setSelectCampaignLoading", false);
                    rej(error)
                });
            });
        },
        getCampaignData({ commit }, campaignId) {
            commit("setSelectCampaignLoading", true);
            return new Promise((res, rej) => {
                http('donate').get(`campaigns/${campaignId}`).then(response => {
                    commit("setSelectCampaignData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectCampaignLoading", false);
                    rej(error)
                });
            });
        },
        updateCampaignData({ commit }, campaignData) {
            commit("setSelectCampaignLoading", true);
            return new Promise((res, rej) => {
                http('donate').put(`campaigns/${campaignData.id}`, campaignData).then(response => {
                    commit("setSelectCampaignData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectCampaignLoading", false);
                    rej(error)
                });
            });
        },
        deleteCampaign({ commit }, campaignId) {
            return new Promise((res, rej) => {
                http('donate').deleteReq(`campaigns/${campaignId}`).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Campaign Deleted Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
    },

};
