
import http from '@/http';

export default {
    state: {
        hourlyReport: null,
        hourlyReportLoading: false,
        OnAirSummary: null,
        OffAirSummary: null,
        MajorSummary: null,
        AllDonationSummary: null
    },
    getters: {
        hourlyReport: state => state.hourlyReport,
        hourlyReportLoading: state => state.hourlyReportLoading,
        OnAirSummary: state => state.OnAirSummary,
        OffAirSummary: state => state.OffAirSummary,
        MajorSummary: state => state.MajorSummary,
        AllDonationSummary: state => state.AllDonationSummary,
    },
    mutations: {
        setHourlyReport(state, data) {
            state.hourlyReport = data || {};
            state.OnAirSummary = data ? data.ON : {};
            state.OffAirSummary = data ? data.OFF : {};
            state.MajorSummary = data ? data.MAJOR : {};
            state.AllDonationSummary = data ? data.ALL : {};
            state.hourlyReportLoading = false;
        },
        setHourlyReportLoading(state, data) {
            state.hourlyReportLoading = data;
        },
    },
    actions: {
        getHourlySummary({ commit }, data) {
            const endPoint = data ? `hourly/summary${data}` : 'hourly/summary'
            commit("setHourlyReportLoading", true);
            return new Promise((res, rej) => {
                http('donate').get(endPoint).then(response => {
                    const resp = response.data.data || null;
                    commit("setHourlyReport", resp);
                    res(response);
                }).catch((error) => {
                    commit("setHourlyReportLoading", false);
                    rej(error)
                });
            });
        },
    }
};

