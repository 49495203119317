<template>
    <v-row id="hourly-summary-page" class="ma-0 pa-0">
        <v-col cols="12">
            <AdvanceFilter :exportLoading="exportLoading" @reset="resetFilter" @search="searchAdvFilter"/>
            <OnAir />
            <OffAir />
            <Major />
            <AllDonations />
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  metaInfo: {
    title: "Hourly Summary",
  },
  components: {
    OnAir : () => import("@/pages/reports/hourly-summary/components/OnAirSummary"),
    OffAir : () => import("@/pages/reports/hourly-summary/components/OffAirSummary"),
    Major : () => import("@/pages/reports/hourly-summary/components/MajorSummary"),
    AllDonations : () => import("@/pages/reports/hourly-summary/components/AllDonateSummary"),
    AdvanceFilter: () => import("@/pages/reports/hourly-summary/components/AdvanceFilter"),
  },
  data: (vm) => ({
    searchLink: null,
    exportLoading: false
  }),
  created() {
    this.resetFilter();
  },
  methods: {
    ...mapActions(["getHourlySummary"]),

    resetFilter() {
      this.searchLink = null;
      this.getHourlySummary();
    },
    searchAdvFilter(searchModel) {
        this.searchLink = this.$helpers.setURLparams(searchModel, '?', 'object');
        this.getHourlySummary(this.searchLink);
    }
  }
}
</script>
