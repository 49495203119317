<template>
  <v-row id="themeform-page">
    <v-col cols="12">
        <base-card>
            <v-card-title class="font-weight-bold">Membership Page Theme</v-card-title>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveTheme">
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete ref="sf_campaign_id"
                                :error-messages="$helpers.errorMsg('name', $v.model.sf_campaign_id, 'Campaigns')"
                                v-model="model.sf_campaign_id"
                                hide-details="auto" label="Campaigns"
                                :items="campaignLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                                outlined
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field  ref="title" v-model="model.title" outlined :error-messages="$helpers.errorMsg('name', $v.model.title, 'Name')" label="Name" required/>
                        </v-col>
                    </v-row>
                    <v-card-title class="font-weight-medium ma-0 pa-0">Theme Settings</v-card-title>
                    <v-divider class="mb-2"></v-divider>
                    <v-row align="center" justify="center">
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Heading</v-subheader>
                            <v-text-field  v-model="model.heading" outlined label="" hide-details="auto" placeholder="Heading"/>
                        </v-col>
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Description</v-subheader>
                            <v-textarea v-model="model.description" outlined label="" auto-grow hide-details="auto" placeholder="Description"
                                rows="4" row-height="20"/>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">New Membership Amount</v-subheader>
                            <v-text-field min="0" placeholder="New Membership Amount" outlined hide-details="auto" type="number"
                                v-model.number="model.new_amount" @focus="$helpers.avoidInputScrollable" @keydown="$helpers.avoidInputArrowKeyPress">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Renewal Amount</v-subheader>
                            <v-text-field min="0" placeholder="Renewal Amount" outlined hide-details="auto" type="number"
                                v-model.number="model.renewal_amount" @focus="$helpers.avoidInputScrollable" @keydown="$helpers.avoidInputArrowKeyPress">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Rules</v-subheader>
                            <v-textarea v-model="model.rules" outlined label="" auto-grow hide-details="auto" placeholder="Rules"
                                rows="4" row-height="20"/>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Rules URL</v-subheader>
                            <v-text-field  v-model="model.rules_url" outlined label="" hide-details="auto" placeholder="Rules URL"/>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Donate background Desktop Image</v-subheader>
                            <v-row v-if="donate_bg_imageLoading" class="fill-height" align-content="center" justify="center">
                                <v-col class="text-subtitle-1 text-center primary--text pb-0 font-weight-bold" cols="12">
                                    Upload your image
                                </v-col>
                                <v-col cols="6">
                                    <v-progress-linear color="secondary accent-4" indeterminate rounded height="6"/>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" align="center" v-if="!donate_bg_imageLoading && images.donate_bg_image">
                                <span class="text-subtitle-1 secondary--text mr-3 font-weight-bold text-uppercase">Preview previous image:</span>
                                <a class="info--text" :href="images.donate_bg_image" target="_blank" >{{ images.donate_bg_image.substring(images.donate_bg_image.lastIndexOf('/')+1) }}</a>
                            </v-row>
                            <v-file-input :clearable="false" placeholder="Upload Your Background Desktop Image" v-if="!donate_bg_imageLoading"
                                @change="$event && imageUploaded($event, 'bgDeskErr', 'donate_bg_image')" label="" outlined hide-details="auto" prepend-icon="mdi-file-upload" v-model="model.donate_bg_image"
                                accept="image/png, image/jpeg, image/jpg" :error-messages="imageError.bgDeskErr ? [imageError.sizeErr?'Please upload upto 8MB file size':'Image is invalid. (Only jpg,jpeg,png images are accepted)'] : []"></v-file-input>
                        </v-col>
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Donate background Mobile Image</v-subheader>
                            <v-row v-if="donate_bg_mobile_imageLoading" class="fill-height" align-content="center" justify="center">
                                <v-col class="text-subtitle-1 text-center primary--text pb-0 font-weight-bold" cols="12">
                                    Upload your image
                                </v-col>
                                <v-col cols="6">
                                    <v-progress-linear color="secondary accent-4" indeterminate rounded height="6"/>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" align="center" v-if="!donate_bg_mobile_imageLoading && images.donate_bg_mobile_image">
                                <span class="text-subtitle-1 secondary--text mr-3 font-weight-bold text-uppercase">Preview previous image:</span>
                                <a class="info--text" :href="images.donate_bg_mobile_image" target="_blank" >{{ images.donate_bg_mobile_image.substring(images.donate_bg_mobile_image.lastIndexOf('/')+1) }}</a>
                            </v-row>
                            <v-file-input :clearable="false" placeholder="Upload Your Background Mobile Image" v-if="!donate_bg_mobile_imageLoading"
                                @change="$event && imageUploaded($event, 'bgMobErr', 'donate_bg_mobile_image')" label="" outlined hide-details="auto" prepend-icon="mdi-file-upload" v-model="model.donate_bg_mobile_image"
                                accept="image/png, image/jpeg, image/jpg" :error-messages="imageError.bgMobErr ? [imageError.sizeErr?'Please upload upto 8MB file size':'Image is invalid. (Only jpg,jpeg,png images are accepted)'] : []">
                            </v-file-input>
                        </v-col>
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Thankyou Banner</v-subheader>
                            <v-textarea v-model="model.thank_you_banner" outlined label="" auto-grow hide-details="auto" placeholder="Description"
                                rows="4" row-height="20"/>
                        </v-col>
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Meta Title</v-subheader>
                            <v-textarea v-model="model.meta_title" outlined label="" auto-grow hide-details="auto" placeholder="Description"
                                rows="4" row-height="20"/>
                        </v-col>
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Meta Descripition</v-subheader>
                            <v-textarea v-model="model.meta_descripition" outlined label="" auto-grow hide-details="auto" placeholder="Description"
                                rows="4" row-height="20"/>
                        </v-col>
                        <v-col cols="11">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Meta Image</v-subheader>
                            <v-row v-if="meta_imageLoading" class="fill-height" align-content="center" justify="center">
                                <v-col class="text-subtitle-1 text-center primary--text pb-0 font-weight-bold" cols="12">
                                    Upload your image
                                </v-col>
                                <v-col cols="6">
                                    <v-progress-linear color="secondary accent-4" indeterminate rounded height="6"/>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0 pa-0" align="center" v-if="!meta_imageLoading && images.meta_image">
                                <span class="text-subtitle-1 secondary--text mr-3 font-weight-bold text-uppercase">Preview previous image:</span>
                                <a class="info--text" :href="images.meta_image" target="_blank" >{{ images.meta_image.substring(images.meta_image.lastIndexOf('/')+1) }}</a>
                            </v-row>
                            <v-file-input placeholder="Upload Your Meta Image" :clearable="false" v-if="!meta_imageLoading"
                                @change="$event && imageUploaded($event, 'metaErr', 'meta_image')" label="" outlined hide-details="auto" prepend-icon="mdi-file-upload" v-model="model.meta_image"
                                accept="image/png, image/jpeg, image/jpg" :error-messages="imageError.metaErr ? [imageError.sizeErr?'Please upload upto 8MB file size':'Image is invalid. (Only jpg,jpeg,png images are accepted)'] : []">
                            </v-file-input>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center" class="mt-4 mb-6">
                        <v-col cols="3">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Show Facebook Button</v-subheader>
                            <v-row class="ma-0 pa-0">
                                <v-switch false-value="Off" true-value="On" v-model="model.show_like_button" hide-details="auto" class="pt-0 pr-5"
                                    inset :label="`${model.show_like_button === 'On' ? 'On' : 'Off'}`"
                                ></v-switch>
                            </v-row>
                        </v-col>
                        <v-col cols="3">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Show Header Phone Number</v-subheader>
                            <v-row class="ma-0 pa-0">
                                <v-switch false-value="Off" true-value="On" v-model="model.show_phone" hide-details="auto" class="pt-0 pr-5"
                                    inset :label="`${model.show_phone === 'On' ? 'On' : 'Off'}`"
                                ></v-switch>
                            </v-row>
                        </v-col>
                        <v-col cols="3">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Show Campaign Progress Bar</v-subheader>
                            <v-row class="ma-0 pa-0">
                                <v-switch false-value="Off" true-value="On" v-model="model.show_target" hide-details="auto" class="pt-0 pr-5"
                                    inset :label="`${model.show_target === 'On' ? 'On' : 'Off'}`"
                                ></v-switch>
                            </v-row>
                        </v-col>
                        <v-col cols="2">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Target Mode</v-subheader>
                            <v-row class="ma-0 pa-0">
                                <v-switch false-value="D" true-value="P" v-model="model.target_mode" hide-details="auto" class="pt-0 pr-5"
                                    inset :label="`${model.target_mode === 'P' ? 'Percentage' : 'Dollar Amount'}`"
                                ></v-switch>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class="my-2 mb-5"/>
                    <v-row justify="end">
                        <v-btn type="submit" color="secondary" class="font-weight-bold mr-4" :disabled="themeLoading">
                            Save
                        </v-btn>
                    </v-row>
                </v-form>
            </v-card-text>
        </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
    metaInfo: {
        title: "Membership Page Theme",
    },
    data: (vm) => ({
        show: false,
        imageError: {
            bgDeskErr: false,
            bgMobErr: false,
            metaErr: false,
            sizeErr: false
        },
        images: {
            donate_bg_image: null,
            donate_bg_mobile_image: null,
            meta_image: null
        },
        donate_bg_imageLoading: false,
        donate_bg_mobile_imageLoading: false,
        meta_imageLoading: false,
        themeLoading: false,
        model: {
            sf_campaign_id: '',
            title: '',
            heading: '',
            description: '',
            donate_bg_image: null,
            donate_bg_mobile_image: null,
            thank_you_banner: "",
            meta_title: "",
            meta_descripition: "",
            meta_image: null,
            meta_image_use_default: 1,
            show_like_button: "On",
            show_phone: "On",
            show_target: "On",
            target_mode: "P",
        }
    }),
    validations: {
        model: {
            title: { required},
            sf_campaign_id: { required}
        }
    },
    computed: {
        ...mapGetters(["campaignLists", "campaignListLoading"]),
    },
    created() {
        this.getThemeData();
        this.getCampaignLists();
    },
    methods: {
        ...mapActions(['getCampaignLists', 'getOptionsData', 'updatesOptionsData',
            'uploadImages', 'deleteImages']),

        getThemeData() {
            this.themeLoading = true;
            const reqData = {
                name: 'MEMBERSHIP_THEME',
                value: {}
            }
            this.getOptionsData(reqData).then((response) => {
                const newValue = response.data.data.option_value || null;
                if (newValue) {
                    const tempModel = newValue;
                    Object.keys(this.images).map(key => {
                        this.images[key] = newValue[key];
                        delete tempModel[key];
                    });
                    this.model = {...this.model, ...tempModel};
                }
                this.themeLoading = false;
            }).catch(err => this.themeLoading = false);
        },
        saveTheme() {
            this.$v.$touch();
            if (this.$v.$invalid || this.imageError.bgDeskErr || this.imageError.bgMobErr || this.imageError.metaErr) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            this.updateThemeData();
        },
        updateThemeData() {
            this.themeLoading = true;
            Object.keys(this.images).map(key => this.model[key] = this.images[key]);
              const reqData = {
                value: this.model,
                name: 'MEMBERSHIP_THEME'
            }
            this.updatesOptionsData(reqData).then(resp => {
                if (!resp || !resp.data) {
                    return;
                }
                this.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Membership Theme Updated Successfully",
                    color: "success",
                });
                this.themeLoading = false;
            }).catch(err => this.themeLoading = false);
        },
        imageUploaded(event, field, var_name) {
		    const acceptType = ['jpg', 'jpeg', 'png'],
                fileName = event ? (event.name.slice(event.name.lastIndexOf('.')+1)).toLowerCase() : null;
            if (fileName && acceptType.indexOf(fileName) === -1) {
                 this.imageError[field] = true;
            } else {
                if (event.size > 8388608) {
                    this.imageError[field] = this.imageError.sizeErr = true;
                    return;
                }
                this.imageError[field] = this.imageError.sizeErr = false;
                this.uploadedFile(event, var_name);
            }
        },
        uploadedFile(event, var_name) {
            this[`${var_name}Load`] = true;
            const reqData = new FormData(),
                headers =  { 'Content-Type': 'multipart/form-data' };
            reqData.append('file', event);
            this.uploadImages(reqData, headers).then((response) => {
                this.deleteSaveImg(var_name);
                const changeModel = {};
                this.images[var_name] = response.data.url;
                changeModel[var_name] = null;
                this[`${var_name}Load`] = false;
                this.model = Object.assign({}, this.model, changeModel);
            }).catch((err) => {
                this[`${var_name}Load`] = false;
                this.model[var_name] = null;
            });
        },
        deleteSaveImg(fieldName) {
            if (this.images[fieldName]) {
                const reqData = {
                    file: this.images[fieldName]
                }
                this.deleteImages(reqData)
            }

        }
    }
};
</script>
