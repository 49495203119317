import http from '@/http';

export default {
    state: {
        stripIntentLoading: false,
    },
    getters: {
        stripLoading: state => state.stripIntentLoading,
    },
    mutations: {
        setStripLoading(state, data) {
            state.stripIntentLoading = data;
        },
    },
    actions: {
        getDonatSubBal({ commit }, subId) {
            return new Promise((res, rej) => {
                http('stripe').get(`subscriptions/${subId}?columns=balance`).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        createPaymentIndent({ commit }, data) {
            return new Promise((res, rej) => {
                http('stripe').post(`paymentmethods/payment_intent`, data).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        createSubscription({ commit }, data) {
            return new Promise((res, rej) => {
                http('stripe').post(`subscriptions`, data).then(response => {
                    res(response.data.data);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
    },
};
