export default {
    state: {
        modalLoading: false
    },
    getters: {
        modalLoading: state => state.modalLoading,
    },
    mutations: {
        setModalLoading(state, data) {
            state.loadingDT = data;
        },
    }
};
