import http from '@/http';
import { saleForce } from './sub-modules'

export default {
    modules: {
        saleForce
    },
    state: {
        selectedAppSettingModel: {},
        selectAppSettingLoading: false
    },
    getters: {
        selectedAppSettingModel: state => state.selectedAppSettingModel,
        selectAppSettingLoading: state => state.selectAppSettingLoading,
    },
    mutations: {
        setSelectAppSettingData(state, data) {
            state.selectedAppSettingModel = data;
            state.selectAppSettingLoading = false;
        },
        setSelectAppSettingLoading(state, data) {
            state.selectAppSettingLoading = data;
        },
    },
    actions: {
        getSelectAppSettingData({ commit }, settingId) {
            commit("setSelectAppSettingLoading", true);
            return new Promise((res, rej) => {
                http('donate').get(`options/${settingId}`).then(response => {
                    commit("setSelectAppSettingData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectAppSettingLoading", false);
                    rej(error)
                });
            });
        },
    }
};
