<template>
    <datatable ref="dtComponent" :modelData="dtData" @deleteItem="deleteIncentiveItem"
      @activeHandled="incentiveActiveUpdate" activeFieldName="active"/>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Incentives",
  metaInfo: {
    title: "Incentives",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
  },
  data() {
    return {
      dtData: {
        title: 'Incentives',
        headers: [
            { text: 'SF Incentives', align: 'start', value: 'sf_incentive'},
            { text: 'Name', value: 'name'},
            { text: "Threshold Once", value: "threshold_once" },
            { text: "Threshold Monthly", value: "threshold_monthly" },
            { text: "Active", value: "active", sortable: false},
            {text: 'Action', value: 'action', sortable: false}
        ],
        activeIcon: 'mdi-star',
        actions: ['edit', 'delete'],
        headerActions: ['create', 'reset'],
        createLink: '/incentives/create-incentive',
        editLink: '/incentives/edit-incentive/',
        pageLink: `${window.VUE_APP_MS_URL}donate/incentives?page=1`
      }
    };
  },
  computed: {
    ...mapGetters(["metaInfoDT", "activeLink", "dtLists", "searchDT"])
  },
  methods: {
    ...mapActions(["deleteIncentive", "updateIncentiveActive"]),

    deleteIncentiveItem(item) {
      let pageLink = new URL(this.activeLink);
      if (this.dtLists.length === 1 || !this.activeLink) {
        pageLink = new URL(this.dtData.pageLink);
        if (this.searchDT !== '') {
          pageLink.searchParams.set("q", this.searchDT);
        }
        pageLink.searchParams.set("page", 1);
      }
      this.deleteIncentive(item.id).then(() => this.$refs.dtComponent.pageLoading(pageLink));
    },
    incentiveActiveUpdate(incentiveId) {
      this.updateIncentiveActive(incentiveId).then((res) => {
        let listsDT = this.$refs.dtComponent.dtLists;
        if(listsDT.length) listsDT = listsDT.map(item => {
          if (item.id == incentiveId) {
            item.active = 1;
            return item;
          }
          item.active = 0;
          return item;
        })
        this.$refs.dtComponent.dtLists = listsDT || [];
      });
    }
  },
};
</script>
