<template>
    <v-row id="last-3hours-summary-page" class="ma-0 pa-0">
        <v-col cols="12">
            <OnAir />
            <OffAir />
            <Major />
            <AllDonations />
        </v-col>
    </v-row>
  
</template>

<script>
import { mapActions } from "vuex";

export default {
  metaInfo: {
    title: "Hourly Summary(Last 3hrs)",
  },
  components: {
    OnAir : () => import("@/pages/reports/last-3hours-summary/components/OnAirSummary"),
    OffAir : () => import("@/pages/reports/last-3hours-summary/components/OffAirSummary"),
    Major : () => import("@/pages/reports/last-3hours-summary/components/MajorSummary"),
    AllDonations : () => import("@/pages/reports/last-3hours-summary/components/AllDonateSummary"),
  },
  created() {
      this.getHourlySummary('?sd=3hour');
  },
  methods: {
    ...mapActions(["getHourlySummary"]) 
  }
}
</script>
