<template>
  <v-row id="update-profile-page">
    <v-col cols="12">
      <base-card>
        <v-row class="ma-0 py-3" align="center" justify="center">
          <v-card-title class="text-h5 primary--text font-weight-bold d-inline-block pa-0 title-border">
            Update Profile
          </v-card-title>
        </v-row>
        <v-card-text>
          <v-form lazy-validation @submit.prevent="saveProfile">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">First Name</v-subheader>
                <v-text-field
                  outlined ref="first_name" hide-details="auto" v-model="model.first_name"
                  :error-messages="$helpers.errorMsg('name', $v.model.first_name, 'First Name')"
                  placeholder="First Name" @input="$v.model.first_name.$touch()"
                  @blur="$v.model.first_name.$touch()" required/>
              </v-col>
              <v-col cols="12" class="col-lg-6">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Last Name</v-subheader>
                <v-text-field
                  outlined ref="last_name" hide-details="auto" v-model="model.last_name"
                  :error-messages="$helpers.errorMsg('name', $v.model.last_name, 'Last Name')"
                  placeholder="Last Name" @input="$v.model.last_name.$touch()"
                  @blur="$v.model.last_name.$touch()" required/>
              </v-col>
            </v-row>

            <v-col cols="12">
              <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">E-mail</v-subheader>
              <v-text-field
                outlined v-model="model.email" hide-details="auto"
                :error-messages="$helpers.errorMsg('email', $v.model.email, 'Email')" placeholder="E-mail"
                ref="email" @input="$v.model.email.$touch()" @blur="$v.model.email.$touch()" required/>
            </v-col>

            <v-col cols="12">
              <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Password</v-subheader>
              <v-text-field
                outlined ref="password" hide-details="auto" :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" v-model="model.password"
                :error-messages="$helpers.errorMsg('name', $v.model.password, 'Password')"
                placeholder="Password" @click:append="show = !show" required/>
            </v-col>

            <div class="d-block d-lg-flex justify-end my-3">
              <v-btn type="submit" color="secondary" :disabled="profileLoading">update profile</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </v-col>

    <v-col cols="12">
      <base-card>
        <v-row class="ma-0 py-3" align="center" justify="center">
          <v-card-title class="text-h5 primary--text font-weight-bold d-inline-block pa-0 title-border">
            Google Authenticator (2FA)
          </v-card-title>
        </v-row>
        <v-card-text>
          <v-form lazy-validation v-if="google2fa.formState === 'setup'" @submit.prevent="google2faGenerate()">
            <div class="d-block d-lg-flex justify-center my-3">
              <v-btn type="submit" color="secondary" :loading="google2faLoading">
                Set up Authentication App
              </v-btn>
            </div>
          </v-form>
          <v-form lazy-validation v-if="google2fa.formState === 'register'" @submit.prevent="google2faRegister()">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="col-lg-12 text-center">
                <p>Set up your two factor authentication by scanning the barcode below. Alternatively, you can use the code <strong>{{ google2fa.secret }}</strong></p>
              </v-col>
              <v-col cols="12" class="col-lg-4 mx-auto">
                <v-img :src="google2fa.imgSrc"></v-img>
              </v-col>
              <v-col cols="12" class="col-lg-12 text-center">
                <p>You must set up your Google Authenticator app before continuing. You will be unable to login otherwise.</p>
              </v-col>
            </v-row>

            <div class="d-block d-lg-flex justify-center my-3">
              <v-btn type="submit" color="secondary" :loading="google2faLoading">Activate 2FA</v-btn>
            </div>
          </v-form>

          <v-form lazy-validation v-if="google2fa.formState === 'verify'" @submit.prevent="google2faConfigure()">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="col-lg-12 text-center">
                <p>
                  Please enter the <strong>OTP</strong> generated on your Authenticator App. <br>
                  Ensure you submit the current one because it refreshes every 30 seconds.
                </p>
              </v-col>
              <v-col cols="12" class="col-lg-12">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">One Time Password</v-subheader>
                <v-text-field
                  class="col-lg-6" outlined hide-details="auto" v-model="google2fa.otp"
                  :error-messages="$helpers.errorMsg('otp', $v.google2fa.otp, 'OTP')" placeholder="OTP"
                  @input="$v.google2fa.otp.$touch()" @blur="$v.google2fa.otp.$touch()" required/>
              </v-col>
            </v-row>

            <div class="d-block d-lg-flex justify-center my-3">
              <v-btn type="submit" color="secondary" :loading="google2faLoading">Verify & Activate</v-btn>
            </div>
          </v-form>

          <v-form lazy-validation v-if="google2fa.formState === 'deactivate'" @submit.prevent="google2faConfigure()">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="col-lg-12 text-center">
                <p>
                  Please enter the <strong>OTP</strong> generated on your Authenticator App. <br>
                  Ensure you submit the current one because it refreshes every 30 seconds.
                </p>
              </v-col>
              <v-col cols="12" class="col-lg-12">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">One Time Password</v-subheader>
                <v-text-field
                  class="col-lg-6" outlined ref="otp" hide-details="auto"
                  v-model="google2fa.otp"
                  :error-messages="$helpers.errorMsg('otp', $v.google2fa.otp, 'OTP')" placeholder="OTP"
                  @input="$v.google2fa.otp.$touch()" @blur="$v.google2fa.otp.$touch()" required/>
              </v-col>
            </v-row>

            <div class="d-block d-lg-flex justify-center my-3">
              <v-btn type="submit" color="secondary" :loading="google2faLoading">Verify and Deactivate</v-btn>
            </div>
          </v-form>

          <v-form lazy-validation v-if="google2fa.formState === 'active'" @submit.prevent="google2fa.formState = 'deactivate';">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="col-lg-12 text-center">
                <p>Your two-factor authentication (2FA) is now active.</p>
              </v-col>
              <v-col cols="12" class="col-lg-12">
                <div class="d-block d-lg-flex justify-center my-3">
                  <v-btn type="submit" color="secondary" :loading="google2faLoading">Deactivate 2FA</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {email, required} from "vuelidate/lib/validators";

export default {
  name: "updateProfile",
  metaInfo: {
    title: "Update Profile",
  },
  data() {
    return {
      model: {},
      show: false,
      google2fa: {
        status: 0,
        imgSrc: "",
        formState: 'setup', // possible values: 'setup', 'register', 'verify', 'active', 'deactivate'
        secret: "",
        otp: "",
      },
      google2faLoading: false,
    };
  },
  validations() {
    return {
      model: {
        first_name: { required },
        last_name: { required },
        email: { email, required },
        password: { required },
      },
      google2fa: {
        otp: {
          required,
          isNumeric(value) {
            return /^[0-9]+$/.test(value);
          },
          exactLength(value) {
            return value.length === 6;
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["profileUser", "profileLoading"]),
  },
  watch: {
    profileUser(newValue) {
      this.model = { ...this.model, ...newValue };
      this.checkGoogle2faEnabled();
    },
  },
  created() {
    this.getAuthUser();
  },
  methods: {
    ...mapActions([
      "getAuthUser",
      "updateUserData",
      "generateGoogle2fa",
      "toggleGoogle2faStatus",
    ]),
    saveProfile() {
      this.$v.model.$touch();
      if (this.$v.model.$invalid) {
        this.$helpers.focusErrorElement(this.$v.model, this.$refs);
        return;
      }
      this.updateUserData(this.model)
        .then(response => {
          if (!response || !response.data) {
            return;
          }
          this.$store.commit("snackbar/SHOW_MESSAGE", {
            text: "Profile Updated Successfully",
            color: "success",
          });
        })
        .catch((err) => console.log(err));
    },
    google2faGenerate() {
      this.google2faLoading = true;
      this.generateGoogle2fa(this.model)
        .then(response => {
          if (!response || !response.data) {
            return;
          }
          if (response.data.qr) {
            this.google2fa.formState = 'register';
            const svgDataUrl = 'data:image/svg+xml;base64,' + btoa(response.data.qr);
            this.google2fa.imgSrc = svgDataUrl;
            this.google2fa.secret = response.data.secret;
          }
        })
        .catch((err) => console.log(err.message))
        .finally(() => this.google2faLoading = false);
    },
    google2faRegister() {
      if (this.google2fa.secret) {
        this.google2fa.formState = 'verify';
      }
    },
    google2faConfigure() {
      this.$v.google2fa.otp.$touch();
      if (this.$v.google2fa.$invalid) {
        return;
      }

      this.google2faLoading = true;

      this.toggleGoogle2faStatus({ one_time_password: this.google2fa.otp })
        .then(response => {
          if (!response || !response.data) {
            return;
          }

          let formState = 'active'
          let message = 'Activated successfully'

          if (this.google2fa.formState === 'deactivate') {
            formState = 'setup';
            message = 'Deactivated Successfully'
          }

          this.$store.commit("snackbar/SHOW_MESSAGE", {
            text: message,
            color: "success",
          });

          this.google2fa.formState = formState;
          this.google2fa.otp = '';
        })
        .catch((err) => console.log(err.message))
        .finally(() => this.google2faLoading = false);
    },
    checkGoogle2faEnabled() {
      if (this.model.google2fa_status) {
        this.google2fa.formState = 'active';
      }
    }
  },
};
</script>
