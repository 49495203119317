<template>
  <div id="sync-failed-txns-page">
    <datatable ref="dtComponent" :modelData="dtData" />
    </div>
</template>

<script>
export default {
  name: "SyncFailedTxns",
  metaInfo: {
    title: "Sync Failed Transactions",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
  },
  data() {
    return {
      dtData: {
        title: 'Sync Failed Transactions',
        headers: [
            { text: 'SF Contact ID', align: 'start', value: 'htmlelement', field: 'donation.contact_sf_id', link: window.VUE_APP_SF_DOMAIN},
            { text: "SF Opportunity ID", value: 'htmlelement', field: 'donation.sf_id', link: window.VUE_APP_SF_DOMAIN },
            { text: "SF Txn ID", value: 'htmlelement', field: 'sf_id', link: window.VUE_APP_SF_DOMAIN },
            { text: "SF Sync Error", value: "sf_response" },
            { text: "Donor", value: "donation.donor_name" },
            { text: "Email", value: "donation.email" },
            { text: "Suburb", value: "donation.donor_suburb" },
            { text: "Amount", value: "donation.amount" },
            { text: "Mode", value: "donation.frequency_label" },
            { text: "Source", value: "donation.source" },
            { text: "Transaction Date/Time", value: 'datefield', field: "charged_at" },
            { text: "Volunteer", value: "donation.volunteer" },
            { text: "Target", value: "donation.target" },
            { text: "Campaign", value: "donation.campaign" },
            { text: "Payout ID", value: "payout_id" },
        ],
        actions: [],
        headerActions: ['reset'],
        pageLink: `${window.VUE_APP_MS_URL}donate/transactions?st=0&columns=donation&page=1`
      }
    }
  }

}
</script>
