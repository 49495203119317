<template>
    <datatable ref="dtComponent" :modelData="dtData" @deleteItem="deleteCampaignItem"
      :combineData="{value:['start', 'end'], dateFormat: true, join: ' - '}"  @activeHandled="campaignActiveUpdate"
      activeFieldName="is_active"/>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Campaigns",
  metaInfo: {
    title: "Campaigns",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
  },
  data() {
    return {
      dtData: {
        title: 'Campaigns',
        headers: [
            { text: 'Campaign Once', align: 'start', value: 'sf_campaign_once_name'},
            { text: "Campaign Monthly", value: "sf_campaign_monthly_name" },
            { text: 'State Date - End Date', align: 'start', value: 'combine'},
            { text: "Active", value: "active", sortable: false},
            {text: 'Action', value: 'action', sortable: false}
        ],
        actions: ['edit', 'delete'],
        activeIcon: 'mdi-star',
        headerActions: ['create', 'reset'],
        createLink: '/campaigns/create-campaign',
        editLink: '/campaigns/edit-campaign/',
        pageLink: `${window.VUE_APP_MS_URL}donate/campaigns?page=1`
      }
    };
  },
  computed: {
    ...mapGetters(["metaInfoDT", "activeLink", "dtLists", "searchDT"])
  },
  methods: {
    ...mapActions(["deleteCampaign", "updateCampaignData"]),

    deleteCampaignItem(item) {
      let pageLink = new URL(this.activeLink);
      if (this.dtLists.length === 1 || !this.activeLink) {
        pageLink = new URL(this.dtData.pageLink);
        if (this.searchDT !== '') {
          pageLink.searchParams.set("q", this.searchDT);
        }
        pageLink.searchParams.set("page", 1);
      }
      this.deleteCampaign(item.id).then(() => this.$refs.dtComponent.pageLoading(pageLink));
    },
    campaignActiveUpdate(campaignId) {
      this.$store.commit("setItemActiveLoading", true);
      const reqData = {id: campaignId, is_active: 1};
      this.updateCampaignData(reqData).then((res) => {
        let listsDT = this.$refs.dtComponent.dtLists;
        if(listsDT.length) listsDT = listsDT.map(item => {
          if (item.id == campaignId) {
            item.is_active = 1;
            return item;
          }
          item.is_active = 0;
          return item;
        })
        this.$refs.dtComponent.dtLists = listsDT || [];
        this.$store.commit("setItemActiveLoading", false);
      }).catch(err => this.$store.commit("setItemActiveLoading", false));
    }
  },
};
</script>
