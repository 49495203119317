import http from '@/http';

export default {
    state: {
        storySearchLoading: false,
        slackHistoryLoading: false,
        slackHistoryLists: {},
    },
    getters: {
        storySearchLoading: state => state.storySearchLoading,
        slackHistoryLoading: state => state.slackHistoryLoading,
        slackHistoryLists: state => state.slackHistoryLists
    },
    mutations: {
        setStorySearchLoading(state, data) {
            state.storySearchLoading = data;
        },
        setSlackHistoryLoading(state, data) {
            state.slackHistoryLoading = data;
        },
        setSlackHistoryLists(state, data) {
            state.slackHistoryLists = data;
            state.slackHistoryLoading = false;
        },
    },
    actions: {
        getStoryList({ commit }, endPoint) {
            return new Promise((res, rej) => {
                http('donate').get(`/stories?${endPoint}`).then(response => {
                    const resp = response.data.data || null;
                    res(resp);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        storySearch({ commit }, search) {
            commit("setStorySearchLoading", true);
            return new Promise((res, rej) => {
                http('donate').post(`stories/search?onair=${search}`).then(response => {
                    commit("setStorySearchLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setStorySearchLoading", false);
                    rej(error)
                });
            });
        },
        sendToSlack({ commit }, storyId) {
            return new Promise((res, rej) => {
                http('donate').get(`/stories/${storyId}/send`).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Story Send Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        updateStoryMention({ commit }, storyId) {
            return new Promise((res, rej) => {
                http('donate').get(`/stories/${storyId}/mention`).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Story Mentioned Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        getSlackHistory({ commit }, slackData) {
            commit("setSlackHistoryLoading", true);
            return new Promise((res, rej) => {
                http('email').post('slack/history', slackData).then(response => {
                    commit("setSlackHistoryLists", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSlackHistoryLoading", false);
                    rej(error)
                });
            });
        }
    },
};
