import http from '@/http';

export default {
    state: {
        selectedIncentive: {},
        selectIncentiveLoading: false,
        latestIncentiveLoading: false,
        currentIncentive: null
    },
    getters: {
        getSelectedIncentive: state => state.selectedIncentive,
        selectIncentiveLoading: state => state.selectIncentiveLoading,
        latestIncentiveLoading: state => state.latestIncentiveLoading,
        currentIncentive: state => state.currentIncentive
    },
    mutations: {
        setSelectIncentiveData(state, data) {
            state.selectedIncentive = data;
            state.selectIncentiveLoading = false;
        },
        setSelectIncentiveLoading(state, data) {
            state.selectIncentiveLoading = data;
        },
        setLatestIncentiveLoading(state, data) {
            state.latestIncentiveLoading = data;
        },
        setCurrentIncentive(state, data) {
            state.currentIncentive = data;
            state.latestIncentiveLoading = false;
        },
    },
    actions: {
        getLatestIncentive({ commit }, params) {
            let endPoint = 'incentives/active';
            if( typeof params !== 'undefined' && Object.keys(params).length > 0){
                endPoint += '?' + new URLSearchParams(params).toString();
            }

            commit("setLatestIncentiveLoading", true);
            return new Promise((res, rej) => {
                http('donate').get(endPoint).then(response => {
                    commit("setCurrentIncentive", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setLatestIncentiveLoading", false);
                    rej(error)
                });
            });
        },
        getIncentiveData({ commit }, incentiveId) {
            commit("setSelectIncentiveLoading", true);
            return new Promise((res, rej) => {
                http('donate').get(`incentives/${incentiveId}`).then(response => {
                    commit("setSelectIncentiveData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectIncentiveLoading", false);
                    rej(error)
                });
            });
        },
        createIncentive({ commit }, incentiveData) {
            commit("setSelectIncentiveLoading", true);
            return new Promise((res, rej) => {
                http('donate').post('incentives', incentiveData).then(response => {
                    commit("setSelectIncentiveLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setSelectIncentiveLoading", false);
                    rej(error)
                });
            });
        },
        updateIncentiveData({ commit }, incentiveData) {
            commit("setSelectIncentiveLoading", true);
            return new Promise((res, rej) => {
                http('donate').put(`incentives/${incentiveData.id}`, incentiveData).then(response => {
                    commit("setSelectIncentiveData", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setSelectIncentiveLoading", false);
                    rej(error)
                });
            });
        },
        deleteIncentive({ commit }, incentiveId) {
            return new Promise((res, rej) => {
                http('donate').deleteReq(`incentives/${incentiveId}`).then(response => {
                    commit("snackbar/SHOW_MESSAGE", {
                        text: "Incentive Deleted Successfully",
                        color: "success",
                    });
                    res(response);
                }).catch((error) => {
                    rej(error)
                });
            });
        },
        updateIncentiveActive({ commit }, incentiveId) {
            return new Promise((res, rej) => {
                commit("setItemActiveLoading", true);
                http('donate').get(`incentives/${incentiveId}/activate`).then(response => {
                    commit("setItemActiveLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setItemActiveLoading", false);
                    rej(error)
                });
            });
        },
    },

};
