<template>
    <div id="stories-page">
        <datatable ref="dtComponent" :modelData="dtData"
            @toggleField="updateItem"
            @customAction="sendMsgToSlack($event.item.id)">
            <template v-slot:topsection>
                <v-row class="ma-0 pa-3" align="center" justify="end">
                    <div>
                        <v-chip color="warning" label class="rounded-r-0"
                            @click="clearAllFavourite('Web')">
                            Clear All <span class="mdi mdi-star-outline"></span>
                        </v-chip>
                        <v-chip color="primary" label class="rounded-0" :outlined="filterTable!=='Favourite'"
                            @click="filterStories('Favourite')">
                            Favourites
                        </v-chip>
                        <v-chip color="primary" label class="rounded-l-0 me-3" :outlined="filterTable!=='All'"
                            @click="filterStories('All')">
                            All
                        </v-chip>
                        <v-chip color="primary" label class="rounded-r-0" :outlined="selectedTable !== 'CC'"
                            @click="refreshTable('CC')">
                            Call Centre
                        </v-chip>
                        <v-chip color="primary" label class="rounded-l-0" :outlined="selectedTable !== 'Web'"
                            @click="refreshTable('Web')">
                            Web Form
                        </v-chip>
                    </div>
                </v-row>
            </template>
        </datatable>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: "Stories",
    metaInfo: {
        title: "Stories",
    },
    components: {
        Datatable: () => import("@/components/common/datatable"),
    },
    data() {
        return {
            dtData: {
                title: 'Stories',
                headers: [
                    { text: 'SF ID (Opportunity)', align: 'start', value: 'htmlelement', field: 'donation_sf_id', link: window.VUE_APP_SF_DOMAIN },
                    { text: "Date", value: "created_at" },
                    { text: "Name", value: "name" },
                    { text: "Suburb", value: "suburb" },
                    { text: "Phone", value: "phone" },
                    { text: "Amount", value: "amount" },
                    { text: "Story", value: "story_details" },
                    { text: 'Major Donor', value: 'boolean', field: 'major_donor', trueValue: 'Yes', falseValue: '-', sortable: false },
                    { text: "Favourite", value: 'toggle', type: 'star', field: "favourite", sortable: false },
                    { text: 'Action', value: 'action', sortable: false }
                ],
                actions: [],
                headerActions: ['reset'],

                customButtonActions: [{
                    icon: 'mdi-slack',
                    tooltipName: 'Send to Slack',
                    name: 'Send to Slack',
                    color: 'secondary',
                    btnSmall: true
                }],
                pageLink: `${window.VUE_APP_MS_URL}donate/stories?donation_source=Web Donation Form&page=1`
            },
            selectedTable: 'Web',
            filterTable: 'All',

        };
    },
    methods: {
        ...mapActions(["sendToSlack", "updateStory", "clearFavouriteStory"]),

        refreshTable(data) {
            this.selectedTable = data;
            this.dtData.title = data === 'CC' ? 'Stories' : 'Web Form Stories';
            const baseUrl = `${window.VUE_APP_MS_URL}donate/stories?page=1&donation_source=`;
            this.dtData.pageLink = data === 'CC' ? `${baseUrl}Call Centre` : `${baseUrl}Web Donation Form`;
            if(this.filterTable === 'Favourite') {
              this.dtData.pageLink += '&favourite=1'
            }
            this.$refs.dtComponent.pageLoading(this.dtData.pageLink);
        },

      filterStories(mode) {
          this.filterTable = mode
          this.refreshTable(this.selectedTable)
        },

        sendMsgToSlack(itemId) {
            const itemIndex = this.$refs.dtComponent.dtLists.findIndex(item => item.id === itemId);
            this.$refs.dtComponent.dtLists[itemIndex].customLoading = true;
            this.sendToSlack(itemId).then(resp => {
                const list = { ...this.$refs.dtComponent.dtLists[itemIndex], ...{ customLoading: false } };
                this.$refs.dtComponent.dtLists.splice(itemIndex, 1, list);
            }).catch(err => {
                const list = { ...this.$refs.dtComponent.dtLists[itemIndex], ...{ customLoading: false } };
                this.$refs.dtComponent.dtLists.splice(itemIndex, 1, list);
            });
        },
        updateItem({item, index}) {
            item.toggleLoading = true;
            this.$refs.dtComponent.dtLists.splice(index, 1, item);
            item.favourite = +!item.favourite;
            this.updateStory({id: item.id, favourite: item.favourite}).then((res) => {
              this.$store.commit("snackbar/SHOW_MESSAGE", {
                text: "Favourite Updated Successfully",
                color: "success",
              });
              item.toggleLoading = false;
              item.favourite = +!item.favourite;
              this.$refs.dtComponent.dtLists.splice(index, 1, item);
              this.$refs.dtComponent.pageLoading(activeUrl);
            }).catch(() => {
              item.toggleLoading = false;
              item.favourite = +!item.favourite;
              this.$refs.dtComponent.dtLists.splice(index, 1, item);
            });
        },
        clearAllFavourite() {
            const activeUrl = this.activeLink ? this.activeLink : this.dtData.pageLink;
            let urlObj = new URL(activeUrl);
            urlObj.searchParams.delete('favourite');

            const dtUrl = urlObj.toString();
            this.filterTable = 'All';

            this.clearFavouriteStory().then(() => {
                this.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "Clear All Favourite Successfully",
                    color: "success",
                });
                this.$refs.dtComponent.pageLoading(dtUrl);
            }).catch(() => {
            });
        }
    }


}
</script>
