<template>
    <div id="sync-failed-donations-page">
        <AdvanceFilter :clearAdvFilter="clearAdvFilter" @reset="resetFilter" @search="searchAdvFilter"/>
        <datatable ref="dtComponent" :modelData="dtData" @clearFilter="clearAdvFilter=true;searchLink = searchData = null"
          :searchModel="searchData"/>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "SyncFailedDonations",
  metaInfo: {
    title: "Sync Failed Donations",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
    AdvanceFilter: () => import("@/pages/reports/donations/components/AdvanceFilter"),
  },
  data() {
    return {
        clearAdvFilter: false,
        dtData: {
          title: 'Sync Failed Donations',
          headers: [
              { text: 'SF Contact ID', align: 'start', value: 'htmlelement', field: 'contact_sf_id', link: window.VUE_APP_SF_DOMAIN},
              { text: "SF Sync Error", value: "failed_reason" },
              { text: "Donor", value: "donor_name" },
              { text: "Email", value: "email" },
              { text: "Suburb", value: "donor_suburb" },
              { text: "Amount", value: "amount" },
              { text: "Mode", value: "frequency_label" },
              { text: "Type", value: "payment_type_label" },
              { text: "Source", value: "source" },
              { text: "Donation Date/Time", value: "created_at" },
          ],
          actions: [],
          headerActions: ['reset'],
          pageLink: `${window.VUE_APP_MS_URL}donate/donates?st=0&page=1`
        },
        searchLink: null,
        searchData: null
    };
  },
  computed: {
    ...mapGetters(["metaInfoDT", "activeLink"]),
  },
  methods: {
    ...mapActions(['pageNavigation', 'updateDonate']),

    resetFilter() {
        this.searchLink = this.searchData = null;
        this.$refs.dtComponent.searchDT = '';
        this.$refs.dtComponent.pageLoading(this.dtData.pageLink, true);
    },
    searchAdvFilter(searchModel) {
        this.searchLink = this.$helpers.setURLparams(searchModel, this.dtData.pageLink);
        this.clearAdvFilter = false;
        this.searchData = searchModel;
        this.$refs.dtComponent.searchDT = '';
        this.$refs.dtComponent.pageLoading(this.searchLink, true);
    }
  }
};
</script>
