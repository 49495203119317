<template>
    <v-row id="common-data-table">
        <v-col cols="12">
            <base-card elevation="5">
                <v-card-title class="pb-0">
                  <v-row align="center" justify="end" v-if="modelData.headerActions && modelData.headerActions.length">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ma-2" :to="modelData.createLink" v-bind="attrs" v-on="on" dark color="primary" v-if="modelData.headerActions.includes('create')">
                            <v-icon>mdi-plus</v-icon>
                            Create
                        </v-btn>
                      </template>
                      <span>Create {{ modelData.title.substring(0, modelData.title.length - 1) }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ma-2" @click="refreshPage()" fab dark small v-bind="attrs" v-on="on" color="info" v-if="modelData.headerActions.includes('reset')">
                          <v-icon dark>
                            mdi-refresh
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Reset</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :loading="exportLoading" class="ma-2 font-weight-bold" @click="$emit('export')" dark v-bind="attrs" v-on="on" color="danger" v-if="modelData.headerActions.includes('export')">
                          Export
                          <v-icon dark class="pl-1">
                            mdi-download
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>File Download</span>
                    </v-tooltip>
                  </v-row>
                </v-card-title>
                <v-card-title class="font-weight-bold" v-if="!modelData.searchDisable || modelData.title">
                  {{ modelData.title }}
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>

                  <v-text-field autofocus v-if="!modelData.searchDisable"
                    class="font-weight-regular"
                    v-model.trim="searchDT"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    :disabled="loading"
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-skeleton-loader v-if="loading" :loading="loading" type="table-tbody, table-tfoot"></v-skeleton-loader>
                <v-data-table v-if="!loading" :search="searchDT" v-model="selected"
                    :headers="modelData.headers"
                    :items="dtLists"
                    item-key="id"
                    :items-per-page="10"
                  class="elevation-1 table-one"
                >
                  <template v-slot:[`item.active`]="{item}">
                    <v-tooltip top >
                      <template v-slot:activator="{on, attrs}">
                          <v-btn :loading="activeLoading"
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="$emit('activeHandled', item.id)"
                          >
                            <v-icon>{{ item[activeFieldName] === 1 ? modelData.activeIcon : `${modelData.activeIcon}-outline` }}</v-icon>
                          </v-btn>
                      </template>
                      <span>Active {{modelData.title.substring(0, modelData.title.length - 1)}}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.action`]="{item}">
                    <div class="d-flex">
                      <template v-if="modelData.customButtonActions && modelData.customButtonActions.length" >
                        <v-tooltip :disabled="!customItem.tooltipName" top v-for="(customItem, customIndex) in modelData.customButtonActions" :key="customIndex">
                          <template v-slot:activator="{on, attrs}">
                              <v-btn
                                :color="customItem.color || 'info'"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                :class="customItem.btnClass"
                                :icon="customItem.btnIcon"
                                :small="customItem.btnSmall"
                                @click="$emit('customAction', {button: customItem.name, item: item})"
                              >
                                <v-icon v-if="customItem.icon" class="mr-2">{{customItem.icon}}</v-icon>
                                <template v-if="customItem.name">{{customItem.name}}</template>
                              </v-btn>
                          </template>
                          <span>{{ customItem.tooltipName }}</span>
                        </v-tooltip>
                      </template>
                      <v-tooltip top  v-if="modelData.actions && modelData.actions.includes('edit')">
                        <template v-slot:activator="{on, attrs}">
                            <v-btn
                              color="secondary"
                              dark
                              :to="modelData.editLink+item.id"
                              v-bind="attrs"
                              v-on="on"
                              icon
                            >
                              <v-icon>mdi-pencil-box-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip top  v-if="modelData.actions && modelData.actions.includes('delete') && (!modelData.deleteActionHide || item[modelData.deleteActionHide.name] !== modelData.deleteActionHide.value)">
                        <template v-slot:activator="{on, attrs}">
                            <v-btn
                              color="danger"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              icon
                              @click="$emit('deleteItem', item)"
                            >
                              <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', item.created_at)}}
                  </template>
                </v-data-table>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';

export default {
  name: "Datatable",
  props: ["modelData", "activeFieldName"],
  emits: ["deleteItem", "clearFilter", "customAction", "activeHandled", 'export'],
  data() {
      return {
          selected: [],
          loading: false,
          dtLists: [],
          searchDT: '',
          exportLoading: false,
      }
  },
  computed: {
    ...mapGetters({
      activeLoading: "itemActiveLoading"
    }),
  },
  created() {
    this.refreshPage();
  },
  methods: {
    ...mapActions(["pageNavigation"]),

    refreshPage() {
      this.pageLoading(this.modelData.pageLink);
      this.$emit('clearFilter');
    },
    pageLoading(link) {
      this.loading = true;
      this.pageNavigation(link).then((data) => {
        this.dtLists = data.data || [];
        this.loading = false;
      }).catch(err => this.loading = false);
    }
  },
}
</script>
