<template>
  <div id="payout-page">
    <AdvanceFilter :clearAdvFilter="clearAdvFilter" @reset="resetFilter" @search="searchAdvFilter"/>
    <datatable ref="dtComponent" :modelData="dtData" @clearFilter="clearAdvFilter=true;searchData = null"
      @customAction="viewLogsModal($event.item)" :searchModel="searchData"/>
    <ViewLogModal :item="selectedItem" :modalOpen="openViewModal" @closeModal="openViewModal=false"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "Payouts",
  metaInfo: {
    title: "Payouts",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
    AdvanceFilter: () => import("@/pages/reports/payouts/components/AdvanceFilter"),
    ViewLogModal: () => import("@/pages/reports/payouts/components/ViewLogModal"),
  },
  data() {
    return {
      openViewModal: false,
      selectedItem: {},
      clearAdvFilter: false,
      dtData: {
        title: 'Payouts',
        expandable: true,
        headers: [
            { text: "", value: "data-table-expand" },
            { text: 'Payout Id', align: 'start', value: 'payout_id'},
            { text: "Payout Date", value: "payout_date" },
            { text: "Amount", value: "amount" },
            { text: "Status", value: "status" },
            {text: 'Reconciles', value: 'boolean', field: 'reconciled', trueValue: 'Yes', falseValue: 'No'},
            {text: 'Action', value: 'action', sortable: false}
        ],
        expandHeaders: [
          {text: 'Donor', value:'donation.donor_name'},
          {text: 'Date', value:'datefield', field:'charged_at'},
          {text: 'Charge ID', value:'stripe_id'},
          {text: 'Fee', value:'fee_amount'},
          {text: 'Amount', value:'amount'},
          {text: 'Net', value:'net_amount'}
        ],
        actions: [],
        customButtonActions:[{
          icon: 'mdi-file-eye-outline',
          tooltipName: 'View Log',
          btnIcon: true
        }],
        headerActions: ['reset', 'expand'],
        pageLink: `${window.VUE_APP_MS_URL}stripe/payouts?page=1`,
        expandDatas: {
          link: `${window.VUE_APP_MS_URL}donate/transactions?columns=donation`,
          field: 'payout_id',
          passField: 'payout_id',
          method: 'get',
        }
      },
      searchData: null
    };
  },
  computed: {
    ...mapGetters(["activeLink"]),
  },
  methods: {
    resetFilter() {
        this.searchData = null;
        this.$refs.dtComponent.searchDT = '';
        this.$refs.dtComponent.pageLoading(this.dtData.pageLink, true);
    },
    searchAdvFilter(searchModel) {
        const pageLink = this.$helpers.setURLparams(searchModel, this.dtData.pageLink);
        this.clearAdvFilter = false;
        this.searchData = searchModel;
        this.$refs.dtComponent.searchDT = '';
        this.$refs.dtComponent.pageLoading(pageLink, true);
    },
    viewLogsModal(item) {
      this.selectedItem = item.unsynced_charges || [];
      this.openViewModal = true;
    }
  }
};
</script>
