import http from '@/http';

export default {
    state: {
        pickListLoading: false,
        followUpModel: {
            description: '',
            type: null,
            donation_id: null
        },
        followUpLoading: false,
        searchPickLsLoading: false
    },
    getters: {
        followUpModel: state => state.followUpModel,
        followUpLoading: state => state.followUpLoading,
        picklistLoading: state => state.pickListLoading,
        searchPickLsLoading: state => state.searchPickLsLoading,
    },
    mutations: {
        setPickListLoading(state, data) {
            state.pickListLoading = data;
        },
        setSearchPickLsLoading(state, data) {
            state.searchPickLsLoading = data;
        },
        setFollowUpModel(state, data) {
            state.followUpModel = data;
        },
        setFollowUpLoading(state, data) {
            state.followUpLoading = data;
        }
    },
    actions: {
        getPicklist({ commit }, reqData) {
            commit("setPickListLoading", true);
            return new Promise((res, rej) => {
                http('salesforce').post('picklist', reqData).then(response => {
                    commit("setPickListLoading", false);
                    res(response.data.data);
                }).catch((error) => {
                    commit("setPickListLoading", false);
                    rej(error)
                });
            });
        },
        getSearchPicklist({ commit }, reqData) {
            commit("setSearchPickLsLoading", true);
            return new Promise((res, rej) => {
                http('salesforce').post('picklist/search', reqData).then(response => {
                    commit("setSearchPickLsLoading", false);
                    res(response.data.data);
                }).catch((error) => {
                    commit("setSearchPickLsLoading", false);
                    rej(error)
                });
            });
        },
        updateFollowUp({ commit }, reqData) {
            commit("setFollowUpLoading", true);
            return new Promise((res, rej) => {
                http('donate').post('followups', reqData).then(response => {
                    if (response.data.data) {
                        const model = reqData;
                            model['id'] = response.data.data.id;
                        commit("setFollowUpModel", model);
                    }
                    commit("setFollowUpLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setFollowUpLoading", false);
                    rej(error)
                });
            });
        },
    },
};
