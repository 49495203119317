import http from '@/http';

import { TargetModel, storySearch, donateSearch, optionActions } from './sub-modules'

export default {
    modules: {
        TargetModel,
        storySearch,
        donateSearch,
        optionActions
    },
    state: {
        producerActcampaignLoading: false,
        producerActCampModel: {},
    },
    getters: {
        producerActcampaignLoading: state => state.producerActcampaignLoading,
        producerActCampModel: state => state.producerActCampModel,
    },
    mutations: {
        setProducerActcampaignLoading(state, data) {
            state.producerActcampaignLoading = data;
        },
        setProducerActCampModel(state, data) {
            state.producerActCampModel = data;
            state.producerActcampaignLoading = false;
        },
    },
    actions: {
        getActiveCampaign({commit}, params){
            let endPoint = 'campaigns/active';
            if( typeof params !== 'undefined' && Object.keys(params).length > 0){
                endPoint += '?' + new URLSearchParams(params).toString();
            }

            commit("setProducerActcampaignLoading", true);
            return new Promise((res, rej) => {
                http('donate').get(endPoint).then(response => {
                    commit("setProducerActCampModel", response.data.data);
                    res(response);
                }).catch((error) => {
                    commit("setProducerActcampaignLoading", false);
                    rej(error)
                });
            });
        },
    }
};
