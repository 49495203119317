<template>
    <div id="on-air-page" class="pa-3">
        <HeaderCards />
        <ActiveInformations />
        <DetailsCard />
    </div>
</template>
<script>
export default {
  name: "OnAir",
  metaInfo: {
    title: "On Air",
  },
  components: {
    HeaderCards: () => import("@/pages/active-campaign/on-air/components/header-components"),
    ActiveInformations: () => import("@/pages/active-campaign/on-air/components/active-inform-components"),
    DetailsCard: () => import("@/pages/active-campaign/on-air/components/content-details-components"),
  },
  data() {
    return {
    }
  }
};
</script>
