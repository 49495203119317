<template>
  <div id="producer-page">
    <HeaderACtion @targetEditorUpdate="targetEditor=$event"/>
    <v-row class="ma-0 pa-0 px-6 pt-5 mt-2 producer-content" justify="space-between">
      <StoryLists/>
      <DonationsLists />
      <v-col cols="4" class="pa-0 pl-5">
        <OnAirTimer/>
        <Target :targetEditor="targetEditor"/>
      </v-col>
    </v-row>
  </div>  
</template>

<script>
export default {
  name: "Producer",
  metaInfo: {
    title: "Producer",
  },
  components: {
    HeaderACtion: () => import("@/pages/active-campaign/producer/components/header-action-component"),
    StoryLists: () => import("@/pages/active-campaign/producer/components/story-component"),
    DonationsLists: () => import("@/pages/active-campaign/producer/components/donation-component"),
    OnAirTimer: () => import("@/pages/active-campaign/producer/components/on-air-component"),
    Target: () => import("@/pages/active-campaign/producer/components/target-component"),
  },
  data() {
    return {
      targetEditor: null
    }
  },
  mounted() {
    this.wsTargetUpdate();
  },
  methods: {
    wsTargetUpdate() {
      Echo.channel('target').listen('.saved',(targetData) => {
        const currentTarget = this.$helpers.givenTimeRangeCheck(targetData.end_time, targetData.start_time);
        if (currentTarget) {
          targetData.deleted_at =  targetData.deleted_at ?
              this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', targetData.deleted_at) : null;
          this.$store.commit("setCurrentTargetModel", targetData);
        }
      });
    }
  },
  beforeDestroy() {
      Echo.leaveChannel('target');
  }
}
</script>
