import AxiosInstance from "@/http/axios.interceptor";

let API;

const http = (microServcName) => {
    API = AxiosInstance(microServcName);
    return {
        get,
        post,
        put,
        deleteReq
    };
};

// GET method
function get(endPoint, datas) {
    return API.get(endPoint, datas);
}

// POST method
function post(endPoint, datas, options = {}) {
    return API.post(endPoint, datas, options);
}

// PUT method
function put(endPoint, datas, options = {}) {
    return API.put(endPoint, datas, options);
}
//DELETE method
function deleteReq(endPoint, datas) {
    return API.delete(endPoint, datas);
}

export default http;
