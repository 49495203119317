import Vue from 'vue';
import Vuex from 'vuex';

import themeConfig from '@/store/themeConfig';
import { snackbar, datatable, modal } from '@/store/common-modules';

import auth from '@/store/modules/auth';
import dashboard from '@/store/modules/dashboard';
import contact from '@/store/modules/contact';
import { users, roles, permissions } from '@/store/modules/access';
import { donations, hourlySummaryReport, stories } from '@/store/modules/reports';
import { incentives, campaigns, donatePageThemes, appSettings } from '@/store/modules/settings';
import { producer, tally } from '@/store/modules/active-campaign';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    themeConfig,
    snackbar,
    datatable,
    modal,
    auth,
    dashboard,
    users,
    roles,
    permissions,
    contact,
    donations,
    donatePageThemes,
    producer,
    tally,
    campaigns,
    incentives,
    appSettings,
    hourlySummaryReport,
    stories
  },
})
