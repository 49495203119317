<template>
  <v-card class="pa-0 ma-0 h-full" :color="wsConnection !== 'connected' && wsConnection !== 'connecting' ? 'background' : 'primary'" id="sound-effects-page">
    <v-row class="ma-0" align="center" justify="end">
        <div style="opacity: 0;" v-if="model.INCENTIVE_AUDIO && model.INCENTIVE_AUDIO.includes('.mp3')">
          <vue-plyr>
            <audio ref="incentive" controls>
              <source :src="model.INCENTIVE_AUDIO" type="audio/mp3"/>
            </audio>
          </vue-plyr>
        </div>
        <div style="opacity: 0;" v-if="model.TALLY_AUDIO && model.TALLY_AUDIO.includes('.mp3')">
          <vue-plyr>
            <audio ref="tally" controls>
              <source :src="model.TALLY_AUDIO" type="audio/mp3"/>
            </audio>
          </vue-plyr>
        </div>
        <div style="opacity: 0;" v-if="model.NEW_DONOR_AUDIO && model.NEW_DONOR_AUDIO.includes('.mp3')">
          <vue-plyr>
            <audio ref="newDonor" controls>
              <source :src="model.NEW_DONOR_AUDIO" type="audio/mp3"
              />
            </audio>
          </vue-plyr>
        </div>
        <v-btn small class="mr-2" color="secondary" :disabled="fileLoading"
          @click="soundPlay('incentive')">
          <v-icon class="mr-1">mdi-wallet-giftcard</v-icon> Incentive
        </v-btn>
        <v-btn small class="mr-2" color="secondary" :disabled="fileLoading"
          @click="soundPlay('tally')">
          <v-icon class="mr-1">mdi-tally-mark-5</v-icon> Tally
        </v-btn>
        <v-btn small class="mr-2" color="secondary" :disabled="fileLoading"
          @click="soundPlay('newDonor')">
          <v-icon class="mr-1">mdi-charity</v-icon> New Donor
        </v-btn>
    </v-row>
    <v-row class="ma-0 h-95" align="center" justify="center">
      <v-icon v-if="wsConnection !== 'connected' && wsConnection !== 'connecting'" size="100"
        class="background--text text--darken-3 cursor-pointer">mdi-bell-off</v-icon>
      <v-icon v-if="wsConnection === 'connected' || wsConnection === 'connecting'" size="100" color="white" class="mr-3 cursor-pointer">mdi-bell</v-icon>
    </v-row>    
  </v-card>
</template>

<script>
import VuePlyr from 'vue-plyr';
import { mapActions } from 'vuex';

import 'vue-plyr/dist/vue-plyr.css';

export default {
  name: "SoundEffect",
  metaInfo: {
    title: "Sound Effect",
  },
  components: {
    VuePlyr
  },
  data() {
    return {
      wsConnection: 'disconnected',
      model: {
        INCENTIVE_AUDIO: null,
        TALLY_AUDIO: null,
        NEW_DONOR_AUDIO: null
      },
      fileLoading: false
    }
  },
  watch: {
    'Echo.connector.pusher.connection.state': function(newVal) {
      this.wsConnection = newVal;
    }
  },
  created() {
    this.getAudioFile();
  },
  mounted() {
    this.wsConnection = Echo.connector.pusher.connection.state;
    this.wsTrigger();
  },
  methods: {
    ...mapActions(["getOptionsData"]),

    getAudioFile() {
        this.fileLoading = true;
        const reqData = {
            name: 'search',
            value: {
                option_name: ['INCENTIVE_AUDIO', 'TALLY_AUDIO', 'NEW_DONOR_AUDIO'],
            }
        }
        this.getOptionsData(reqData).then((response) => {
          this.model = response.data.data;
          this.fileLoading = false;
        }).catch(err => this.fileLoading = false);
    },
    soundPlay(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].play();
      }
    },
    wsTrigger() {
        Echo.channel('donation').listen('.created',(donationItem) => {
          if (donationItem.new_donor === 1) {
            this.soundPlay('newDonor');
          } else  if (donationItem.incentive_count && donationItem.incentive_count > 0) {
            this.soundPlay('incentive');
          } else if (donationItem.inspiration_fund === 0) {
            this.soundPlay('tally');
          }
        });
    }
  },
  beforeDestroy() {
    Echo.leaveChannel('donation');
  }
}
</script>
