<template>
  <base-card elevation="5" class="pa-0 ma-0">
    <v-row class="ma-0 pa-0" v-if="model">
        <v-card-title class="primary--text font-weight-bold">
            Refund Transaction
        </v-card-title>
    </v-row>
    <v-form @submit.prevent="onSubmit">
        <v-row v-if="model" class="ma-0 pa-10" align="center">
            <v-col cols="5" class="d-flex align-center pa-0">
                <v-subheader class="font-weight-bold black--text text-subtitle-1">Transaction ID:</v-subheader>
                <span>{{txnId}}</span>
            </v-col>
            <v-col cols="7" class="d-flex align-center justify-end py-0 pl-0">
                <v-subheader class="font-weight-bold black--text text-subtitle-1">Transaction Amount:</v-subheader>
                <span>$ {{model.amount}}</span>
                <template v-if="model.refundedAmount">
                    <v-subheader class="font-weight-bold black--text text-subtitle-1">Refunded Amount:</v-subheader>
                    <span>$ {{model.refundedAmount}}</span>
                </template>
            </v-col>
            <v-col v-if="model.balance" cols="12" class="mt-7 d-flex align-center justify-center pa-0">
                <div class="w-75 d-flex">
                    <v-subheader class="font-weight-bold text-subtitle-1">Refund Amount*</v-subheader>
                    <v-text-field ref="amount" placeholder="Refund Amount" hide-details="auto" outlined min="0" type="number"
                        v-model.number="amount" required :error-messages="$helpers.errorMsg('number', $v.amount, 'Refund Amount', model.balance)"
                        @focus="$helpers.avoidInputScrollable" @keydown="$helpers.avoidInputArrowKeyPress"></v-text-field>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="model && model.balance" class="ma-0 pa-0 pb-6 pr-10" align="center" justify="end">
            <v-card-actions class="pa-0">
                <v-btn :loading="loading" type="submit" color="primary" class="text-capitalize mr-3 font-weight-bold">Refund</v-btn>
            </v-card-actions>
        </v-row>
        <v-row v-if="model && !model.balance" align="center" justify="center" class="ma-0 primary--text pb-8 text-subtitle-1">
            Transaction amount was fully refunded!.
        </v-row>
    </v-form>
    <v-row v-if="!tranxnLoading && !model" class="ma-0 text-h5 pa-8 error--text" align="center" justify="center">
        Invalid Transaction!.
    </v-row>
  </base-card>
</template>

<script>
import { required, maxValue, minValue } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';

export default {
    name: "RefundTxn",
    metaInfo: {
        title: "Refund Transaction",
    },
    data: (vm) => ({
        oppId: vm.$route.params.oppId,
        txnId: vm.$route.params.txnId,
        amount: '',
        model: null,
        loading: false,
        tranxnLoading: false
    }),
    validations() {
        const valid = {
            amount: { required , minValue: minValue(0)}
        };
        if (this.model) valid.amount['maxValue'] = maxValue(this.model.balance);
        return valid;
    },
    created() {
        this.getTxnData();
    },
    methods: {
        ...mapActions(['transactionSearch', 'refundTxn']),

        getTxnData() {
            this.tranxnLoading = true;
            this.transactionSearch({sf_id: this.txnId, columns: 'balance'}).then(resp => {
                this.model = resp && resp.length ? resp[0] : null;
                this.amount = resp && resp.length ? parseFloat(resp[0].balance) : '';
                this.tranxnLoading = false;
            }).catch(err => this.tranxnLoading = false)
        },
        onSubmit() {
            this.$v.$touch();
            if (this.$v && this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            this.loading = true;
            const data = {
                id: this.model.id,
                reqData: {amount: this.amount, columns: 'balance'}
            }
            this.refundTxn(data).then(resp => {
                this.loading = false;
                this.getTxnData();
            }).catch(err => this.loading = false);
        }
    }
}
</script>