const currencyFormat = {
    params: ['curreny'],
    bind (el, binding) {
        const objValue = typeof binding.value === 'string' || typeof binding.value === 'number' ? true : false;
        if(binding.value.type) {
            el.innerHTML = twoValueFormat(el, binding, binding.value.options);
            return;
        };
        const amt =  objValue ? parseFloat(binding.value) : parseFloat(binding.value.value),
              opt = !objValue && !binding.value.value.includes('.')  ? binding.value.options : {};
        el.innerHTML = (`${el.innerHTML} ${new Intl.NumberFormat('en-AU', {...{ style: 'currency', currency: 'AUD' }, ...opt}).format(amt)}`).trim();
    }
};
const twoValueFormat = (el, binding, opt) => {
    const formatVal = new Intl.NumberFormat('en-AU', {...{ style: 'currency', currency: 'AUD' }, ...opt}).format(parseFloat(binding.value.value || 0)),
          formatVal1 = new Intl.NumberFormat('en-AU', {...{ style: 'currency', currency: 'AUD' }, ...opt}).format(parseFloat(binding.value.value1 || 0));
    const html = `${el.innerHTML}${binding.value.value && binding.value.value1 ? '<br/>' : ' '} `,
          value2 = `${binding.value.value1 ? (binding.value.value ? '/ ' : '')+ formatVal1+ ' pm' : '' }`;
    return  (`${html}${binding.value.value ? formatVal : ''} ${value2}`).trim();
}

export default currencyFormat;
  