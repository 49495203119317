<template>
    <div id="sync-failed-contacts-page">
        <datatable ref="dtComponent" :modelData="dtData" :inline-edit="true" @editItem="updateContact($event)"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "SyncFailedContacts",
    metaInfo: {
        title: "Sync Failed Contacts",
    },
    components: {
        Datatable: () => import("@/components/common/datatable"),
    },
    data() {
        return {
            dtData: {
                title: 'Sync Failed Contacts',
                headers: [
                    { text: "Name", value: "name" },
                    { text: "Email", value: "email" },
                    { text: "Phone", value: "phone" },
                    { text: "Address", value: "address" },
                    { text: "Failed Sync count", value: "sf_failed" },
                    { text: "SF Account ID", value: 'inline', field: 'sf_account_id', link: window.VUE_APP_SF_DOMAIN },
                    { text: 'SF Contact ID', align: 'start', value: 'inline', field: 'sf_id', link: window.VUE_APP_SF_DOMAIN},
                    { text: 'Failed Reason', align: 'start', value: 'sf_response' },
                    {text: 'Action', value: 'action', sortable: false}
                ],
                actions: ['edit'],
                headerActions: ['reset'],
                pageLink: `${window.VUE_APP_MS_URL}salesforce/contacts?sf_failed=1&page=1`
            },
        }
    },
    computed: {
        ...mapGetters(["activeLink"])
    },
    methods: {
        ...mapActions(["updateSyncFailedContact"]),

        updateContact({item, index}) {
            const rowData = item;
            rowData.editItemLoading = true;
            this.$refs.dtComponent.dtLists.splice(index, 1, rowData);
            const pageLink = new URL(this.activeLink ? this.activeLink : this.dtData.pageLink);
            rowData.sf_failed = 0;
            this.updateSyncFailedContact(rowData).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                this.$store.commit("snackbar/SHOW_MESSAGE", {
                    text: "IDs Updated Successfully",
                    color: "success",
                });
                rowData.editItemLoading = false;
                this.$refs.dtComponent.dtLists.splice(index, 1, rowData);
                this.$refs.dtComponent.pageLoading(pageLink);
            }).catch(() => {
                rowData.editItemLoading = false;
                rowData.sf_failed = item.sf_failed;
                this.$refs.dtComponent.dtLists.splice(index, 1, rowData);
            });
        }
    }

}
</script>
