<template>
    <datatable :modelData="dtData"/>
</template>

<script>
export default {
  name: "AppSettings",
  metaInfo: {
    title: "Application Settings",
  },
  components: {
    Datatable: () => import("@/components/common/datatable"),
  },
  data() {
    return {
      dtData: {
        title: 'Application Settings',
        headers: [
            { text: 'Setting Name', align: 'start', value: 'option_name'},
            {text: 'Action', value: 'action', sortable: false}
        ],
        actions: ['edit'],
        headerActions: ['reset'],
        customEditItem: {
          value: 'MEMBERSHIP_THEME',
          field: 'option_name',
          link: '/membership-page-theme'
        },
        editLink: '/app-settings/edit-setting/',
        pageLink: `${window.VUE_APP_MS_URL}donate/options?page=1`
      }
    };
  }
};
</script>
